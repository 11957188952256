import "./otherNavItemsLight.css";
import "./otherNavItemsDark.css";
import { NavLink } from "react-router-dom";
import ForMobileOtherMenu from "./otherNavItemsPhone";
import { useSelector } from "react-redux";

export default function OtherNavItems() {
    const getDarkModoe = useSelector(darkModes => darkModes);
    return (
        <>
            <ForMobileOtherMenu />
            <div className={getDarkModoe.ToggleDarkModeReducer.chekced ? "otherNavItemsDarkCont" : "otherNavItemsLightCont"}>
                <div>
                    <div className="row1">
                        <NavLink to={"/task"}>
                            <p>Задачи</p>
                            <div className="iconContOtherMenuTask">

                            </div>
                        </NavLink>
                        <NavLink to={"/chat"}>
                            <p>Мессенджер</p>
                            <div className="iconContOtherMenuMessanger">

                            </div>
                        </NavLink>
                        <NavLink to={"/mydata"}>
                            <p>Настройки</p>
                            <div className="settingImgContSettings">

                            </div>
                        </NavLink>
                        <NavLink to={"/settings/AddNewUser"}>
                            <p>Сотрудники</p>
                            <div className="iconContOtherMenuEmploys">

                            </div>
                        </NavLink>
                    </div>
                    <div className="row2">

                        <NavLink to={"/changeApi"}>
                            <p>API ключи</p>
                            <div className="iconContOtherMenuApi">

                            </div>
                        </NavLink>
                        <NavLink to={"/ozon"}>
                            <p>OZON</p>
                            <div className="iconContOtherMenuOzon">

                            </div>
                        </NavLink>
                        <NavLink to={"/wildberries"}>
                            <p>Wildberries</p>
                            <div className="iconContOtherMenuWB">

                            </div>
                        </NavLink>
                        {/* <NavLink to={"/yandex"}>
                            <p>Яндекс Маркет</p>
                            <div className="iconContOtherMenuYandex">

                            </div>
                        </NavLink> */}
                    </div>

                </div>
                {/* <div className="lastChild"></div> */}
            </div>
        </>
    )
}