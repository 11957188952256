import {
  Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton,
  ModalBody, ModalFooter, Button, FormControl, FormLabel, Input,
  NumberInput, NumberInputField, Switch, Select, Textarea, useToast,
  List, ListItem, Spinner
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { useState } from "react";
import axios from "axios";

const CreatePromoModal = ({ isOpen, onClose, onCreated }) => {
  const { register, handleSubmit, reset, watch, setValue } = useForm();
  const [loading, setLoading] = useState(false);
  const toast = useToast();

  const selectedType = watch("type", "global");

  const [searchText, setSearchText] = useState("");
  const [companyOptions, setCompanyOptions] = useState([]);
  const [searchLoading, setSearchLoading] = useState(false);

  const onSubmit = async (data) => {
    try {
      setLoading(true);

      data.discountValue = Number(data.discountValue);
      data.usageLimit = data.usageLimit ? Number(data.usageLimit) : null;
      data.oneTimePerUser = Boolean(data.oneTimePerUser);
      data.expirationDate = new Date(data.expirationDate);
      if (!data.expirationDate || isNaN(new Date(data.expirationDate))) {
        toast({ title: "Ошибка", description: "Выберите корректную дату окончания", status: "error" });
        setLoading(false);
        return;
      }

      if (data.type === "global") data.companyID = null;

      await axios.post("/promo/generate", data);
      toast({ title: "Промокод создан", status: "success" });
      reset();
      onClose();
      onCreated();
    } catch (err) {
      toast({ title: "Ошибка создания", status: "error" });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg">
      <ModalOverlay />
      <ModalContent as="form" onSubmit={handleSubmit(onSubmit)}>
        <ModalHeader>Создать промокод</ModalHeader>
        <ModalCloseButton />
        <ModalBody display="grid" gap={4}>
          <FormControl>
            <FormLabel>Тип промокода</FormLabel>
            <Select {...register("type")} defaultValue="global">
              <option value="global">Глобальный</option>
              <option value="company">Для компании</option>
            </Select>
          </FormControl>

          {selectedType === "company" && (
            <FormControl>
              <FormLabel>Компания</FormLabel>
              <Input
                placeholder="Поиск по имени компании"
                value={searchText}
                onChange={async (e) => {
                  const val = e.target.value;
                  setSearchText(val);
                  if (val.length > 1) {
                    setSearchLoading(true);
                    try {
                      const res = await axios.get(`/promo/searchCompanyForPromocode?q=${val}`);
                      setCompanyOptions(res.data);
                    } catch {
                      toast({ title: "Ошибка поиска компании", status: "error" });
                    } finally {
                      setSearchLoading(false);
                    }
                  } else {
                    setCompanyOptions([]);
                  }
                }}
              />

              {searchLoading && <Spinner size="sm" mt={2} />}

              <List spacing={1} mt={2}>
                {companyOptions.map((company) => (
                  <ListItem
                    key={company._id}
                    bg="gray.100"
                    p={2}
                    borderRadius="md"
                    cursor="pointer"
                    _hover={{ bg: "blue.100" }}
                    onClick={() => {
                      setSearchText(company.sellersname);
                      setCompanyOptions([]);
                      setValue("companyID", company._id);
                    }}
                  >
                    {company.sellersname}
                  </ListItem>
                ))}
              </List>
            </FormControl>
          )}

          <FormControl>
            <FormLabel>Тип скидки</FormLabel>
            <Select {...register("discountType")} defaultValue="percent">
              <option value="percent">Процент (%)</option>
              <option value="fixed">Фиксированная сумма (₽)</option>
            </Select>
          </FormControl>

          <FormControl>
            <FormLabel>Значение скидки</FormLabel>
            <NumberInput min={1}>
              <NumberInputField {...register("discountValue")} />
            </NumberInput>
          </FormControl>

          <FormControl>
            <FormLabel>Лимит использований (опционально)</FormLabel>
            <NumberInput min={1}>
              <NumberInputField {...register("usageLimit")} />
            </NumberInput>
          </FormControl>

          <FormControl display="flex" alignItems="center">
            <FormLabel mb="0">Одноразовый (1 раз на компанию)</FormLabel>
            <Switch {...register("oneTimePerUser")} />
          </FormControl>

          <FormControl>
            <FormLabel>Срок действия (дата окончания)</FormLabel>
            <Input type="date" {...register("expirationDate")} />
          </FormControl>

          <FormControl>
            <FormLabel>Комментарий (внутренний)</FormLabel>
            <Textarea {...register("comment")} placeholder="Не виден клиенту" />
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <Button onClick={onClose} mr={3}>Отмена</Button>
          <Button colorScheme="blue" type="submit" isLoading={loading}>Создать</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default CreatePromoModal;