import React from 'react';
import { FaArrowUp, FaArrowDown, FaEquals  } from 'react-icons/fa';
import { FcNeutralTrading } from "react-icons/fc";
import { PiNumberSquareZeroFill } from "react-icons/pi";





const PriceDifference = ({ currentPrice, previousPrice }) => {
  const priceDifference = currentPrice - previousPrice;

  const renderArrow = () => {
    if (priceDifference > 0) {
      return (
        <div style={{ color: 'green' }}>
          {/* <FaArrowUp /> */}
           {priceDifference.toFixed(2)} ↑
        </div>
      );
    } else if (priceDifference < 0) {
      return (
        <div style={{ color: 'red' }}>
          {/* <FaArrowDown /> */}
           {Math.abs(priceDifference).toFixed(2)} ↓
        </div>
      );
    } else {
      return (
        <div style={{ color: '#98B6D4' }}>
          {/* <PiNumberSquareZeroFill  /> */}
           {Math.abs(priceDifference).toFixed(2)} =
        </div>  
      );
    }
  };

  return (
    <div>
    
      {renderArrow()}
    </div>
  );
};

// Пример использования


export default PriceDifference;
