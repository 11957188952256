import "./addNewUserTable.css";
import "./addNewUserTableDark.css";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useEffect, useState } from "react";
import axios from "axios";
import ModalFromEdit from "../modalForEditTable/modalForEditTable";
import { fetchUsers } from "../../../../../redux/reducer/postFetcReducer/action/action";
import { useDispatch, useSelector } from "react-redux";
import { Loading, Grid } from "@nextui-org/react";
import { getmyinfoAction } from "../../../../../redux/reducer/getmyinfo/action/action";
import { getMyImageAction } from "../../../../../redux/reducer/getMyimg/action/action";
import { Checkbox, CheckboxGroup } from '@chakra-ui/react'
import DeletWorkersInCopmay from "../deleteWorker/deleteWorker";
import { Avatar, AvatarBadge, AvatarGroup } from '@chakra-ui/react';
import sockets from "../../../../../socket/socket";
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
} from '@chakra-ui/react';
export default function AddNewUserTable({ handleShow, workers }) {
  const getDarkModoe = useSelector(darkModes => darkModes);

  const socket = sockets;
  const dipatch = useDispatch();
  const [shows, setShows] = useState(false);
  const [currentDate, SetcurrentDate] = useState({});
  const [load, setLoad] = useState(false);
  const useData = useSelector(datas => datas);
  const [myChecked, setmyChecked] = useState(false);
  const myinfos = useSelector(data => data.getMyImageReducer);
  const [selectedUser, setSelectedUser] = useState([]);


  let getCompanyID = useSelector(compID => compID.checkedSessionReducer);
  let onlineUSers = useSelector(onlineUSers => onlineUSers);
  let getmyifno = useSelector(myinfo => myinfo);
  //для редактирование сотрудника
  useEffect(() => {

    dipatch(fetchUsers())
    dipatch(getmyinfoAction("/registration/v2/api"))
    dipatch(getMyImageAction("/registration/v2/api"))

  }, [dipatch])
  // SOCKET UPDATE IN CLIENT

  useEffect(() => {

    socket.on('updateemployee', (users) => {
      dipatch(fetchUsers())
    });



    return () => {
      socket.off('updateemployee');
      socket.off('disconnect');


    };
  }, [])



  const openModalEdit = (empoloys) => {
    setShows(true)
    SetcurrentDate(empoloys)
    dipatch(fetchUsers())
  }
  //используйте эту функцию, чтобы удалить сотрудника


  function handleCheckedAll(e) {
    const checkboxes = document.querySelectorAll("#employChecek");
    setmyChecked(!myChecked)

    checkboxes.forEach((checkbox, index) => {

      if (myChecked === true) {
        checkbox.checked = false; // Устанавливаем состояние checked в true для всех чекбоксов
        checkbox.defaultChecked = false; // Устанавливаем состояние defaultChecked в true для всех чекбоксов


      } else {
        checkbox.checked = true; // Устанавливаем состояние checked в true для всех чекбоксов
        checkbox.defaultChecked = true; // Устанавливаем состояние defaultChecked в true для всех чекбоксов


      }


    });



  }

  useEffect(() => {

    if (myChecked) {
      workers?.map((item) => {
        setSelectedUser((prevSelected) => [...prevSelected, item.workerID]);
      })
    } else {
      setSelectedUser([]);
    }

  }, [myChecked])

  const hanldeDeleteAllWorkers = () => {
    console.log(selectedUser);

    axios.post('/deleteCheckedWorkers/api', {
      selectedUser
    })
      .then(function (response) {
        console.log(response);
        setSelectedUser([]);
        socket.emit('updateemployeeServerSocket', { companyIDS: getCompanyID.checkedSession });
      })
      .catch(function (error) {
        console.log(error);
      });

  }

  const hanldeCheckMyId = (myID) => {

    let findMyid = selectedUser.find(itemID => itemID === myID)
    if (!findMyid) {
      setSelectedUser((prevSelected) => [...prevSelected, myID]);
    } else {
      let filter = selectedUser.filter(itemID => itemID !== myID)
      setSelectedUser(filter);
    }



  }

  if (useData.rootReducer.users.data == "") {
    return (
      <div className={getDarkModoe.ToggleDarkModeReducer.chekced ? "noEmployPageDark" : "noEmployPageLight"}>
        <div></div>
        <div>
          <div>
            <p>Вы еще не добавили сотрудника</p>
            <p>Добавьте в компанию сотрудников, чтобы управлять их задачами</p>
          </div>
        </div>
      </div>
    )

  }









  return (
    < >
      < ModalFromEdit shows={shows} currentDate={currentDate} setShows={setShows} />
      {(useData.rootReducer.users.length < 1) ?

        <div className={getDarkModoe.ToggleDarkModeReducer.chekced ? "noEmployPageDark" : "noEmployPageLight"}>
          <div></div>
          <div>
            <div>
              <p>Вы еще не добавили сотрудника</p>
              <p>Добавьте в компанию сотрудников, чтобы управлять их задачами</p>
            </div>
          </div>
        </div>
        :
        <div>
          <table>
            <thead>
              <tr>
                <th style={{ textAlign: "center" }}>


                  {
                    myinfos.users?.data?._id == useData.getMyCompanyAPIReducer.company.ownerofcompanyID ?
                      <input type="checkbox" name="employChecek" id="glavaChecked" value={myChecked} onChange={(e) => handleCheckedAll(e.target.value)} />
                      :
                      ""
                  }
                </th>

                <th className="THIMG">Аватар</th>
                <th>ФИО</th>
                <th className="EMAIL">Электронная почта</th>
                <th>Номер телефона</th>
                <th >Роль</th>
                <th className="employOtherOptions">
                  {
                    myinfos.users?.data?._id == useData.getMyCompanyAPIReducer.company.ownerofcompanyID && selectedUser.length > 0 ?
                      <Popover >
                        <PopoverTrigger>
                          <button >
                            <svg xmlns="http://www.w3.org/2000/svg" width="4" height="4" viewBox="0 0 4 4" fill="none">
                              <circle cx="2.00156" cy="1.99844" r="1.6" transform="rotate(90 2.00156 1.99844)" fill="#767676" />
                            </svg>
                            <svg xmlns="http://www.w3.org/2000/svg" width="4" height="4" viewBox="0 0 4 4" fill="none">
                              <circle cx="2.00156" cy="1.99844" r="1.6" transform="rotate(90 2.00156 1.99844)" fill="#767676" />
                            </svg>
                            <svg xmlns="http://www.w3.org/2000/svg" width="4" height="4" viewBox="0 0 4 4" fill="none">
                              <circle cx="2.00156" cy="1.99844" r="1.6" transform="rotate(90 2.00156 1.99844)" fill="#767676" />
                            </svg>
                          </button>
                        </PopoverTrigger>
                        <PopoverContent className={getDarkModoe.ToggleDarkModeReducer.chekced ? "deleteAllEmployDark" : "deleteAllEmployLight"}>
                          <PopoverBody >
                            <div onClick={hanldeDeleteAllWorkers}>
                              <div>
                                <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
                                  <path d="M17.5 9V18.4C17.5 18.7314 17.2314 19 16.9 19H4.1C3.76863 19 3.5 18.7314 3.5 18.4V9" stroke="#353535" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                  <path d="M8.5 15V9" stroke="#353535" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                  <path d="M12.5 15V9" stroke="#353535" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                  <path d="M6.5 5H14.5M19.5 5H14.5H19.5ZM1.5 5H6.5H1.5ZM6.5 5V1.6C6.5 1.26863 6.76863 1 7.1 1H13.9C14.2314 1 14.5 1.26863 14.5 1.6V5H6.5Z" stroke="#353535" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                              </div>
                              <p>Удалить выделенные</p>
                            </div>
                          </PopoverBody>
                        </PopoverContent>
                      </Popover>
                      :
                      ""
                  }


                </th>
              </tr>
            </thead>
            <tbody>
              {/* admin  здесь данные владелец компании */}
              <tr style={{ textAlign: "center" }}>
                <td style={{ textAlign: 'center' }}>
                  {
                    myinfos.users?.data?._id == useData.getMyCompanyAPIReducer.company.ownerofcompanyID ?
                      <input type="checkbox" name="employChecek" disabled />
                      :
                      ""
                  }

                </td>
                <td className="THIMGTD">
                  {
                    getmyifno.rootReducer.users.adminInfo?.online ?
                      <Avatar size={"sm"} loading="lazy" src={useData.rootReducer.users.adminInfo.img ? `/${useData.rootReducer.users.adminInfo.img}` : "https://bit.ly/broken-link"}>
                        <AvatarBadge boxSize='1em' bg='green.500' />
                      </Avatar>
                      :
                      <Avatar size={"sm"} loading="lazy" src={useData.rootReducer.users.adminInfo.img ? `/${useData.rootReducer.users.adminInfo.img}` : "https://bit.ly/broken-link"} />
                  }


                  {/* <img src={useData.rootReducer.users.adminInfo.img?"http://localhost:5000/"+useData.rootReducer.users.adminInfo.img:"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTTe0qkX7CGP28JHWYVUlbUOAe27r9YPPx0SqgiYJV9rWMlFvMPXuhswH4xaWyIrYF9xWY&usqp=CAU"}/> */}
                </td>
                <td>{useData.rootReducer.users.adminInfo.name}</td>
                <td className="EMAIL">{useData.rootReducer.users.adminInfo.email}</td>
                <td>{useData.rootReducer.users.adminInfo.tel}</td>
                <td className="activeButton"><div style={{ cursor: "pointer" }}>Владелец</div></td>
                <td>



                </td>

              </tr>
              {/* ================================================================ */}
              {/* my wokers если в компании есть сотрудник, то выводим на экран*/}
              {<h2>У вас еще нет сотрудника</h2> && workers?.map((item, index) => {

                // console.log(useData.getMyCompanyAPIReducer.company.ownerofcompanyID);
                if (myinfos.users.data) {
                  if (myinfos.users?.data?._id == useData.getMyCompanyAPIReducer.company.ownerofcompanyID) {
                    return (
                      <tr key={index}>
                        <td style={{ textAlign: 'center' }}>

                          <input type="checkbox" name="employChecek" id="employChecek" value={item.workerID} onClick={() => hanldeCheckMyId(item.workerID)} />
                        </td>
                        <td className="THIMGTD">
                          {
                            onlineUSers.getOnlineUserViaRouterReducer.users.data?.includes(item.workerID) ?
                              <Avatar size="sm" src={item.img ? `/${item.img}` : "https://bit.ly/broken-link"} loading="lazy">
                                <AvatarBadge boxSize='0.9em' bg='green.500' />
                              </Avatar>
                              :
                              <Avatar size="sm" src={item.img ? `/${item.img}` : "https://bit.ly/broken-link"} loading="lazy" />
                          }

                          {/* <img src={item.img?item.img:"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTTe0qkX7CGP28JHWYVUlbUOAe27r9YPPx0SqgiYJV9rWMlFvMPXuhswH4xaWyIrYF9xWY&usqp=CAU"}/> */}
                        </td>
                        <td>{item.name}</td>
                        <td className="EMAIL">{item.email}</td>
                        <td>{item.tel}</td>
                        <td className="activeButton" style={{ cursor: "pointer" }}><div onClick={() => openModalEdit(item)}>{item.rols}</div></td>
                        <td className="deletrTD">
                          <div className="editEmploy" onClick={() => openModalEdit(item)}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                              <path d="M1 19H10H19" stroke="#3B79F6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                              <path d="M10.2198 3.82843L15.1696 8.77814M10.2198 3.82843L13.0483 1L17.998 5.94975L15.1696 8.77814L10.2198 3.82843ZM10.2198 3.82843L4.6132 9.43504C4.42567 9.62254 4.32031 9.87694 4.32031 10.1421V14.6776H8.85588C9.12108 14.6776 9.37538 14.5723 9.56298 14.3847L15.1696 8.77814L10.2198 3.82843Z" stroke="#3B79F6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                          </div>
                          <DeletWorkersInCopmay item={item} btnload={load} />
                        </td>
                      </tr>
                    )
                  } else {
                    //
                    return (
                      <tr key={index}>
                        <td style={{ textAlign: 'center' }}>

                        </td>
                        <td className="THIMGTD">
                          {
                            onlineUSers.getOnlineUserViaRouterReducer.users.data?.includes(item.workerID) ?
                              <Avatar size="sm" src={item.img ? `/${item.img}` : "https://bit.ly/broken-link"} loading="lazy">
                                <AvatarBadge boxSize='0.9em' bg='green.500' />
                              </Avatar>
                              :
                              <Avatar size="sm" src={item.img ? `/${item.img}` : "https://bit.ly/broken-link"} loading="lazy" />
                          }
                          {/* <img src={item.img?item.img:"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTTe0qkX7CGP28JHWYVUlbUOAe27r9YPPx0SqgiYJV9rWMlFvMPXuhswH4xaWyIrYF9xWY&usqp=CAU"}/> */}
                        </td>
                        <td>{item.name}</td>
                        <td className="EMAIL">{item.email}</td>
                        <td>{item.tel}</td>
                        <td className="activeButton" style={{ cursor: "pointer" }}><div  >{item.rols}</div></td>
                        <td className="deletrTD">
                          {(() => {
                            if (item.tel == useData.getMayInfoReducer.myinfo.tel) {
                              return <DeletWorkersInCopmay item={item} btnload={load} />

                            }
                          })()}
                        </td>
                      </tr>
                    )
                  }
                }
              })}
            </tbody>
          </table>
          {/* mobile =========================================================================================================================================== */}
          <div className={getDarkModoe.ToggleDarkModeReducer.chekced ? "forMobileDark" : "forMobileLight"}>
            <div >
              <div>
                <div>
                  {
                    getmyifno.rootReducer.users.adminInfo?.online ?
                      <Avatar size={"sm"} loading="lazy" src={useData.rootReducer.users.adminInfo.img ? `/${useData.rootReducer.users.adminInfo.img}` : "https://bit.ly/broken-link"} >
                        <AvatarBadge boxSize='1em' bg='green.500' />
                      </Avatar>
                      :
                      <Avatar loading="lazy" size={"sm"} src={useData.rootReducer.users.adminInfo.img ? `/${useData.rootReducer.users.adminInfo.img}` : "https://bit.ly/broken-link"} />
                  }
                  <div>
                    <p>{useData.rootReducer.users.adminInfo.name}</p>
                  </div>
                </div>
                <div>

                </div>
              </div>
              <div>
                <div>
                  <p>Электронная почта</p>
                </div>
                <div>
                  <p>{useData.rootReducer.users.adminInfo.email}</p>
                </div>
              </div>
              <div>
                <div>
                  <p>Номер телефона</p>
                </div>
                <div>
                  <p>{useData.rootReducer.users.adminInfo.tel}</p>
                </div>
              </div>
              <div>
                <div>
                  <p>Роль</p>
                </div>
                <div>
                  <p>Владелец</p>
                </div>
              </div>

            </div>
            {<h2>У вас еще нет сотрудника</h2> && workers?.map((item, index) => {

              if (myinfos.users.data) {
                if (myinfos.users?.data?._id == useData.getMyCompanyAPIReducer.company.ownerofcompanyID) {
                  return (
                    <div key={index}>
                      <div>
                        <div>
                          {
                            onlineUSers.getOnlineUserViaRouterReducer.users.data?.includes(item.workerID) ?
                              <Avatar size="sm" src={item.img ? `/${item.img}` : "https://bit.ly/broken-link"} loading="lazy">
                                <AvatarBadge boxSize='0.9em' bg='green.500' />
                              </Avatar>
                              :
                              <Avatar size="sm" src={item.img ? `/${item.img}` : "https://bit.ly/broken-link"} loading="lazy" />
                          }
                          <div>
                            <p>{item.name}</p>
                          </div>
                        </div>
                        <div>
                          <div onClick={() => openModalEdit(item)}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                              <path d="M1 19H10H19" stroke="#3B79F6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                              <path d="M10.2198 3.82843L15.1696 8.77814M10.2198 3.82843L13.0483 1L17.998 5.94975L15.1696 8.77814L10.2198 3.82843ZM10.2198 3.82843L4.6132 9.43504C4.42567 9.62254 4.32031 9.87694 4.32031 10.1421V14.6776H8.85588C9.12108 14.6776 9.37538 14.5723 9.56298 14.3847L15.1696 8.77814L10.2198 3.82843Z" stroke="#3B79F6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                          </div>
                          {/* <div onClick={hanldeDeleteAllWorkers}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                              <path fillRule="evenodd" clipRule="evenodd" d="M8.5 6V3.5H15V6H8.5ZM7.75 7.5H15.75H20.75C21.1642 7.5 21.5 7.16421 21.5 6.75C21.5 6.33579 21.1642 6 20.75 6H16.5V3.35C16.5 2.60444 15.8956 2 15.15 2H8.35C7.60442 2 7 2.60442 7 3.35V6H2.75C2.33579 6 2 6.33579 2 6.75C2 7.16421 2.33579 7.5 2.75 7.5H7.75ZM5.5 10.75C5.5 10.3358 5.16421 10 4.75 10C4.33579 10 4 10.3358 4 10.75V20.15C4 20.8956 4.60444 21.5 5.35 21.5H18.15C18.8956 21.5 19.5 20.8956 19.5 20.15V10.75C19.5 10.3358 19.1642 10 18.75 10C18.3358 10 18 10.3358 18 10.75V20H5.5V10.75ZM10.5 10.75C10.5 10.3358 10.1642 10 9.75 10C9.33579 10 9 10.3358 9 10.75V16.75C9 17.1642 9.33579 17.5 9.75 17.5C10.1642 17.5 10.5 17.1642 10.5 16.75V10.75ZM13.75 10C14.1642 10 14.5 10.3358 14.5 10.75V16.75C14.5 17.1642 14.1642 17.5 13.75 17.5C13.3358 17.5 13 17.1642 13 16.75V10.75C13 10.3358 13.3358 10 13.75 10Z" fill="#DD5E98" />
                            </svg>
                          </div> */}
                           <DeletWorkersInCopmay item={item} btnload={load} />
                        </div>
                      </div>
                      <div>
                        <div>
                          <p>Электронная почта</p>
                        </div>
                        <div>
                          <p>{item.email}</p>
                        </div>
                      </div>
                      <div>
                        <div>
                          <p>Номер телефона</p>
                        </div>
                        <div>
                          <p>{item.tel}</p>
                        </div>
                      </div>
                      <div>
                        <div>
                          <p>Роль</p>
                        </div>
                        <div>
                          <p>{item.rols}</p>
                        </div>
                      </div>

                    </div>
                  )
                } else {
                  return (
                    <div key={index}>
                      <div>
                        <div>
                          {
                            onlineUSers.getOnlineUserViaRouterReducer.users.data?.includes(item.workerID) ?
                              <Avatar size="sm" src={item.img ? `/${item.img}` : "https://bit.ly/broken-link"} loading="lazy">
                                <AvatarBadge boxSize='0.9em' bg='green.500' />
                              </Avatar>
                              :
                              <Avatar size="sm" src={item.img ? `/${item.img}` : "https://bit.ly/broken-link"} loading="lazy" />
                          }
                          <div>
                            <p>{item.name}</p>
                          </div>
                        </div>
                        <div>
                          {(() => {
                            if (item.tel == useData.getMayInfoReducer.myinfo.tel) {
                              if (load) {
                                return <Loading type="points" />
                              } else {
                                return (
                                  <>
                                    <div onClick={() => openModalEdit(item)}>
                                      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                        <path d="M1 19H10H19" stroke="#3B79F6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M10.2198 3.82843L15.1696 8.77814M10.2198 3.82843L13.0483 1L17.998 5.94975L15.1696 8.77814L10.2198 3.82843ZM10.2198 3.82843L4.6132 9.43504C4.42567 9.62254 4.32031 9.87694 4.32031 10.1421V14.6776H8.85588C9.12108 14.6776 9.37538 14.5723 9.56298 14.3847L15.1696 8.77814L10.2198 3.82843Z" stroke="#3B79F6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                      </svg>
                                    </div>
                                    <DeletWorkersInCopmay item={item} btnload={load} />
                                  </>
                                )
                              }

                            }
                          })()}

                        </div>
                      </div>
                      <div>
                        <div>
                          <p>Электронная почта</p>
                        </div>
                        <div>
                          <p>{item.email}</p>
                        </div>
                      </div>
                      <div>
                        <div>
                          <p>Номер телефона</p>
                        </div>
                        <div>
                          <p>{item.tel}</p>
                        </div>
                      </div>
                      <div>
                        <div>
                          <p>{item.rols}</p>
                        </div>
                        <div>
                          <p>Дизайн</p>
                        </div>
                      </div>

                    </div>
                  )
                }
              }

            })}


          </div>

        </div>

      }
      <ToastContainer />
    </>
  );
}
