
import yandexIcon from "./yandexIcon.png"
import googleIcon from "./googleIcon.png"
import mailruIcon from "./mailleIcon.png"
import "./viaSocialWidget.css"
import { useEffect } from "react";
let realaddress = process.env.NODE_ENV==="production"?"https://xn--h1alceggc.xn--p1ai":"http://localhost:5000";
let client_id =  process.env.NODE_ENV==="production"?"65b91f13813b4d7ba4720267cd5fd1c5":"1f78545b9ce44f0486e762828e49d5f3"
export default function ViaSocialWidget(params) {
  const loginWithGoogle = () => {
    window.location.href = `${realaddress}/auth/google`;
  };


  const loginmailru = () => {
    window.location.href = `${realaddress}/auth/mailru`;
  };


  const logyandex = () => {
    window.location.href = `https://oauth.yandex.ru/authorize?response_type=code&client_id=${client_id}&redirect_uri=${realaddress}/auth/yandex/callback`;
  };


  // useEffect(() => {
  //   // Добавляем скрипт Telegram Widget
  //   const script = document.createElement('script');
  //   script.src = "https://telegram.org/js/telegram-widget.js?7";
  //   script.async = true;
  //   script.setAttribute('data-telegram-login', 'uprastiBot');
  //   script.setAttribute('data-size', 'large');
  //   script.setAttribute('data-radius', '5');
  //   script.setAttribute('data-auth-url', 'https://1d53-5-77-202-41.ngrok-free.app/api/auth/telegram');
  //   script.setAttribute('data-request-access', 'write');

  //   document.getElementById('telegram-widget').appendChild(script);

  //   return () => {
  //     // Удаляем скрипт при размонтировании компонента
  //     document.getElementById('telegram-widget').removeChild(script);
  //   };
  // }, []);

  return (
    <div className="socWidgetsCont">
      <button className="yandex-login-btn" onClick={logyandex}>
        <img src={yandexIcon} alt="Yandex" />
        <span>Заполнить данные через Яндекс</span>
      </button>
      <button className="google-login-btn" onClick={loginWithGoogle}>
        <img src={googleIcon} alt="Google" />
        <span>Заполнить данные через Google</span>
      </button>
      <button className="mailRU-login-btn" onClick={loginmailru}>
        <img src={mailruIcon} alt="Mail.ru" />
        <span>Заполнить данные через Mail.ru</span>
      </button>
      {/* <button className="telegram-button">
         <div id="telegram-widget"  />
      </button> */}
    </div>

  )
}