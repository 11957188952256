import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';

import { BrowserRouter } from "react-router-dom";

import { Provider } from 'react-redux'
import store from './redux/store/store';

import { ChakraProvider } from '@chakra-ui/react'
import NoPage from './component/page/noPage/noPage';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';  // <-- Добавьте эту строку
// В самом начале index.js или axios.js
import axios from "axios";
import CsrfProvider from './CsrfProvider';


axios.defaults.withCredentials = true;
axios.interceptors.request.use((config) => {
  console.log("withCredentials?", config.withCredentials); // должно быть true
  return config;
});



const theme = {
  fontSizes: {
    lg: '18px',
  },
  colors: {
    gray: {
      100: '#fafafa',
      200: '#f7f7f7',
    },
  },
}



const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(

<React.StrictMode>
  <BrowserRouter>
    <Provider store={store}>
    <CsrfProvider />
      <ChakraProvider>
        <App />
      </ChakraProvider>
    </Provider>
  </BrowserRouter>
</React.StrictMode>
);

serviceWorkerRegistration.register();
