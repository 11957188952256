// actions/notificationActions.js
import axios from 'axios';

export const FETCH_NOTIFICATIONS_REQUEST = 'FETCH_NOTIFICATIONS_REQUEST';
export const FETCH_NOTIFICATIONS_SUCCESS = 'FETCH_NOTIFICATIONS_SUCCESS';
export const FETCH_NOTIFICATIONS_FAILURE = 'FETCH_NOTIFICATIONS_FAILURE';
export const UPDATE_NOTIFICATION_SUCCESS = 'UPDATE_NOTIFICATION_SUCCESS';
export const DELETE_NOTIFICATION_SUCCESS = 'DELETE_NOTIFICATION_SUCCESS';
export const MARK_NOTIFICATION_AS_READ  = 'MARK_NOTIFICATION_AS_READ';

export const fetchNotificationsByCompany = (companyID) => {
    return async (dispatch) => {
        dispatch({ type: FETCH_NOTIFICATIONS_REQUEST });
        try {
            const response = await axios.get('/notifyConfirm');
            const allNotifications = response.data.data;

            const filteredNotifications = allNotifications.filter(
                (notification) => notification.companyID === companyID
            );

            dispatch({
                type: FETCH_NOTIFICATIONS_SUCCESS,
                payload: { companyID, notifications: filteredNotifications },
            });
        } catch (error) {
            dispatch({
                type: FETCH_NOTIFICATIONS_FAILURE,
                payload: {
                    message: error.message,
                    response: error.response || null,
                },
            });
        }
    };
};

// Действие для обновления уведомления
export const updateNotificationID = (notificationId, updatedData) => {
    return async (dispatch) => {
        try {
            const response = await axios.put(`/notifyConfirm/${notificationId}`, updatedData);
            dispatch({
                type: UPDATE_NOTIFICATION_SUCCESS,
                payload: response.data.data,
            });
        } catch (error) {
            console.error("Error updating notification:", error);
        }
    };
};

// Действие для удаления уведомления
export const deleteNotification = (notificationId) => {
    return async (dispatch) => {
        try {
            await axios.delete(`/notifyConfirm/${notificationId}`);
            dispatch({
                type: DELETE_NOTIFICATION_SUCCESS,
                payload: notificationId,
            });
        } catch (error) {
            console.error("Error deleting notification:", error);
        }
    };
};

export const markNotificationAsRead = (companyID, notificationId) => {
    return async (dispatch) => {
        // Здесь можно добавить запрос на сервер для обновления статуса прочтения, если нужно
        dispatch({
            type: MARK_NOTIFICATION_AS_READ,
            payload: { companyID, notificationId },
        });
    };
};