
import './App.css';
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import AddNewCompany from './component/page/addCompany/addCompany';
import { Statistic } from './component/page/statistic/statistic1';
import CardUser from './component/page/settingsUser/settingsPage/datecellery/datecellery';
import SettingNav from './component/page/settingsUser/settingNav/setttingNav';
import AddNewUser from './component/page/settingsUser/addNewUser/addNewUser';
import axios from 'axios';
import { useEffect, useState } from 'react';
import Outh from './component/aouth/aouth/aouth';
import Mydata from './component/page/settingsUser/settingsPage/mydata/mydata';
import ChangeApi from './component/page/chaneApi/chanegApi';
import { useSelector, useDispatch } from "react-redux";
import { fetchUsers } from './redux/reducer/postFetcReducer/action/action';
import EditCompanyOption from './component/page/addCompany/editCompany/editCompanyOptions/editCompanyOptions';
import { checkedSessionAction } from './redux/reducer/getCheckedCompanySession/action/action';
import AdminLogin from './component/aouth/admin/adminLogin';
import AdmiNav from './component/page/adminPage/adminNav/adminNav';
import AllCompany from './component/page/adminPage/allCompany/allCompany';
import Allusers from './component/page/adminPage/allUsers/allUsers';
import AllAdmin from './component/page/adminPage/addNewAdmin/addNewAdmin';
import Wildberries from './component/page/wildberries/wildberries';
import Ozon from './component/page/ozon/ozon';
import TaskMeneger from './component/page/taskManager/taskmeneger';
import NavBarCont from './component/nav/navBarCont/navBarCont';
import CreatTask from './component/page/taskManager/addTask/addTask';
import GetcurrentTask from './component/page/taskManager/getCurrentTask/getCurrentTask';
import EditChild from './component/page/taskManager/editChild/editChild';
import { setOnlineUser } from './redux/reducer/onlineUsers/action/action';
import NoPage from './component/page/noPage/noPage';
import InDevelopingPage from './component/page/inDevelopingPage/inDevelopingPage';
import Home from './component/page/home/home';
import { getOnlineUserViaRouterFunc } from './redux/reducer/getOnlineUserviaRouter/action/action';
import YandexMetric from './component/utilt/bootstrapAlert/yandexMetrica';
import ChatContainer from './component/page/chat/chatContainer/chatContainer';
import ChatNavFilter from './component/page/chat/chatNavFilter/chatNavFilter';
import PublicChat from './component/page/chat/publicChat/publicChat';
import Conversations from './component/page/chat/conversationsChat/conversations';
import PrivateChat from './component/page/chat/privateChat/privateChat';
import OtherNavItems from './component/nav/otherNavItems/otherNavItems';
import sockets from './socket/socket';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import {
  Box,
  SkeletonCircle,
  SkeletonText,
  Skeleton
} from '@chakra-ui/react';



import FirstVisit from './component/page/firstVisit/firstVisit';
import AdmiNavigation from './component/page/adminPage/AdmiNavigation/AdmiNavigation';
import MyPageAdmin from './component/page/adminPage/myPageAdmin/myPageAdmin';
import GraphCompany from './component/page/settingsUser/settingsPage/graphCompany/graphCompany';
import { EmployeeTemplateComand } from './component/page/settingsUser/employee template/employeeTemplate';
import PushNotificationSubscriber from './component/PushNotificationSubscriber/PushNotificationSubscriber';
import QRScannerOuth from './component/aouth/qrScannerOuth/qrsCannerOuth';
import AppLication from './component/page/application/appLication/appLication';
import ProtectedRoutes from './component/page/protectedRoutes/protectedRoutes';
import WBtable from './component/page/wildberries/wbtable/wbTable';
import WBunit from './component/page/wildberries/wbunit/wbunit';
import Payment from './component/page/payment/payment';
import PromoAdminPanel from './component/page/adminPage/promoAdminPanel/PromoAdminPanel';
import DashboardPayment from './component/page/adminPage/DashboardPayment/dashboardPayment';
import TestXSS from './component/page/test/TestXSS/TestXSS';

axios.defaults.withCredentials = true;







const socket = sockets;

function App() {
  const dispatch = useDispatch()
  let getCompanyID = useSelector(compID => compID.checkedSessionReducer)

  //проверить мастер-авторизацию
  // let[data, error, loading,getData]= ByAxios("/auth/login/api")

  let getmyinfo = useSelector(compID => compID.getMayInfoReducer)
  const [roomJoined, setRoomJoined] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const [isLoading, setIsLoading] = useState(true); // состояние загрузки
  useEffect(() => {
    const handleLoad = () => setIsLoading(false);

    // Проверяем состояние загрузки документа
    if (document.readyState === "complete") {
      handleLoad();
    } else {
      window.addEventListener("DOMContentLoaded", handleLoad);
      window.addEventListener("load", handleLoad);
    }

    return () => {
      window.removeEventListener("DOMContentLoaded", handleLoad);
      window.removeEventListener("load", handleLoad);
    };
  }, []);



  useEffect(() => {
    if (Array.isArray(getmyinfo.myinfo)) {
      // код, если getmyinfo.myinfo является массивом
    } else if (typeof getmyinfo.myinfo === 'object' && getmyinfo.myinfo !== null) {
      socket.emit('pushmyid', getmyinfo.myinfo._id);

      dispatch(checkedSessionAction("/getCheckedCompanySession"));

      if (getCompanyID.checkedSession && !roomJoined) {
        socket.emit('joinRoom', getCompanyID.checkedSession);
        setRoomJoined(true);
        socket.emit('updateMydataOnlineTrueForServer');
        dispatch(getOnlineUserViaRouterFunc());
        socket.emit('getNotifyEmit');
      }
    }

    const handleOnlineUsers = (users) => {
      dispatch(getOnlineUserViaRouterFunc());
      dispatch(setOnlineUser(users));
    };

    const onDisconnect = () => {
      socket.emit('pushmyid', getmyinfo.myinfo._id);
    };

    const connect = () => {
      if (getCompanyID?.checkedSession) {
        socket.emit('joinRoom', getCompanyID?.checkedSession);
      }
      if (getmyinfo.myinfo._id) {
        socket.emit('pushmyid', getmyinfo.myinfo._id);
      }
    };

    socket.on('online', handleOnlineUsers);
    socket.on('disconnect', onDisconnect);
    socket.on('connect', connect);

    // Очистка при размонтировании компонента
    return () => {
      socket.off('online', handleOnlineUsers);
      socket.off('disconnect', onDisconnect);
      socket.off('connect', onDisconnect);
    };
  }, [getCompanyID.checkedSession, getmyinfo.myinfo, roomJoined, dispatch]);

  useEffect(() => {
    const handleUpdateMyData = () => {
      axios.patch('/updateOnlineOffline/api', { online: true })
        .then(function (response) {
          // Обновление данных
          dispatch(fetchUsers());
          dispatch(getOnlineUserViaRouterFunc());
        })
        .catch(function (error) {
          // Обработка ошибки
          console.error('Ошибка при обновлении онлайн-статуса', error);
        });
    };

    socket.on('updateMydataOnlineTrueForClient', handleUpdateMyData);

    // Очистка при размонтировании компонента
    return () => {
      socket.off('updateMydataOnlineTrueForClient', handleUpdateMyData);
    };
  }, [dispatch]);


  useEffect(() => {
    serviceWorkerRegistration.register({
      onUpdate: (registration) => {
        // Показ уведомления о доступном обновлении
        showUpdateNotification(registration);
      },
      onSuccess: () => {
        console.log('Приложение кэшировано для оффлайн-использования.');
      },
    });

  }, []);

  function showUpdateNotification(registration) {
    const notification = new Notification("Доступно новое обновление!", {
      body: "Нажмите для обновления.",
      icon: "/android-chrome-512x512.png", // Путь к изображению
    });

    notification.onclick = () => {
      // Обновить приложение при клике на уведомление
      if (registration.waiting) {
        registration.waiting.postMessage({ type: 'SKIP_WAITING' });
        window.location.reload();
      }
    };
  }



// return ( <TestXSS />)

  // Если страница загружается, показываем индикатор загрузки
  if (isLoading) {
    return (
      <Box padding='6' boxShadow='lg' bg='white' minHeight="100dvh">
        <SkeletonCircle size='10' />
        <SkeletonText mt='4' noOfLines={4} spacing='4' skeletonHeight='2' />
        <Skeleton height='50px' mt='4' noOfLines={4} spacing='4' skeletonHeight='2' />
        <Skeleton height='50px' mt='4' noOfLines={4} spacing='4' skeletonHeight='2' />
        <Skeleton height='50px' mt='4' noOfLines={4} spacing='4' skeletonHeight='2' />
        <SkeletonText mt='4' noOfLines={4} spacing='4' skeletonHeight='2' />
        <SkeletonText mt='4' noOfLines={4} spacing='4' skeletonHeight='2' />
        <SkeletonText mt='4' noOfLines={4} spacing='4' skeletonHeight='2' />
        <Skeleton height='50px' mt='4' noOfLines={4} spacing='4' skeletonHeight='2' />
        <Skeleton height='50px' mt='4' noOfLines={4} spacing='4' skeletonHeight='2' />
        <Skeleton height='50px' mt='4' noOfLines={4} spacing='4' skeletonHeight='2' />
      </Box>
    );
  }


  
  return (
    <div>
      < ProtectedRoutes />
      <PushNotificationSubscriber />
      <FirstVisit />
      <YandexMetric />
      <Routes>
        <Route path="/" element={<NavBarCont />}>
          <Route element={<ProtectedRoutes />}>
            <Route index element={<Home />} />

            {/* <Route path="wildberries" element={<Wildberries />} /> */}
            <Route path="wildberries" element={<Wildberries />} >
              <Route path="wbDashbord" element={<WBtable />} />
              <Route path="wnubit" element={<WBunit />} />
            </Route>

            <Route path="ozon" element={<Ozon />} />
            <Route path="task" element={<TaskMeneger />} />
            <Route path="creattask" element={<CreatTask />} />
            <Route path="othermenu" element={<OtherNavItems />} />
            <Route path="getCurrentTask/:id" element={<GetcurrentTask />} />
            <Route path="editChild/:id/:index" element={<EditChild />} />
            <Route path="changeApi" element={<ChangeApi />} />
            <Route path="payment" element={<Payment />} />
            <Route path="EditCompanyOption" element={<EditCompanyOption />} />
            <Route path="yandex" element={<InDevelopingPage />} />
            <Route path="forum" element={<InDevelopingPage />} />
            <Route path="chat" element={<ChatContainer />} />
            <Route path="application" element={<AppLication />} />
            <Route path='sellertnewuserpage' element={<CardUser />}></Route>
            <Route path='mydata' element={<Mydata />}></Route>
            <Route path="settings" element={<SettingNav />} >
              <Route path='AddNewUser' element={<AddNewUser />}></Route>
              <Route path='graphCompany' element={<GraphCompany />}></Route>
              <Route path='employeeTemplate' element={<EmployeeTemplateComand />}></Route>
            </Route>
            <Route path="chat" element={<ChatNavFilter />} >
              <Route path='public' element={<PublicChat />}></Route>
              <Route path='conversations' element={<Conversations />}></Route>
              <Route path='private' element={<PrivateChat />}></Route>
            </Route>
            <Route path="addnewcompany" element={<AddNewCompany />} />
            <Route path="statistic" element={<Statistic />} />
          </Route>
        </Route>


        <Route path="/login" element={<Outh />} />
        <Route path="/QRScannerOuth" element={<QRScannerOuth />} />

        {/* <Route path="/registration" element={<Registration />} /> */}
        {/* admin */}
        
        <Route path="/adminPage" element={<AdmiNavigation />}>
          <Route path="allcompany" element={<AllCompany />} />
          <Route path="allusers" element={<Allusers />} />
          <Route path="alladmin" element={<AllAdmin />} />
          <Route path="myPageAdmin" element={<MyPageAdmin />} />
          <Route path="promocode" element={<PromoAdminPanel />} />
          <Route path="paymentList" element={<DashboardPayment />} />
        </Route>
        <Route path="/admin" element={<AdminLogin />} />
        <Route path="*" element={<NoPage />} />
      </Routes>

    </div>
  );
}

export default App;





