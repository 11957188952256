import { useEffect } from "react";
import DownloadButton from "../test/dowNloadYesy"
import FileUploadTes from "../test/upload multiple files with progress indicator/uploadTestProgress"
import "./homeLight.css"
import { Helmet } from "react-helmet";
import axios from "axios";


export default function Home() {
    const fileUrl = 'https://example.com/sample-video.mp4';
    const fileName = 'sample-video.mp4';
  
    // return       <DownloadButton downloadUrl={fileUrl} filename={fileName} />
    
    useEffect(()=>{
        const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone; // Часовой пояс пользователя
        const userLocale = Intl.DateTimeFormat().resolvedOptions().locale; // Локализация пользователя
        console.log(userTimeZone,"userTimeZone");
        console.log(userLocale,"userLocale");
        
        
        
    },[])
    const handlePayment = async () => {
        const response = await fetch(`/generate-payment`, { method: 'GET' });
        const data = await response.json();
        console.log(data);
        
      };

      const checked = async () => {
       try {
        axios.post('/robokassa/callback', {
            InvId: 100500, // Убедитесь, что это совпадает с тем, что вы отправляете при генерации платежа
            OutSum: 2000,  // Эта сумма также должна совпадать
            Success: true, // Это значение должно быть "true" для успешного платежа
            SignatureValue:"fa728fb4a8bff483b0d73ccc7a9b1ca65e204ee92a0731540e4402059a2f5011",
            userData: {
                productId: "656c6a02514a0cdf13e560c0",
                username: "Новороссийск",
                userID: "asdasdasd"
            }
          })
          .then(function (response) {
            console.log(response.data);
          })
          .catch(function (error) {
            console.error(error);
          });
       } catch (error) {
            console.error(error);
            
       }

    };
    
      
    return (
        <div className="glavimg">
                  <Helmet>
        <title> Бесплатный таск-трекер для управления задачами и командой | Упрости.рф</title>
        <meta name="description" content="Управляйте задачами и командой на маркетплейсах легко и бесплатно. Простой и интуитивный таск-трекер с внутренним чатом и поддержкой мобильной версии. Начните работать эффективнее уже сегодня!" />
      </Helmet>
            <img src="/glav.svg" alt="Glav SVG" />
            {/* <FileUploadTes /> */}
            {/* <button onClick={handlePayment}>generete URL</button>
            <button onClick={checked}>chek</button> */}
          
        </div>
    )
}