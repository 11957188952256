// reducer.js

import { IS_EDIT_MESSAGE_TRADE, REPLY_TO_MESSAGE_TRADE, UPDATE_DATA_TRADE, ENABLED_ALL_TRADE } from "./action/action";

// для того чтобы открыть остальные функционал для редактирования сообщений и ответа сообщений


const initialState = {
    editMessage: false,
    replyMessage: false,
    data: {},
};

const TradeReducer = (state = initialState, action) => {
    switch (action.type) {
        case IS_EDIT_MESSAGE_TRADE:
            return {
                ...state,
                data: action.value, // Обновляем data только для IS_EDIT_MESSAGE_TRADE
                editMessage: true,
                replyMessage: false
            };
        case REPLY_TO_MESSAGE_TRADE:
            return {
                ...state,
                data: action.value, // Обновляем data только для REPLY_TO_MESSAGE
                editMessage: false,
                replyMessage: true
            };
        case ENABLED_ALL_TRADE:
            return {
                ...state,
                editMessage: false,
                replyMessage: false,
                data: {}
            };
        case UPDATE_DATA_TRADE:
            return {
                ...state,
                data: action.value, // Обновляем data только для UPDATE_DATA
            };
        default:
            return state;
    }
};

export default TradeReducer;
