import axios from "axios";
import "./wbunit.css";
import "./wbunitDark.css";
import { useState, useEffect } from "react";
import { DndContext, useDraggable, useSensor, useSensors, MouseSensor, KeyboardSensor, PointerSensor, closestCenter, TouchSensor } from "@dnd-kit/core";
import { SortableContext, useSortable, arrayMove } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { useDispatch, useSelector } from "react-redux";
import { Image } from '@chakra-ui/react';
import { Tooltip } from '@chakra-ui/react'
import { IoCopyOutline } from "react-icons/io5";
import { border, useToast } from '@chakra-ui/react';
import { changeCompanyClear, changeDateFromTo, changeSreachValueWbUnit, getWbUnitAction, reorderWbUnit, setPagewbUnit, sortingwbunit } from "../../../../redux/reducer/wbUnit/action/action";
import { NextUIProvider, createTheme } from '@nextui-org/react';
import { Pagination } from '@nextui-org/react';
import { DatePicker, Space, ConfigProvider, Button, Popover, Input, Checkbox, theme, Flex, Skeleton } from 'antd';
import ruRU from "antd/locale/ru_RU";
import dayjs from "dayjs";
import "dayjs/locale/ru";
const { RangePicker } = DatePicker;
const dateFormat = "YYYY.MM.DD";
// Создание темы

// Создаем класс для кастомного PointerSensor

class MyPointerSensor extends PointerSensor {
    static activators = [
        {
            eventName: "onPointerDown",
            handler: ({ nativeEvent: event }) => {
                if (
                    !event.isPrimary ||
                    event.button !== 0 ||
                    isInteractiveElement(event.target)
                ) {
                    return false;
                }

                return true;
            },
        },
    ];
}

// Проверяем, является ли элемент интерактивным
function isInteractiveElement(element) {
    const interactiveElements = ["button", "input", "textarea", "select", "option", "p", "span", "div"];

    if (interactiveElements.includes(element.tagName.toLowerCase())) {
        return true;
    }

    return false;
}
export default function WBunit() {
    const [data, setData] = useState([]);
    const [popoverType, setPopoverType] = useState(null);
    const [popoverIndex, setPopoverIndex] = useState(null);
    const [reclam, setReclam] = useState(10);
    const [tax, setTax] = useState(6);
    const getDarkModoe = useSelector(darkModes => darkModes)
    const getWBunitReducer = useSelector(getWBunitReducer => getWBunitReducer).getWBunitReducer;
    const { currentPage, itemsPerPage, totalPages, wnUnit, date_from, date_to, loading, searchValue } = useSelector(getWBunitReducer => getWBunitReducer).getWBunitReducer;
    const isMobile = () => /Android|iPhone|iPad|iPod/i.test(navigator.userAgent);
    const getDefaulyCheckedSession = useSelector(company => company.checkedSessionReducer);
    const checked = useSelector(company => company.userReducerCompanyChecked);
    const { Search } = Input;
    const sensors = useSensors(
        useSensor(MyPointerSensor, {
            activationConstraint: isMobile()
                ? null // Без задержки для настольных устройств
                : {
                    delay: 0, // Никакой задержки
                    tolerance: 0, // Без смещения
                },
        }),
        useSensor(TouchSensor, {
            activationConstraint: isMobile()
                ? {
                    delay: 1000, // Задержка в 500 мс для мобильных устройств
                    tolerance: 20, // Допустимое смещение в 10 пикселей
                }
                : null, // Без ограничений для настольных устройств
        })
    );
    const darkTheme = createTheme({
        type: getDarkModoe.ToggleDarkModeReducer.chekced ? "dark" : "light",
    });


    const [columns, setColumns] = useState([
        { key: "_id", label: "Артикул" },
        { key: "count", label: "Продажи" },
        { key: "constPrice", label: "Себестоимость" },
        { key: "price", label: "Цена" },
        { key: "commission", label: "Комиссия" },
        { key: "logistics", label: "Логистика" },
        { key: "ransom", label: "% Выкупа" },
        { key: "return_amount", label: "Возвраты" },
        { key: "acquiringAvgFee", label: "Эквайринг" },
        { key: "advertising", label: "Реклама" },
        { key: "tax", label: "Налог" },
        { key: "totalExpenses", label: "Всего расходы" },
        { key: "revenuePerUnit", label: "Выручка" },
        { key: "marginPlan", label: "Маржинальность %" },
        { key: "profitPerUnit", label: "Прибыль с единицы" }
    ]);
    const dispatch = useDispatch();






    function update() {
      try {
        dispatch(getWbUnitAction('/getwbUnit/api', currentPage, 10, searchValue, [date_from, date_to]));
      } catch (error) {
            console.log(error);
            
      }
    }





    const formatCurrency = (value) => {
        if (isNaN(value) || value === null || value === undefined) return "0.00 ₽";
        return new Intl.NumberFormat("ru-RU", {
            style: "currency",
            currency: "RUB",
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        }).format(value);
    };



    const formatDecimal = (value) => {
        if (isNaN(value) || value === null || value === undefined) return "0.00";
        return new Intl.NumberFormat("ru-RU", {
            style: "decimal",
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        }).format(value);
    };

    function updateReclam(value, nmID) {
        axios.post("/updateReclam/api", { logistics: value, nmID })
            .then(() => {
                setPopoverIndex(null);
                update();
            })
            .catch(error => console.log(error));
    }

    function updateTax(value, nmID) {
        axios.post("/updateTax/api", { logistics: value, nmID })
            .then(() => {
                setPopoverIndex(null);
                update();
            })
            .catch(error => console.log(error));
    }

    function handleRowDragEnd(event) {
        const { active, over } = event;
        if (!active || !over || active.id === over.id) return;
        const oldIndex = wnUnit.findIndex(item => item?.nmID_data?.id === active.id);
        const newIndex = wnUnit.findIndex(item => item?.nmID_data?.id === over.id);

        const newOrder = arrayMove(wnUnit, oldIndex, newIndex);
        dispatch(reorderWbUnit(newOrder));
    }





    function handleColumnDragEnd(event) {
        const { active, over } = event;
        if (!active || !over || active.id === over.id) return;

        setColumns(prev => {
            const oldIndex = prev.findIndex(col => col.key === active.id);
            const newIndex = prev.findIndex(col => col.key === over.id);
            return arrayMove(prev, oldIndex, newIndex);
        });
    }



    const handlePageChange = (page) => {
        dispatch(setPagewbUnit(page));

    };

    const filterByDate = (dates, dateStrings) => {
        console.log("Выбранные даты (строки):", dateStrings);
        dispatch(changeDateFromTo(dateStrings))

    }
    // useEffect(()=>{     
    //         try {
    //             if (!getDefaulyCheckedSession.loading) {
    //                 dispatch(changeCompanyClear())   
    //                 dispatch(getWbUnitAction('/getwbUnit/api', currentPage, 10, searchValue, [date_from, date_to]));
    //             }
    //         } catch (error) {
    //             console.log(error);
                
    //         }

    // },[getDefaulyCheckedSession.checkedSession,checked.companyID,getDefaulyCheckedSession.loading])

    useEffect(() => {
        try {
            dispatch(getWbUnitAction('/getwbUnit/api', currentPage, 10, searchValue, [date_from, date_to]));
        } catch (error) {
            console.log(error);
            
        }
    }, [currentPage, dispatch, searchValue, date_from, date_to,]);

    console.log(getDefaulyCheckedSession);
    
    
    return (
        <div  className={getDarkModoe.ToggleDarkModeReducer.chekced ? "wbUnitContDark" : "wbUnitCont"}>
           {
            loading &&  <div class="custom-loaderWBUnit"></div>
           }

            <div>
                <ConfigProvider
                    locale={ruRU}
                    theme={{
                        token: {
                            colorBgContainer: getDarkModoe.ToggleDarkModeReducer.chekced ? "#323A49" : "#fff",
                            colorText: getDarkModoe.ToggleDarkModeReducer.chekced ? "#8b8b8b" : "#000",
                            colorBorder: getDarkModoe.ToggleDarkModeReducer.chekced ? "#181B22" : "#ddd",
                            colorBgElevated: getDarkModoe.ToggleDarkModeReducer.chekced ? "#181B22" : "#f5f5f5",
                            colorTextBase: getDarkModoe.ToggleDarkModeReducer.chekced ? "white" : "black",
                            controlOutline: "none",
                            colorBorderBg: "red",
                        },
                    }}
                >
                    <RangePicker
                        defaultValue={[dayjs(date_from, dateFormat), dayjs(date_to, dateFormat)]}
                        format={dateFormat}
                        className="dataPickerWBUnit"
                        onChange={filterByDate}
                       
                    />
                    <Search
                        placeholder="Товары"
                        enterButton
                        value={searchValue}
                        onChange={(e) => [dispatch(setPagewbUnit(1)), dispatch(changeSreachValueWbUnit(e.target.value))]}
                        loading={loading}
                    />
                </ConfigProvider>
            </div>
            {wnUnit.length > 0 && (
                <>
                    <div
                        className={
                            getDarkModoe.ToggleDarkModeReducer.chekced
                                ? "table-wrapper dark"
                                : "table-wrapper"
                        }
                    >
                        <DndContext collisionDetection={closestCenter} onDragEnd={handleColumnDragEnd} sensors={sensors}>
                            <table className="draggable-table">
                                <thead>
                                    <SortableContext items={columns.map((col) => col.key)}>
                                        <tr className="sticky">
                                            {columns.map((column) => (
                                                <SortableColumn key={column.key} column={column} />
                                            ))}
                                        </tr>
                                    </SortableContext>
                                </thead>
                                <DndContext collisionDetection={closestCenter} onDragEnd={handleRowDragEnd} sensors={sensors}>
                                    <SortableContext items={wnUnit.map((item) => item?.nmID_data?.id || "")}>
                                        <tbody>
                                            {wnUnit.map((item, index) => (
                                                <SortableRow key={item?.nmID_data?.id} item={item} columns={columns} />
                                            ))}
                                        </tbody>
                                    </SortableContext>
                                </DndContext>
                            </table>
                        </DndContext>
                    </div>
                    <NextUIProvider theme={darkTheme}>
                        <div>
                            {/* Ваши компоненты, включая Pagination */}

                            <Pagination
                                className={`text-foreground bg-background ${loading ? 'pointer-events-none opacity-50' : ''}`}
                                size="sm"            
                                total={totalPages}
                                initialPage={1}
                                onChange={(page) => {
                                    if (loading === false) {
                                        handlePageChange(page);
                                    }
                                }}
                            />

                        </div>
                    </NextUIProvider>
                </>
            )}
        </div>
    );

}

function SortableColumn({ column }) {
    const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: column.key });
    const { currentPage, itemsPerPage, totalPages, wnUnit, date_from, date_to, loading, searchValue, isAscending, sortedField } = useSelector(getWBunitReducer => getWBunitReducer).getWBunitReducer;
    const style = { transform: CSS.Transform.toString(transform), transition };
    const dispatch = useDispatch();


    return (
        <th ref={setNodeRef} style={style} {...attributes} {...listeners} className="sticky"

        >
            <div>

                {
                    (() => {
                        if (sortedField === column.key) {
                            if (isAscending) {
                                return (
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                        <path d="M12.25 6L6.25 12M12.25 18.5V6V18.5ZM12.25 6L18.25 12L12.25 6Z" stroke="#3B79F6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                )
                            } else {
                                return (
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                        <path d="M12.25 18L18.25 12M12.25 5.5V18V5.5ZM12.25 18L6.25 12L12.25 18Z" stroke="#3B79F6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                )
                            }

                        } else {
                            return (
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path d="M15.5 9.5L12 6L8.5 9.5" stroke="#3B79F6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M15.5 14L12 17.5L8.5 14" stroke="#3B79F6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                            )
                        }
                    })()
                }
                <p
                    style={{ margin: "0", padding: "0", fontSize: "11px" }}
                    onClick={() => dispatch({ type: "SORT_WB_UNIT", payload: { field: column.key } })}
                >{column.label}</p>
            </div>


        </th>
    );
}

function SortableRow({ item, columns }) {

    const id = item?.nmID_data?.id || "";
    const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id });
    const isMobile = () => /Android|iPhone|iPad|iPod/i.test(navigator.userAgent); 
    const { currentPage, itemsPerPage, totalPages, wnUnit, date_from, date_to, loading, searchValue } =
        useSelector(state => state.getWBunitReducer);

    const { darkAlgorithm } = theme;
    const getDarkModoe = useSelector(darkModes => darkModes);
    const [loadSebest, setLoadSebest] = useState(false);
    const [loadTax, setLoadSTax] = useState(false);
    const [loadAverding, setLoadAverding] = useState(false);


    const dispatch = useDispatch();
    // Локальное состояние для себестоимости и флага "применить для всех"
    const [constPrice, setConstPrice] = useState(item.constPrice);
    const [tax, setTax] = useState(item.taxInput);
    const [advertising, setadvertising] = useState(item.advertisingCostsInput);
    useEffect(() => {
        setConstPrice(item.constPrice);
    }, [item.constPrice]);

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
        cursor: "grab" // Добавляет визуальную подсказку, что элемент можно тянуть
    };

    const formatCurrency = (value) => {
        if (isNaN(value) || value === null || value === undefined) return "0.00 ₽";
        return new Intl.NumberFormat("ru-RU", {
            style: "currency",
            currency: "RUB",
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        }).format(value);
    };

    const formatDecimal = (value) => {
        if (isNaN(value) || value === null || value === undefined) return "0.00";
        return new Intl.NumberFormat("ru-RU", {
            style: "decimal",
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        }).format(value);
    };

    // Функция для отправки данных на сервер
    const updateSebest = (constPrice, constPriceEdit, nmID) => {
        try {
            console.log(constPrice, constPriceEdit, nmID);
            setLoadSebest(true)
            axios.post('/updateSebest/api', {
                sebest: constPrice,
                nmID,
                applyAll: constPriceEdit
            })
                .then(response => {
                    // После обновления обязательно вызываем обновление данных
                    dispatch(setPagewbUnit(currentPage));
                    dispatch(getWbUnitAction('/getwbUnit/api', currentPage, 10, searchValue, [date_from, date_to]));
                    setLoadSebest(false)
                })
                .catch(error => {
                    console.error("Ошибка обновления:", error);
                    setLoadSebest(false)
                });
        } catch (error) {
                console.log(error);
                
        }
    };

    // Обработчик для поля ввода: при потере фокуса отправляем данные
    const handlePriceBlur = (e) => {
        const inputValue = e.target.value;
        const parsed = parseFloat(inputValue);
        if (!isNaN(parsed)) {
            const formatted = parsed.toFixed(2);
            setConstPrice(formatted); // обновляем состояние – input обновится мгновенно

            // Отправляем данные на сервер
            const form = e.target.form;
            const constPriceEdit = form.constPriceEdit.checked;
            const nmID = form.nmID.value;
            updateSebest(formatted, constPriceEdit, nmID);
        }
    };

    // Обработчик для чекбокса: при потере фокуса отправляем данные



    const updateTax = (taxValue, taxALL, nmID) => {
        try {
            console.log(taxValue, taxALL, nmID);
            setLoadSTax(true)
            axios.post('/updateTax/api', {
                taxValue,
                nmID,
                taxALL
            })
                .then(response => {
                    // После обновления обязательно вызываем обновление данных
                    dispatch(setPagewbUnit(currentPage));
                    dispatch(getWbUnitAction('/getwbUnit/api', currentPage, 10, searchValue, [date_from, date_to]));
                    setLoadSTax(false)
                })
                .catch(error => {
                    console.error("Ошибка обновления:", error);
                    setLoadSTax(false)
                });
        } catch (error) {
                console.log(error);
                
        }
    };
    const handleTaxBlur = (e) => {
        const inputValue = e.target.value;
        const parsed = parseFloat(inputValue);
        if (!isNaN(parsed)) {
            const formatted = parsed.toFixed(2);
            setConstPrice(formatted); // обновляем состояние – input обновится мгновенно
            // Отправляем данные на сервер
            const form = e.target.form;
            const taxALL = form.taxAll.checked;
            const nmID = form.nmID.value;
            updateTax(formatted, taxALL, nmID);
        }
    };



    const updateAdvertising = (advertising, advertisingAllChecked, nmID) => {
        try {
            console.log(advertising, advertisingAllChecked, nmID);
            setLoadAverding(true)
            axios.post('/updateAdvertising/api', {
                advertising,
                nmID,
                advertisingAllChecked
            })
                .then(response => {
                    // После обновления обязательно вызываем обновление данных
                    dispatch(setPagewbUnit(currentPage));
                    dispatch(getWbUnitAction('/getwbUnit/api', currentPage, 10, searchValue, [date_from, date_to]));
                    setLoadAverding(false)
                })
                .catch(error => {
                    console.error("Ошибка обновления:", error);
                    setLoadAverding(false)
                });
        } catch (error) {
                console.log(error);
                
        }
    };

    const handleAdvertisingBlur = (e) => {
        const inputValue = e.target.value;
        const parsed = parseFloat(inputValue);
        if (!isNaN(parsed)) {
            const formatted = parsed.toFixed(2);
            setConstPrice(formatted); // обновляем состояние – input обновится мгновенно
            // Отправляем данные на сервер
            const form = e.target.form;
            const advertisingAllChecked = form.advertisingAllChecked.checked;
            const nmID = form.nmID.value;
            updateAdvertising(formatted, advertisingAllChecked, nmID);
        }
    };

    return (
        // Оборачиваем родительский контейнер, чтобы тема применялась ко всем вложенным antd-компонентам
        <ConfigProvider 
        locale={ruRU}
        theme={{
            token: {
                colorBgContainer: getDarkModoe.ToggleDarkModeReducer.chekced ? "#323A49" : "#fff",
                colorText: getDarkModoe.ToggleDarkModeReducer.chekced ? "#8b8b8b" : "#000",
                colorBorder: getDarkModoe.ToggleDarkModeReducer.chekced ? "#181B22" : "#ddd",
                colorBgElevated: getDarkModoe.ToggleDarkModeReducer.chekced ? "#181B22" : "#f5f5f5",
                colorTextBase: getDarkModoe.ToggleDarkModeReducer.chekced ? "white" : "black",
                controlOutline: "none",
                colorBorderBg: "red",
            },
        }}
        >
            <tr ref={setNodeRef} style={style} {...attributes} {...(!isMobile() ? listeners : {})}>
                {columns.map((column, index) => {
                    // console.log(item, "");

                    let content;

                    if (column.key === "_id") {
                        content = (
                            <div

                            >
                                <Image
                                    src={
                                        item?.nmID_data?.photos?.length > 0
                                            ? item?.nmID_data?.photos[0].big
                                            : "https://dummyimage.com/50x50/cccccc/ffffff&text=No+Image"
                                    }
                                    fallback="https://via.placeholder.com/150"
                                    style={{ width: 50, height: 50 }}
                                    loading="lazy"
                                />
                                <div>
                                    <p>{item?.nmID_data?.offer_id}</p>

                                    <div style={{ display: "flex", alignItems: "center" }}>
                                        <IoCopyOutline
                                            style={{ cursor: "pointer", fontSize: "10px", marginRight: "5px" }}
                                            onMouseDown={(e) => {
                                                e.stopPropagation();
                                                navigator.clipboard.writeText(item?.nmID_data?.nmID);
                                            }}
                                        />
                                        <a
                                            href={`https://www.wildberries.ru/catalog/${item?.nmID_data?.nmID}/detail.aspx`}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            {item?.nmID_data?.nmID}
                                        </a>
                                    </div>
                                    <Tooltip title={item?.sa_name}>
                                        <span>{item?.nmID_data?.title}</span>
                                    </Tooltip>
                                    <Popover title={item?.sa_name}>
                                        <span>{item?.sa_name}</span>
                                    </Popover>
                                    {/* <p style={{color:"red"}}>{item?.nmID_data?.vendorCode}</p> */}
                                </div>
                            </div>
                        );
                    } else if (column.key === "tax") {
                        const mycontent = (
                            <div className="popoverForUnit"        >
                                {item.istaxEdit === false && (
                                    <p style={{ color: "tomato" }}>Введите верную ставку налога</p>
                                )}
                                <form>
                                    <Input
                                        value={tax}
                                        type="number"
                                        name="tax"
                                        onChange={(e) => setTax(e.target.value)}
                                        onBlur={handleTaxBlur}
                                        disabled={loadTax}
                                    />

                                    <Checkbox
                                        defaultChecked={false}
                                        name="taxAll"
                                        disabled={loadTax}
                                        onChange={(e) => {
                                            const taxAllChecked = e.target.checked;
                                            updateTax(tax, taxAllChecked, item._id);

                                        }}
                                    >
                                        применить для всех
                                    </Checkbox>
                                    <input type="hidden" name="nmID" value={item._id} />
                                </form>
                            </div>
                        );

                        content = (
                            <Popover content={mycontent}>
                                <Button type="primary" style={{boxShadow:"none"}} danger={!item.istaxEdit}
                                >
                                    {formatDecimal(item.tax)}
                                </Button>
                            </Popover>
                        );
                    } else if (column.key === "advertising") {
                        const mycontent = (
                            <div className="popoverForUnit">
                                {item.advertisingEdit === false && (
                                    <p style={{ color: "tomato" }}>Введите вашу долю рекламных расходов</p>
                                )}
                                <form>
                                    <Input
                                        value={advertising}
                                        type="number"
                                        name="advertising"
                                        onChange={(e) => setadvertising(e.target.value)}
                                        onBlur={handleAdvertisingBlur}
                                        disabled={loadAverding}
                                    />

                                    <Checkbox
                                        defaultChecked={false}
                                        name="advertisingAllChecked"
                                        disabled={loadAverding}
                                        onChange={(e) => {
                                            const advertisingAllChecked = e.target.checked;
                                            updateAdvertising(advertising, advertisingAllChecked, item._id);

                                        }}
                                    >
                                        применить для всех
                                    </Checkbox>
                                    <input type="hidden" name="nmID" value={item._id} />
                                </form>
                            </div>
                        );

                        content = (
                            <Popover content={mycontent}>
                                <Button type="primary" style={{boxShadow:"none"}}   danger={!item.advertisingEdit}>
                                    {formatDecimal(item.advertising)}
                                </Button>
                            </Popover>
                        );
                    } else if (column.key === "constPrice") {

                        const mycontent = (
                            <div className="popoverForUnit">
                                {item.constPriceEdit === false && (
                                    <p style={{ color: "tomato" }}>Введите верную себестоимость</p>
                                )}
                                <form>
                                    <Input
                                        value={constPrice} // используем состояние без дополнительного форматирования
                                        type="number"
                                        name="constPrice"
                                        onChange={(e) => setConstPrice(e.target.value)}
                                        onBlur={handlePriceBlur}
                                        disabled={loadSebest}
                                    />

                                    <Checkbox
                                        defaultChecked={false}
                                        name="constPriceEdit"
                                        disabled={loadSebest}
                                        onBlur={(e) => {
                                            const form = e.target.form;
                                            const constPrice = form.constPrice.value;
                                            const constPriceEdit = e.target.checked;
                                            const nmID = form.nmID.value;
                                            updateSebest(constPrice, constPriceEdit, nmID);
                                        }}
                                    >
                                        применить для всех
                                    </Checkbox>
                                    <input type="hidden" name="nmID" value={item._id} />
                                </form>
                            </div>
                        );

                        content = (
                            <Popover content={mycontent} style={{ height: "1000px" }}>
                                <Button type="primary" style={{boxShadow:"none"}} danger={!item.constPriceEdit}>
                                    {formatDecimal(item.constPrice)}
                                </Button>
                            </Popover>
                        );
                    } else if (column.key === "acquiringAvgFee") {
                        content = (
                            formatDecimal(item.acquiringAvgFee)
                        )
                    } else if (column.key === "count") {
                        content = (
                            <span


                            >{item.count}</span>
                        )
                    }
                    else {
                        content = item[column.key];
                    }

                    return <td key={index} className={index === 0 ? 'sticky' : ''}>{content}</td>;



                })}
            </tr>
        </ConfigProvider>
    );
}
