import React, { useEffect } from 'react';
import axios from 'axios';
import { useToast } from '@chakra-ui/react';

// Функция для преобразования base64 строки в uint8Array
function urlBase64ToUint8Array(base64String) {
  const padding = '='.repeat((4 - base64String.length % 4) % 4);
  const base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/');
  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);
  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}

// Функция для подписки на push-уведомления
async function subscribeToPushNotifications(registration) {
  try {
    // Запрашиваем публичный VAPID-ключ с сервера
    const { data } = await axios.get('/vapidPublicKey');
    const publicVapidKey = data.publicKey;

    const applicationServerKey = urlBase64ToUint8Array(publicVapidKey);

    const permission = await Notification.requestPermission();
    if (permission !== 'granted') {
      console.warn('Пользователь отклонил разрешение на уведомления');
      return;
    }

    const subscription = await registration.pushManager.subscribe({
      userVisibleOnly: true,
      applicationServerKey,
    });

    console.log('Подписка на push уведомления:', subscription);

    // Отправляем подписку на сервер
    await axios.post('/subscribe', subscription, {
      headers: { 'Content-Type': 'application/json' },
      withCredentials: true,
    });

    console.log('Подписка успешно отправлена на сервер');
  } catch (error) {
    console.error('Ошибка при подписке на push уведомления:', error);
  }
}

const PushNotificationSubscriber = () => {
  const toast = useToast();

  useEffect(() => {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.register('/service-worker.js')
        .then((registration) => {
          subscribeToPushNotifications(registration);
        })
        .catch((error) => {
          console.error('Ошибка регистрации Service Worker:', error);
        });
    } else {
      console.log('Service Worker не поддерживается в этом браузере');
    }
  }, []);

  return null;
};

export default React.memo(PushNotificationSubscriber);
