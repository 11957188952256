import moment from "moment";

const weekMapping = {
  id1: "week1",
  id2: "week2",
  id3: "week3",
  id4: "week4",
  id5: "week5",
  id6: "week6",
  id7: "week7",
  id8: "week8",
};


const week8Days = {
  id1id: "day1",
  id2id: "day2",
  id3id: "day3",
  id4id: "day4",
  id5id: "day5",
  id6id: "day6",
  id7id: "day7",

};

const getOzonState = {
  ozon: {
    data: [],
    weeks: [],
    columns: [],
    isClick: false,
    hasMore: true,
    currentPage: 1,
    totalItems: 0,
    currentCompanyId: null, // Добавлено новое состояние для текущей компании
    sortByWeek: null,
    sortByWeekLastDay8: null,
    isAscending: false,
    sortPrice: false,
    sortStock: false,
    sorTarticle: false,
    averageOrdersBool: false,
    daysOfStockBool: false,
    turnoverVersion2Bool: false,
    sortedProducts: [],
    searchValue: "", // Хранит текст поиска
    isSearching: false, // Флаг активности поиска
  },
  loading: false,
  error: null,


};

// Функция для обновления вложенных значений
const getOzonisListReducer = (state = getOzonState, action) => {
  switch (action.type) {
    case "FETCH_OZON_REQUEST":
      return {
        ...state,
        loading: true,
        error: null,
      };

    case "FETCH_OZON_SUCCESS":
      const newData = action.payload.data || []; // Fallback to an empty array if undefined
      const currentData = state.ozon.data || []; // Fallback to an empty array if undefined


      // если новые данные длина массива равно нулю и текущая страница равно 1 и поиск пустой  тогда Сброс всех данных
      if (newData.length === 0 && state.ozon.currentPage === 1 && state.ozon.searchValue  === "") {
        return {
          ...state,
          loading: false,
          ozon: {
            ...getOzonState.ozon, // Сброс всех данных
            data: [], // Устанавливаем пустой массив
            currentPage: 1,
          },
        };
      }
// ====================================================================================================================
      // Получаем различия между текущими и новыми данными
      const filteredData = state.ozon.currentCompanyId
        ? newData.filter(item => item.companyID === state.ozon.currentCompanyId) // Фильтрация по текущей компании
        : newData;

      // Обновляем данные, добавляя новые элементы или заменяя существующие
      const updatedData = [...currentData];
      filteredData.forEach((newItem) => {
        const existingIndex = updatedData.findIndex((item) => item.id === newItem.id);
        if (existingIndex > -1) {
          updatedData[existingIndex] = newItem; // Замена целого элемента
        } else {
          updatedData.push(newItem); // Если элемента нет, добавляем его
        }
      });

      // Удаляем дубликаты
      let uniqueData = updatedData.filter((item, index, self) =>
        index === self.findIndex((t) => t.id === item.id)
      );

      // Если включена сортировка по цене, применяем сортировку
      if (state.ozon.sortPrice) {
        uniqueData = uniqueData.sort((a, b) => {
          return state.ozon.isAscending ? (b.price || 0) - (a.price || 0) : (a.price || 0) - (b.price || 0);
        });
      }
      // сортировка для остатка товара
      if (state.ozon.sortStock) {
        uniqueData = uniqueData.sort((a, b) => {
          return state.ozon.isAscending ? (b.stocks.stocks[0].present || 0) - (a.stocks.stocks[0].present || 0) : (a.stocks.stocks[0].present || 0) - (b.stocks.stocks[0].present || 0);
        });
      }

      // сортировка для остатка товара
      if (state.ozon.sorTarticle) {
        uniqueData = uniqueData.sort((a, b) => {
          return state.ozon.isAscending ? (b.sku || 0) - (a.sku || 0) : (a.sku || 0) - (b.sku || 0);
        });
      }

      // сортировка для остатка товара
      if (state.ozon.sortByWeekLastDay8) {

        uniqueData = uniqueData.sort((a, b) => {
          const aTotal = a.weeks.week8?.[state.ozon.sortByWeekLastDay8]?.total ?? 0; // Безопасный доступ с использованием nullish coalescing
          const bTotal = b.weeks.week8?.[state.ozon.sortByWeekLastDay8]?.total ?? 0; // Безопасный доступ с использованием nullish coalescing

          return state.ozon.isAscending ? bTotal - aTotal : aTotal - bTotal; // Сравнение и сортировка
        });
      }


      // сортировка среднее количество заказов
      if (state.ozon.averageOrdersBool) {
        uniqueData = uniqueData.sort((a, b) => {
          return state.ozon.isAscending
            ? (b.ads || 0) - (a.ads || 0)
            : (a.ads || 0) - (b.ads || 0);
        });
      }
      // сортировка Остатка хватит на 
      if (state.ozon.daysOfStockBool) {
        uniqueData = uniqueData.sort((a, b) => {
          return state.ozon.isAscending
            ? (b.idc || 0) - (a.idc || 0)
            : (a.idc || 0) - (b.idc || 0);
        });
      }
      // сортировка Оборачиваемость 
      if (state.ozon.turnoverVersion2Bool) {
        uniqueData = uniqueData.sort((a, b) => {
          return state.ozon.isAscending
            ? (b.turnover || 0) - (a.turnover || 0)
            : (a.turnover || 0) - (b.turnover || 0);
        });
      }

      // Обновление недель
      const weeks = Array.isArray(action.payload.weeks) ? action.payload.weeks : []; // Fallback to an empty array if undefined
      const updatedWeeks = weeks?.map((week, index) => ({
        ...week,
        id: `id${index + 1}`,
        days: Object.entries(week.days).map(([, day], dayIndex) => ({
          ...day,
          id: `id${dayIndex + 1}id`,
        })),
      }));

      // Генерация столбцов
      const transformedColumns = [
        { id: "name", label: "Артикул продавца" },
        { id: "price", label: "Цена" },
        { id: "stock", label: "Остаток" },
        ...updatedWeeks.map((week, index) => ({
          id: week.id || index,
          label: (
            <>
              {moment(week.startDate).format("L")}
              <br />
              {moment(week.endDate).format("L")}
            </>
          ),
        })),
      ];

      const days = updatedWeeks[7]?.days || [];
      const transformedDays = days.map((day) => ({
        id: day.id,
        label: `${moment(day.date).format("L")}`,
      }));

      // Объединяем старые и новые колонки, исключая дубли
      const previousColumns = state.ozon.columns || [];
      const newColumns = [
        ...transformedColumns,
        ...transformedDays,
        { id: "Ads", label: "Заказы в день, шт" },
        { id: "idc", label: "Остатка хватит на дн" },
        { id: "turnover", label: "Оборачиваемость" },
        { id: "strategy", label: "Стратегия" },
        { id: "marketing", label: "Маркетинг" },
        { id: "quality", label: "Качество" },
        { id: "content", label: "Контент" },
      ];

      const mergedColumns = previousColumns.concat(
        newColumns.filter(
          (newCol) => !previousColumns.some((oldCol) => oldCol.id === newCol.id)
        )
      );

      const hasMore = newData.length > 0;
      // Динамическая сортировка по неделям, если выбран сортировка

      return {
        ...state,
        loading: false,
        ozon: {
          ...state.ozon,
          isClick: state.ozon.isClick, // Сохраняем значение isClick
          totalItems: action.payload.totalItems,
          hasMore, // Обновляем информацию о том, есть ли еще данные
          currentPage: state.ozon.currentPage, // Увеличиваем только при наличии данных
          data: uniqueData, // Обновляем состояние с уникальными данными
          weeks: updatedWeeks,
          columns: mergedColumns,
          sortByWeek: state.ozon.sortByWeek,
          sortPrice: state.ozon.sortPrice,
          sorTarticle: state.ozon.sorTarticle,
          sortByWeekLastDay8: state.ozon.sortByWeekLastDay8,
          averageOrdersBool: state.ozon.averageOrdersBool,
          daysOfStockBool: state.ozon.daysOfStockBool,
          turnoverVersion2Bool: state.ozon.turnoverVersion2Bool,
          searchValue: state.ozon.searchValue

        },
      };

    case "FETCH_OZON_FAILURE":
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case "UPDATE_OZON_COMMENT":
      const findComment = state.ozon.data.find(item => item.id === action.payload.id);

      if (findComment) {
        const updatedData = state.ozon.data.map(item =>
          item.id === action.payload.id
            ? {
              ...item,
              comments: {
                ...item.comments,
                [action.payload.commentType]: {
                  value: action.payload.comments,
                  date: action.payload.date,
                },
              },
            }
            : item
        );

        return {
          ...state,
          ozon: {
            ...state.ozon,
            data: updatedData,
          },
        };
      }

      return state; // Если элемент не найден

    case "REORDER_OZON_PRODUCTS":
      return {
        ...state,
        ozon: {
          ...state.ozon,
          data: action.payload,
        },
      };

    case "REORDER_OZON_COLUMNS":
      return {
        ...state,
        ozon: {
          ...state.ozon,
          columns: action.payload, // Сохраняем новый порядок колонок
        },
      };

    case "CLICK_ROWS":
      return {
        ...state,
        ozon: {
          ...state.ozon,
          isClick: action.payload, // Это должно переключать значение
        },
      };

    case "UPDATE_CURRENT_PAGE":
      return {
        ...state,
        ozon: {
          ...state.ozon,
          currentPage: action.payload, // Обновление currentPage
          searchValue:state.ozon.searchValue
        },
      };

    case "SET_CURRENT_COMPANY":
      return {
        ...state,
        ozon: {
          ...state.ozon,
          currentCompanyId: action.payload, // Устанавливаем текущую компанию
          currentPage: 1
        },
      };
    // это из клиента подкрутки обновляем сортировку
    case "SORT_WEEKS_BY_DATE_UPDATE":
      // Проверяем, существует ли неделя для сортировки
      if (state.ozon.sortByWeek) {
        const isAscending = state.ozon.isAscending;
        let sortDate = [...state.ozon.data].sort((a, b) => {
          // Используем state.ozon.sortByWeek для правильного доступа
          const aPercentage = a.weeks[state.ozon.sortByWeek]?.totalPercentage || 0;
          const bPercentage = b.weeks[state.ozon.sortByWeek]?.totalPercentage || 0;
          return isAscending ? bPercentage - aPercentage : aPercentage - bPercentage;
        });

        return {
          ...state,
          ozon: {
            ...state.ozon,
            data: sortDate,
            sortByWeek: state.ozon.sortByWeek, // Сохраняем текущую неделю для сортировки
            isAscending,
            sortStock: false,
            sorTarticle: false,
            sortByWeekLastDay8: null,
          },
        };
      }
      return state;
    // здесь назначить сортировку для недель
    case "SORT_WEEKS_BY_DATE": {
      const weekName = action.payload; // Получаем название недели из Action
      if (weekName) {
        const weekIndex = weekMapping[weekName]; // Находим индекс недели по идентификатору

        // Проверяем, существует ли неделя для сортировки
        if (weekIndex) {
          // Проверяем текущую сортировку для этой недели
          const isAscending = state.ozon.sortByWeek === weekIndex ? !state.ozon.isAscending : true;

          // Сортировка данных в зависимости от текущего направления
          state.ozon.data.sort((a, b) => {
            const aPercentage = a.weeks[weekIndex]?.totalPercentage || 0;
            const bPercentage = b.weeks[weekIndex]?.totalPercentage || 0;
            return isAscending ? bPercentage - aPercentage : aPercentage - bPercentage;
          });

          return {
            ...state,
            ozon: {
              ...state.ozon,
              sortByWeek: weekIndex, // Сохраняем текущую неделю для сортировки
              isAscending, // Добавляем флаг для направления сортировки
              sortPrice: false,
              sortStock: false,
              sorTarticle: false,
              sortByWeekLastDay8: null,
            },
          };
        }
      }

      return state; // Возвращаем текущее состояние, если неделя не найдена
    }

    // сортировка для цены
    case "SORT_PRICE_OZON":
      const isAscending = !state.ozon.isAscending
      return {
        ...state,
        ozon: {
          ...state.ozon,
          sortPrice: !state.ozon.sortPrice,
          sortPrice: true,
          sortStock: false,
          sorTarticle: false,
          isAscending: isAscending,
          sortByWeek: null,
          sortByWeekLastDay8: null,
          data: state.ozon.data.sort((a, b) => {
            return state.ozon.isAscending ? (a.price || 0) - (b.price || 0) : (b.price || 0) - (a.price || 0);
          }),
        },
      };


    // Сортировка по остатку товара
    case "SORT_STOCK_OZON": {
      const isAscending = !state.ozon.isAscending; // Объявляем переменную перед использованием
      return {
        ...state,
        ozon: {
          ...state.ozon,
          sortPrice: false,
          sortStock: true,
          sorTarticle: false,
          isAscending: isAscending,
          sortByWeek: null,
          sortByWeekLastDay8: null,
          data: state.ozon.data.sort((a, b) => {
            return isAscending
              ? (b.stocks.stocks[0].present || 0) - (a.stocks.stocks[0].present || 0)
              : (a.stocks.stocks[0].present || 0) - (b.stocks.stocks[0].present || 0);
          }),
        },
      };
    }



    // сортировка по артикулу товара
    case "SORT_ACTICULE_OZON": {
      const isAscending = !state.ozon.isAscending; // Объявляем переменную перед использованием
      return {
        ...state,
        ozon: {
          ...state.ozon,
          sortPrice: false,
          sortStock: false,
          isAscending: isAscending,
          sortByWeek: null,
          sortByWeekLastDay8: null,
          sorTarticle: true,
          data: state.ozon.data.sort((a, b) => {
            return isAscending
              ? (b.sku || 0) - (a.sku || 0)
              : (a.sku || 0) - (b.sku || 0);
          }),
        },
      };
    }



    // сортировка по дням для последней недели
    case "SORT_LASTWEEKDAY8_OZON": {
      const weekName = action.payload; // Получаем название недели из Action
      console.log(weekName, "action weekName");

      if (weekName) {
        const weekKey = week8Days[weekName]; // Получаем строковый ключ, например "day1"
        console.log(weekKey, "weekKey");

        if (weekKey) {
          // Проверяем текущую сортировку для этой недели
          const isAscending = state.ozon.sortByWeekLastDay8 === weekKey ? !state.ozon.isAscending : true;

          // Создаем копию массива перед сортировкой
          const sortedData = [...state.ozon.data].sort((a, b) => {
            // Проверяем, что `week8` существует и содержит нужный день, иначе 0
            const aPercentage = a.weeks.week8?.[weekKey]?.total ?? 0;
            const bPercentage = b.weeks.week8?.[weekKey]?.total ?? 0;
            return isAscending ? bPercentage - aPercentage : aPercentage - bPercentage;
          });

          return {
            ...state,
            ozon: {
              ...state.ozon,
              data: sortedData, // Используем отсортированные данные
              sortByWeekLastDay8: weekKey, // Сохраняем текущую неделю для сортировки
              sortByWeek: null,
              isAscending, // Добавляем флаг для направления сортировки
              sortPrice: false,
              sortStock: false,
              sorTarticle: false,
            },
          };
        }
      }

      return state; // Возвращаем текущее состояние, если неделя не найдена
    }



    // сортировка для средних заказа
    case "SORT_AVERAGEORDERS_OZON": {
      const isAscending = !state.ozon.isAscending; // Объявляем переменную перед использованием
      return {
        ...state,
        ozon: {
          ...state.ozon,
          sortPrice: false,
          sortStock: false,
          isAscending: isAscending,
          sortByWeek: null,
          sortByWeekLastDay8: null,
          sorTarticle: false,
          averageOrdersBool: true,
          daysOfStockBool: false,
          turnoverVersion2Bool: false,
          data: state.ozon.data.sort((a, b) => {
            return isAscending
              ? (b.ads || 0) - (a.ads || 0)
              : (a.ads || 0) - (b.ads || 0);
          }),
        },
      };
    }


    //сортировка для  Остатка хватит на
    case "SORT_DAYS_STOCK_OZON": {
      const isAscending = !state.ozon.isAscending; // Объявляем переменную перед использованием
      return {
        ...state,
        ozon: {
          ...state.ozon,
          sortPrice: false,
          sortStock: false,
          isAscending: isAscending,
          sortByWeek: null,
          sortByWeekLastDay8: null,
          sorTarticle: false,
          averageOrdersBool: false,
          daysOfStockBool: true,
          turnoverVersion2Bool: false,
          data: state.ozon.data.sort((a, b) => {
            return isAscending
              ? (b.idc || 0) - (a.idc || 0)
              : (a.idc || 0) - (b.idc || 0);
          }),
        },
      };
    }

    // сортировка для Оборачиваемость
    case "SORT_TURNOVER_OZON": {
      const isAscending = !state.ozon.isAscending; // Объявляем переменную перед использованием
      return {
        ...state,
        ozon: {
          ...state.ozon,
          sortPrice: false,
          sortStock: false,
          isAscending: isAscending,
          sortByWeek: null,
          sortByWeekLastDay8: null,
          sorTarticle: false,
          averageOrdersBool: false,
          daysOfStockBool: false,
          turnoverVersion2Bool: true,
          data: state.ozon.data.sort((a, b) => {
            return isAscending
              ? (b.turnover || 0) - (a.turnover || 0)
              : (a.turnover || 0) - (b.turnover || 0);
          }),
        },
      };
    }


    case "SET_SEARCH_VALUE":
      return {
        ...state,
        loading: false,
        ozon: {
          ...state.ozon,
          searchValue: action.payload,
          currentPage: 1, // При новом поиске сбрасываем страницу
          data: [], // Очищаем данные перед новым запросом
          hasMore: true, // Снова включаем подгрузку

        },
      };




    default:
      return state;
  }
};

export default getOzonisListReducer;
