import {
    Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton,
    ModalBody, ModalFooter, Button, Text, Box, Flex, IconButton, Tag, useToast
} from "@chakra-ui/react";
import { DeleteIcon } from "@chakra-ui/icons";
import axios from "axios";

const UsedCompaniesModal = ({ isOpen, onClose, promo, onCompanyRemoved }) => {
    const toast = useToast();

    const handleRemoveCompany = async (companyId) => {
        try {
            await axios.patch(`/promo/${promo._id}/remove-company`, { companyId });
            toast({
                title: "Компания удалена",
                description: "Компания может снова использовать промокод",
                status: "success",
                duration: 3000,
                isClosable: true,
            });
            onCompanyRemoved(); // обновить список
        } catch (err) {
            toast({
                title: "Ошибка удаления",
                description: err.response?.data?.message || "Произошла ошибка",
                status: "error",
            });
        }
    };

    return (
        <Modal isOpen={isOpen} onClose={onClose} size="lg">
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Использовано компаниями</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    {promo?.usedByCompanies?.length === 0 ? (
                        <Text>Пока ни одна компания не использовала промокод.</Text>
                    ) : (
                        <Box display="grid" gap={3}>
                            {promo?.usedByCompanies?.map((company) => (
                                <Flex key={company._id} align="center" justify="space-between" p={3} borderWidth={1} borderRadius="md">
                                    <Tag fontSize="md">{company.sellersname}</Tag>
                                    <IconButton
                                        icon={<DeleteIcon />}
                                        aria-label="Удалить"
                                        size="sm"
                                        colorScheme="red"
                                        onClick={() => handleRemoveCompany(company._id)}
                                    />
                                </Flex>
                            ))}
                        </Box>
                    )}
                </ModalBody>
                <ModalFooter>
                    <Button onClick={onClose}>Закрыть</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

export default UsedCompaniesModal;
