import dayjs from "dayjs";

const yesterday = dayjs().subtract(1, "day");
const eightWeeksAgo = yesterday.subtract(8, "week");

const getWBunit = {
  wnUnit: [],
  loading: false,
  error: null,
  currentPage: 1,
  itemsPerPage: 10,
  totalPages: 0,
  date_from: eightWeeksAgo.format("YYYY-MM-DD"),
  date_to: yesterday.format("YYYY-MM-DD"),
  searchValue: "",
  isAscending: false,   // текущий порядок сортировки
  sortedField: null,    // поле, по которому идет сортировка
};

const getWBunitReducer = (state = getWBunit, action) => {
  switch (action.type) {
    case 'FETCH_WB_UNIT_REQUEST':
      return {
        ...state,
        loading: true,
        error: null
      };
    case 'FETCH_WB_UNIT_SUCCESS':
      return {
        ...state,
        loading: false,
        wnUnit: action.payload.data,
        totalPages: action.payload.totalPages
      };
    case 'FETCH_WB_UNIT_FAILURE':
      return {
        ...state,
        loading: false,
        error: "error"
      };
    case 'SET_PAGE_WBUNIT_TABLE':
      return {
        ...state,
        currentPage: action.payload.currentPage
      };
    case 'REORDER_WB_UNIT': // Обрабатываем перетаскивание
      return {
        ...state,
        wnUnit: action.payload,
      };
    case 'SET_DATE_RANGE_WBUNIT': // Обработка изменения дат
      return {
        ...state,
        date_from: dayjs(action.payload.date_from).format("YYYY-MM-DD"),
        date_to: dayjs(action.payload.date_to).format("YYYY-MM-DD"),
      };
    case 'CHANGE_SEARCH_VALUE_WB_UNIT': // Обработка изменения значения поиска
      return {
        ...state,
        searchValue: action.payload,
      };
    case "SORT_WB_UNIT": {
      // action.payload содержит { field }
      const { field } = action.payload;
      let isAscending;
      // Если сортировка уже идет по выбранному полю — переключаем порядок,
      // иначе сбрасываем порядок к возрастанию
      if (state.sortedField === field) {
        isAscending = !state.isAscending;
      } else {
        isAscending = true;
      }
      return {
        ...state,
        sortedField: field,
        isAscending,
        wnUnit: [...state.wnUnit].sort((a, b) => {
          const aValue = a[field] ?? 0;
          const bValue = b[field] ?? 0;
          // Если значения строковые, используем localeCompare
          if (typeof aValue === "string" && typeof bValue === "string") {
            return isAscending
              ? aValue.localeCompare(bValue)
              : bValue.localeCompare(aValue);
          }
          // Для числовых значений
          return isAscending ?  bValue - aValue:aValue - bValue;
        }),
      };
    }


    case 'SET_EMPTY_ARRAY_WB_UNIT': // Обработка изменения значения поиска
    return {
      ...state,
      wnUnit:[],
      currentPage:1
    };
    default:
      return state;
  }
};

export default getWBunitReducer;
