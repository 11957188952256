
import { useEffect, useRef, useState } from 'react';
import './tableTask.css';
import './tableTaskDark.css';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import Tr from './tr';
import { useNavigate } from 'react-router';
import TaskMenegerHeader from './taskMenegerHeader/taskMenegerHeader';
import ForDekstopTable from './forDekstopTable/forDekstopTable';
import ForMobileAndTable from './ForMobileAndTable/forMobileAndTable';
import NoTaskPage from '../noTaskPage/noTaskPage';
import NothingFoundSearchTask from '../Nothing found task/NothingFoundSearchTask';
import { filterBydate,filterByprioritet,filterBypstatus,filterByprice, getAllTask, setPageAction } from '../../../../redux/reducer/getTaskall/action/action';


export default function TaskTable({ receivedMessages, handleDelet, delLoad, update, handleGetNotify, load, onlineUsers, sendMessage }) {

  const [data, setData] = useState([]);

  const [toggleName, setToggleName] = useState("Сортировка")
  const getAllTaskRed = useSelector(compID => compID.getAllTaskReducer)
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [filterFuncm, setfilterFunc] = useState({});
  const [searchValue, setsearchValue] = useState("")
    const filters = useSelector(state => state.optionReducer.filters);
    const taskFiltersObject = useSelector(state => state.optionReducer.taskFiltersObject);
    const myProject = useSelector((state) => state.optionReducer.myProject);
     const dispatch = useDispatch()
  

  // через эту функцию выбирается фильтрация
  const handleSelect = (e) => {

    if (e == "Приоритет") {


      filterByprioritet(e)
      setToggleName('Приоритет')
      setMenuOpen(!isMenuOpen);

    } else if (e == "Дата") {
      filterByprioritet(e)
      setToggleName('Дата')
      setMenuOpen(!isMenuOpen);

    } else if (e == "статусу") {
      filterBypstatus(e)
      setToggleName('Статусу')
      setMenuOpen(!isMenuOpen);

    }
  }
  // perfomer
  // здесь ищем сотрудника через форму
  
  const searchByPerfomer = (e) => {
     dispatch(setPageAction(1))
     if (e === "") {
          dispatch(setPageAction(1))
          let url;
          if (!filters && myProject) {
            url = `/getFindedTaskForAllProject/api?page=${1}&limit=${getAllTaskRed.itemsPerPage}`;
          } 
          const params = new URLSearchParams({
            filters: JSON.stringify(taskFiltersObject),
            page:1,  // Добавляем текущую страницу
            limit: getAllTaskRed.itemsPerPage // Добавляем количество элементов на странице
          }).toString()
          if (filters) {
            url = `/getTaskfindedByFilter/api?${params}`;
          } 
         
          if (!filters && !myProject) {
            url = `/getTaskfinded/api?page=${1}&limit=${getAllTaskRed.itemsPerPage}`;
          }
        
          dispatch(getAllTask(url));
     }
    setsearchValue(e)
    dispatch(getAllTask(`/findByInputSearch/api?inputVal=${e}`));

    // axios.post('/findByInputSearch/api', {
    //   inputVal: e
    // },

    // )
    //   .then(function (response) {
    //     console.log(response.data.data);
    //     setData(response.data.data)
    //     getAllTaskRed.users = response.data.data
    //   })
    //   .catch(function (error) {
    //     console.log(error);
    //   });
  }



  return (
    <>
      <TaskMenegerHeader searchByPerfomer={searchByPerfomer} handleSelect={handleSelect} searchValue={searchValue} />
      {
        (() => {
          if (getAllTaskRed?.users?.length > 0) {
            return (
              <>
                <ForDekstopTable getAllTaskRed={getAllTaskRed} update={update} data={data} handleGetNotify={handleGetNotify} onlineUsers={onlineUsers} filterFuncm={filterFuncm} />
                <ForMobileAndTable getAllTaskRed={getAllTaskRed} update={update} data={data} handleGetNotify={handleGetNotify} onlineUsers={onlineUsers} filterFuncm={filterFuncm} />
              
              </>
            )
          } else if (getAllTaskRed?.users?.length == 0 && searchValue != "") {
            return <NothingFoundSearchTask searchByPerfomer={searchByPerfomer} handleSelect={handleSelect} searchValue={searchValue} />
          }
          else {
            return <NoTaskPage searchByPerfomer={searchByPerfomer} handleSelect={handleSelect} searchValue={searchValue} />
          }
        })()
      }
       
    </>
  );


}

