import { getCompanyForChangeAPI } from "../../../redux/reducer/myCompanyAPI/action/action";
import { useDispatch, useSelector } from "react-redux";
import {
  Alert,
  AlertIcon,
  useToast,
  Button
} from '@chakra-ui/react';
import "./chanegApi.css";
import "./chanegApiDark.css";
import { useEffect, useState } from "react";
import axios from "axios";
import swal from "sweetalert";

import { useNavigate } from "react-router";
import { getmyinfoAction } from "../../../redux/reducer/getmyinfo/action/action";
import sockets from "../../../socket/socket";
const socket = sockets;

export default function ChangeApi() {

  const toast = useToast()
  const dispatch = useDispatch();
  const [loadOzon, setLoadOzon] = useState(false)
  const [loadWB, setLoadWB] = useState(false)
  const [ozonSuccess, setozonSuccess] = useState({})
  const [wbSuccess, setwbSuccess] = useState({})
  const navigate = useNavigate()
  const getMycOMANYapi = useSelector((mycompany) => mycompany.getMyCompanyAPIReducer);
  const getDark = useSelector(dark => dark)
  const getDarkModoe = useSelector(darkModes => darkModes)
  const getmyinforeducer = useSelector(compID => compID.getMayInfoReducer);
  const [ozonTokenVal,setozonToken]=useState("")
  const [ozonClientVAL,setozonClientVAL]=useState("")
  const [wbTokenVal,setwbTokenVal]=useState("")
  const [activeTab, setActiveTab] = useState("Wildberries"); // Храним активную вкладку

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };

  useEffect(() => {
    dispatch(getCompanyForChangeAPI("/changeapi/api"));
    dispatch(getmyinfoAction("/registration/v2/api"))
  }, [])
  const fetchVerifiedKeyOzon = async () => {
    try {
      const response = await axios.get('/getVerifiedKeyOzon/api');
      console.log(response.data);
      setozonSuccess(response.data);
    } catch (error) {
      console.error(error);
      if (error.response) {
        setozonSuccess(error.response.data);
      } else {
        setozonSuccess({ message: "Произошла ошибка" }); // или любое другое значение по умолчанию
      }
    }
  };

  const fetchVerifiedKeyWB = async () => {
    try {
      const response = await axios.get('/getVerifiedKeyWB/api');
      console.log(response.data);
      setwbSuccess(response.data);
      dispatch(getCompanyForChangeAPI("/changeapi/api"))
    } catch (error) {
      console.error(error);
      if (error.response) {
        setwbSuccess(error.response.data);
      } else {
        setwbSuccess({ message: "Произошла ошибка" }); // или любое другое значение по умолчанию
      }
    }
  };

  // здесь мы добавляем token key для Wildberries 

  const WildberriesChange = (e) => {
    dispatch(getCompanyForChangeAPI("/changeapi/api"));
    e.preventDefault()

    setLoadWB(true)
    axios({
      method: 'patch',
      url: '/changeapi/wildberries/api',
      data: {
        companyID: getMycOMANYapi.company._id,
        key1: e.target.key1.value,
      }
    }).then(data => {
      setLoadWB(false)
      swal(data.data.message, data.data.message, data.data.type);
      dispatch(getCompanyForChangeAPI("/changeapi/api"));
    })
      .catch(error => {
        dispatch(getCompanyForChangeAPI("/changeapi/api"));
        swal(error.response.data.message, error.response.data.message, error.response.data.type);
        toast({
          title: 'Ошибка',
          description: error.response.data.message,
          status: "error",
          duration: 9000,
          isClosable: true,
          position: "top"
        })
        setLoadWB(false)
        if (error.response.data.authorized === false) {
          navigate("/login")
        }

      }).finally(() => {
        fetchVerifiedKeyWB()
        
      })
  }
  // здесь мы добавляем token key для Ozon
  const ozonChange = (e) => {
    dispatch(getCompanyForChangeAPI("/changeapi/api"));
    e.preventDefault()

    setLoadOzon(true)
    axios({
      method: 'patch',
      url: '/changeapi/ozon/api',
      data: {
        companyID: getMycOMANYapi.company._id,
        apiKey: e.target.apiKey.value,
        clientId: e.target.clientId.value,
      }
    }).then(data => {
      setLoadOzon(false)
      swal(data.data.message, data.data.message, data.data.type);
      dispatch(getCompanyForChangeAPI("/changeapi/api"));
    })
      .catch(error => {
        dispatch(getCompanyForChangeAPI("/changeapi/api"));
        swal(error.response.data.message, error.response.data.message, error.response.data.type);
        toast({
          title: 'Ошибка',
          description: error.response.data.message,
          status: "error",
          duration: 9000,
          isClosable: true,
          position: "top"
        })
        setLoadOzon(false)
        if (error.response.data.authorized === false) {
          navigate("/login")
        }

      }).finally(() => {
        fetchVerifiedKeyOzon()
      })


  }

  useEffect(() => {
    fetchVerifiedKeyOzon();
    fetchVerifiedKeyWB();
    setozonToken(getMycOMANYapi.company.ozonToken)
    setozonClientVAL(getMycOMANYapi.company.ozonClientID)
    setwbTokenVal(getMycOMANYapi.company.walberisToken)
  }, [getMycOMANYapi.company._id]);

  // Optionally log ozonSuccess after it has been updated

  const renderAlert = () => {
    if (wbSuccess.success === true) {
      switch (wbSuccess.status) {
        case "активен":
          return (
            <Alert status='success'>
              <AlertIcon />
              {wbSuccess?.message}
            </Alert>
          );
        case "ожидается верификация":
          return (
            <Alert status='loading'>
              <AlertIcon />
              {wbSuccess?.message}
            </Alert>
          );
        default:
          return null; // Or handle other statuses if necessary
      }
    }
    return null; // If wbSuccess.success is false
  };
  
  // In your component's render method
  useEffect(() => {


    function onFooEvent(value) {
      dispatch(getCompanyForChangeAPI("/changeapi/api"));
      fetchVerifiedKeyWB();
      setwbTokenVal(getMycOMANYapi.company.walberisToken)
      // setozonSuccess(value)
      console.log(value,"socket vaaaaal");
      
    }


    socket.on('taskCompleted', onFooEvent);

    return () => {

      socket.off('taskCompleted', onFooEvent);
    };
  }, []);
  

  if (getMycOMANYapi.company != "" && getMycOMANYapi.company != null) {
    return (
      <div className={getDarkModoe.ToggleDarkModeReducer.chekced ? "MycompanyContApiDark" : "MycompanyContApi"}>
        <div className="tabs-header">
          <div>
            <button
              className={`tab-button ${activeTab === "Wildberries" ? "active" : ""}`}
              onClick={() => handleTabClick("Wildberries")}
            >
              <p>Wildberries</p>
              <span></span>
            </button>
            <button
              className={`tab-button ${activeTab === "Ozon" ? "active" : ""}`}
              onClick={() => handleTabClick("Ozon")}
            >
              <p>Ozon</p>
              <span></span>
            </button>
          </div>
        </div>
        <div className="tabs-content">
          {activeTab === "Ozon" && (
            <div>
              <form onSubmit={ozonChange} className="ozoncont">
                <div className="changeApiCont">
                  <div className="changeApiContChild1">
                    <p>  {getMycOMANYapi.company.sellersname}</p>
                    <div className="forBorder">
                      <label htmlFor="" style={{ color: getDark.useChangeDarkReducer.fonstColor }}>
                        <p>API ключ Ozon</p>
                        <input placeholder='API ключ Ozon' name="apiKey" value={ozonTokenVal}
                          onChange={(e)=> setozonToken(e.target.value)}
                          style={{ borderColor: ozonSuccess?.success ? "green" : "red" }}
                          type={getMycOMANYapi.company.ownerofcompanyID === getmyinforeducer.myinfo._id ? "text" : "password"} disabled={getMycOMANYapi.company.ownerofcompanyID !== getmyinforeducer.myinfo._id} />
                      </label>

                      <label htmlFor="" style={{ color: getDark.useChangeDarkReducer.fonstColor }}>
                        <p>ID клиента</p>
                        <input placeholder='ID клиента' name="clientId" value={ozonClientVAL}
                        onChange={(e)=> setozonClientVAL(e.target.value)}
                          style={{ borderColor: ozonSuccess?.success ? "green" : "red" }}
                          type={getMycOMANYapi.company.ownerofcompanyID === getmyinforeducer.myinfo._id ? "text" : "password"} disabled={getMycOMANYapi.company.ownerofcompanyID !== getmyinforeducer.myinfo._id} />

                      </label>
                      <p>Необходимо сгенерировать ключ «Admin read only». Перейдите в личный кабинет Ozon Seller и затем на страницу настроек.</p>
                      {
                        ozonSuccess.success === false &&
                        <Alert status='error'>
                          <AlertIcon />
                          {ozonSuccess?.message}
                        </Alert>
                      }
                      {
                        ozonSuccess.success === true &&
                        <Alert status='success'>
                          <AlertIcon />
                          {ozonSuccess?.message}
                        </Alert>
                      }
                      {loadOzon ?
                        <Button isLoading colorScheme='teal' loadingText='Добавить ключ' backgroundColor={"#1879DE"} variant='solid' disabled={getMycOMANYapi.company.ownerofcompanyID !== getmyinforeducer.myinfo._id}>
                          Добавить ключ
                        </Button>
                        :
                        <button colorScheme='blue' type="submit" disabled={getMycOMANYapi.company.ownerofcompanyID !== getmyinforeducer.myinfo._id}>

                          <div>
                            <svg xmlns="http://www.w3.org/2000/svg" width="23" height="24" viewBox="0 0 23 24" fill="none">
                              <path d="M18.0746 18.5805V22.1805M14.4746 18.5805H18.0746H14.4746ZM21.6746 18.5805H18.0746H21.6746ZM18.0746 18.5805V14.9805V18.5805Z" stroke="white" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round" />
                              <path d="M11.3815 9.39327L1.19922 19.5756L3.7448 22.1212M11.3815 9.39327C13.256 11.2678 16.2953 11.2678 18.1698 9.39327C20.0443 7.51881 20.0443 4.47962 18.1698 2.6051C16.2953 0.730592 13.256 0.730592 11.3815 2.6051C9.50699 4.47962 9.50699 7.51881 11.3815 9.39327Z" stroke="white" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round" />
                              <path d="M4.59375 16.1816L7.13933 18.7272" stroke="white" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                          </div>
                          Добавить ключ
                        </button>
                      }

                    </div>
                        
                  </div>
                </div>
              </form>
            </div>
          )}
          {activeTab === "Wildberries" && (
            <div>
              <form onSubmit={WildberriesChange} className="wildberiscont">
                <div className="changeApiCont">
                  <div className="changeApiContChild1">
                    <p>  {getMycOMANYapi.company.sellersname}</p>

                    <div className="forBorder">
                      <label htmlFor="" style={{ color: getDark.useChangeDarkReducer.fonstColor }}>
                        <p>API-ключ. Необходимо сгенерировать ключи для категорий «Контент», «Аналитика», «Цены и скидки», «Маркетплейс», «Статистика». Зайдите в личный кабинет поставщика и перейдите в настройки.</p>
                        <input placeholder='Контент' name="key1" required value={wbTokenVal} key={getMycOMANYapi.company.walberisToken}
                          type={getMycOMANYapi.company.ownerofcompanyID === getmyinforeducer.myinfo._id ? "text" : "password"} disabled={getMycOMANYapi.company.ownerofcompanyID !== getmyinforeducer.myinfo._id}
                          style={{ borderColor: wbSuccess?.success ? "green" : "red" }}
                          onChange={(e)=> setwbTokenVal(e.target.value)}
                        />
                      </label>
                     
                      {
                        wbSuccess.success === false &&
                        <Alert status='error'>
                          <AlertIcon />
                          {wbSuccess?.message}
                        </Alert>
                      }
                      {
                        wbSuccess.success === true && wbSuccess.wbStatus
                        === "активен" &&
                        <Alert status='success'>
                          <AlertIcon />
                          {wbSuccess?.message}
                        </Alert>
                      }
                      {
                        wbSuccess.success === true && wbSuccess.wbStatus
                        === "ожидается верификация" &&
                        <Alert status='loading'>
                          <AlertIcon />
                          {wbSuccess?.message}
                        </Alert>
                      }

                      {loadWB ?
                        <Button isLoading colorScheme='teal' loadingText='Добавить ключ' backgroundColor={"#1879DE"} variant='solid' disabled={getMycOMANYapi.company.ownerofcompanyID !== getmyinforeducer.myinfo._id}>
                          Добавить ключ
                        </Button>
                        :
                        <button colorScheme='blue' type="submit" disabled={getMycOMANYapi.company.ownerofcompanyID !== getmyinforeducer.myinfo._id}>

                          <div>
                            <svg xmlns="http://www.w3.org/2000/svg" width="23" height="24" viewBox="0 0 23 24" fill="none">
                              <path d="M18.0746 18.5805V22.1805M14.4746 18.5805H18.0746H14.4746ZM21.6746 18.5805H18.0746H21.6746ZM18.0746 18.5805V14.9805V18.5805Z" stroke="white" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round" />
                              <path d="M11.3815 9.39327L1.19922 19.5756L3.7448 22.1212M11.3815 9.39327C13.256 11.2678 16.2953 11.2678 18.1698 9.39327C20.0443 7.51881 20.0443 4.47962 18.1698 2.6051C16.2953 0.730592 13.256 0.730592 11.3815 2.6051C9.50699 4.47962 9.50699 7.51881 11.3815 9.39327Z" stroke="white" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round" />
                              <path d="M4.59375 16.1816L7.13933 18.7272" stroke="white" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                          </div>
                          Добавить ключ
                        </button>
                      }
                    </div>

                  </div>

                </div>
              </form>
            </div>
          )}
        </div>

      </div>

    )
  } else {
    return (
      <div style={{ margin: "2%", fontSize: "2rem", color: "#8E8E93" }}>Для добавления ключа необходимо создать компанию</div>
    )
  }

}
