import axios from "axios";
import dayjs from "dayjs";

export const getWbUnitAction = (url, page, limit, searchVal, dates) => {
    return async (dispatch) => {
      try {
        dispatch({ type: "FETCH_WB_UNIT_REQUEST" });
  
        const response = await axios.get(url, {
          params: {
            page: page,
            limit: limit,
            dates: [dates[0], dates[1]], // Передача массива правильно
            ...(searchVal ? { searchVal: encodeURIComponent(searchVal) } : {}),
          },
        });
  
        dispatch({
          type: "FETCH_WB_UNIT_SUCCESS",
          payload: {
            data: response.data.data,
            totalPages: response.data.totalPages,
          },
        });
      } catch (error) {
        dispatch({ type: "FETCH_WB_UNIT_FAILURE", payload: error.message });
        throw error;
      }
    };
  };
  



export const setPagewbUnit = (currentPage) => ({
    type: 'SET_PAGE_WBUNIT_TABLE',
    payload: { currentPage }
  });


  export const reorderWbUnit = (newOrder) => ({
    type: 'REORDER_WB_UNIT',
    payload: newOrder,
});


export const changeDateFromTo = (value) => ({
    type: 'SET_DATE_RANGE_WBUNIT',
    payload: {
        date_from: dayjs(value[0]).format("YYYY-MM-DD"),
        date_to: dayjs(value[1]).format("YYYY-MM-DD")
    }
});





export const changeSreachValueWbUnit = (value) => ({
    type: 'CHANGE_SEARCH_VALUE_WB_UNIT',
    payload:value
});



export const sortingwbunit = (value) => ({
    type: "SORT_WB_UNIT",
});





export const changeCompanyClear = (currentPage) => ({
    type: 'SET_EMPTY_ARRAY_WB_UNIT',
  });
