import { useEffect, useState } from "react";
import {
  ShareAltOutlined
} from '@ant-design/icons';
import { Button } from "antd";
import Spinner from 'react-bootstrap/Spinner';
import Modal from "react-bootstrap/Modal";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./addemployeeplusLigth.css";
import "./addemployeeplusDark.css";
import axios from "axios";
import "react-phone-number-input/style.css";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";

import swal from "sweetalert";
import { useDispatch, useSelector } from "react-redux";
import Form from 'react-bootstrap/Form';
import { fetchUsers } from "../../../redux/reducer/postFetcReducer/action/action";
import { getMyCompany } from "../../../redux/reducer/getCompany/action/action";
import ru from 'react-phone-number-input/locale/ru'
import { isPossiblePhoneNumber } from "react-phone-number-input";
import { useRef } from "react";
import { notification } from "antd";
import GenerateLink from "../../page/generateLink/link/linik";

export default function AddemployeeplusLigth() {
  const dipatch = useDispatch()
  const [number, setNumber] = useState(null);
  const [show, setShow] = useState(false);
  const [load, setload] = useState(false);
  const [rols, setrols] = useState("");
  const [generatedLink, setGeneratedLink] = useState("");
  const [loadGenerate, setLoadGenerate] = useState(false);
  const [shareSupported, setShareSupported] = useState(false);
  const getDarkModoe = useSelector(darkModes => darkModes);
  useEffect(() => {
    dipatch(fetchUsers())
    dipatch(getMyCompany("/mycopany/getallcompany"))
  }, [dipatch])
  const modalRef = useRef();



  //---------------------------------------------------------------------------------------------------
  //эта функция добавляет нового сотрудника
  const addemployee = async () => {
   
    
    if (number) {
      let replcaePhone = number.replace(/\+/g, '');
      dipatch(fetchUsers())
      if (number && rols) {
        dipatch(fetchUsers())
        //здесь мы проверяем тип поля, который должен быть числом
        if (!number) return

        //токен для авторизации
        setload(true)

        axios
          .post("/addemploye/api", {
            number: replcaePhone,
            rols
          })
          .then(function (response) {
            console.log(response);
            swal(response.data.message, response.data.message, "success");
            setShow(false);
            setNumber("")
            dipatch(fetchUsers())
          })
          .catch(function (error) {
            setload(false)
            notification.error({
              message: "Ошибка",
              description: error.response.data.message,
              duration: 5,
            });
          }).finally(rezult => {
            setload(false)
            dipatch(fetchUsers())
          })
      } else {

        notification.error({
          message: "Ошибка",
          description: "Вы не набрали номер сотрудника или не выбрали роль для сотрудника",
          duration: 5,
        });

      }
    }


    // redux


  };

  useEffect(() => {
    if (show) {
      modalRef.current.style.display = "flex";
    } else {
      modalRef.current.style.display = "none";
    }
  }, [show])


  const copyToClipboard = (text) => {
    let numberString = number.toString()
        
    if (isValidPhoneNumber(numberString)) {
      if (text) {
        navigator.clipboard.writeText(text).then(() => {
          notification.success({
            message: "Ссылка скопирована в буфер обмена",
            duration: 5,
          });
        });
      }else{
        notification.error({
          message: "Данные неверны.",
          duration: 5,
        });
      }
    }
   
  };

  const generateLink = async () => {
    if (!rols) {
      notification.error({
        message: "Выберите роль",
        duration: 5,
      });
 

      setGeneratedLink("");
      return;
    }
      if (number) {
        let numberString = number.toString()
        if (isValidPhoneNumber(numberString) === false) {
          console.log(isValidPhoneNumber(numberString),"isValidPhoneNumber(numberString)");
          
          notification.error({
            message: "Данные неверны.",
            duration: 5,
          });
          setGeneratedLink("");
          return;
        }
      }


    setLoadGenerate(true);

    axios.post("/generate-invite", { rols })
      .then((response) => {
        const inviteLink = response.data.inviteLink;
        setGeneratedLink(inviteLink);
        copyToClipboard(inviteLink); // Передаем ссылку напрямую
      })
      .catch((error) => {
        setGeneratedLink("");
        notification.warning({
          message: "Ошибка при генерации",
          description: error.response?.data?.message || "Неизвестная ошибка",
          duration: 5,
        });
      })
      .finally(() => {
        setLoadGenerate(false);
      });
  };


  useEffect(() => {
    // Проверка поддержки Web Share API
    if (navigator.share) {
      setShareSupported(true);
    }
  }, []);

  // Функция шаринга текста (ссылки) через Web Share API
  const shareText = async () => {
    if (generatedLink && navigator.share) {
      try {
        await navigator.share({
          title: "Поделиться ссылкой",
          url: generatedLink,
        });
      } catch (err) {
        console.error("Ошибка при шаринге ссылки:", err);
      }
    } else {
      console.warn("Web Share API не поддерживается или ссылка не установлена");
    }
  };

  return (
    <>
      <button className="addEmloyLeftBtnCont" onClick={() => setShow(!show)}>

        <div>
          <div>
            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
              <path d="M11.75 8C11.75 7.58579 11.4142 7.25 11 7.25C10.5858 7.25 10.25 7.58579 10.25 8V10.25H8C7.58579 10.25 7.25 10.5858 7.25 11C7.25 11.4142 7.58579 11.75 8 11.75H10.25V14C10.25 14.4142 10.5858 14.75 11 14.75C11.4142 14.75 11.75 14.4142 11.75 14V11.75H14C14.4142 11.75 14.75 11.4142 14.75 11C14.75 10.5858 14.4142 10.25 14 10.25H11.75V8Z" fill="white" />
              <path fillRule="evenodd" clipRule="evenodd" d="M11 0.25C5.06294 0.25 0.25 5.06294 0.25 11C0.25 16.9371 5.06294 21.75 11 21.75C16.9371 21.75 21.75 16.9371 21.75 11C21.75 5.06294 16.9371 0.25 11 0.25ZM1.75 11C1.75 5.89137 5.89137 1.75 11 1.75C16.1086 1.75 20.25 5.89137 20.25 11C20.25 16.1086 16.1086 20.25 11 20.25C5.89137 20.25 1.75 16.1086 1.75 11Z" fill="white" />
            </svg>
          </div>
          <p>Добавить сотрудника</p>
        </div>
      </button>

      <div id="myModal" className={getDarkModoe.ToggleDarkModeReducer.chekced ? "addNewUserModalDarkCont" : "addNewUserModalLigthCont"} ref={modalRef}>
        <div className="addNewUserModalLigth-content" >
          <div className="addNewUserModal-header">
            <div>
              <p>Добавить сотрудника</p>
              <div onClick={() => setShow(!show)}>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path d="M10.6712 12L7.27521 8.60406C6.90826 8.23711 6.90826 7.64216 7.27521 7.27521C7.64216 6.90826 8.23711 6.90826 8.60406 7.27521L12 10.6712L15.3959 7.27521C15.7629 6.90826 16.3578 6.90826 16.7248 7.27521C17.0917 7.64216 17.0917 8.23711 16.7248 8.60406L13.3288 12L16.7248 15.3959C17.0917 15.7629 17.0917 16.3578 16.7248 16.7248C16.3578 17.0917 15.7629 17.0917 15.3959 16.7248L12 13.3288L8.60406 16.7248C8.23711 17.0917 7.64216 17.0917 7.27521 16.7248C6.90826 16.3578 6.90826 15.7629 7.27521 15.3959L10.6712 12Z" fill="#353535" />
                </svg>
              </div>
            </div>
            <p>Введите все доступные данные для создания профиля сотрудника</p>
          </div>
          <div className="addNewUserModal-body">
            <div>
              <div>
                <label htmlFor="">Номер телефона</label>
                <PhoneInput
                  initialValueFormat="national"
                  labels={ru}
                  international
                  defaultCountry={"RU"}
                  limitMaxLength
                  placeholder="Введите номер"
                  value={number}
                  onChange={setNumber}
                  error={number ? (isPossiblePhoneNumber(number) ? undefined : 'Invalid phone number') : 'Phone number required'}
                />
              </div>
              <div>
                <label htmlFor="">Должность</label>
                <select name="cars" id="cars" onChange={(e) => setrols(e.target.value)} required={true}>
                  <option value="">Выберите роль сотрудника</option>
                  <option value="Менеджер маркетплейсов">Менеджер маркетплейсов</option>
                  <option value="Контент менеджер">Контент менеджер</option>
                  <option value="SEO-специалист">SEO-специалист</option>
                  <option value="Дизайнер">Дизайнер</option>
                </select>


              </div>

            </div>
          </div>
          <div className="addNewUserModal-footer">
            <button className="addEmloyLeftBtnCont" onClick={generateLink} style={{ opacity: loadGenerate ? "0.6" : "1" }} disabled={loadGenerate}>
              <div>

                <p>
                  Отправить ссылку
                </p>
              </div>
            </button>
            <button className="closeaddNewUserModal" onClick={() => setShow(!show)}>Отменить</button>
            <button className="addEmloyLeftBtnCont" onClick={addemployee}>
              <div>
                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
                  <path d="M11.75 8C11.75 7.58579 11.4142 7.25 11 7.25C10.5858 7.25 10.25 7.58579 10.25 8V10.25H8C7.58579 10.25 7.25 10.5858 7.25 11C7.25 11.4142 7.58579 11.75 8 11.75H10.25V14C10.25 14.4142 10.5858 14.75 11 14.75C11.4142 14.75 11.75 14.4142 11.75 14V11.75H14C14.4142 11.75 14.75 11.4142 14.75 11C14.75 10.5858 14.4142 10.25 14 10.25H11.75V8Z" fill="#FAFAFA" />
                  <path fillRule="evenodd" clipRule="evenodd" d="M11 0.25C5.06294 0.25 0.25 5.06294 0.25 11C0.25 16.9371 5.06294 21.75 11 21.75C16.9371 21.75 21.75 16.9371 21.75 11C21.75 5.06294 16.9371 0.25 11 0.25ZM1.75 11C1.75 5.89137 5.89137 1.75 11 1.75C16.1086 1.75 20.25 5.89137 20.25 11C20.25 16.1086 16.1086 20.25 11 20.25C5.89137 20.25 1.75 16.1086 1.75 11Z" fill="#FAFAFA" />
                </svg>
                <p>Добавить</p>
              </div>
            </button>

          </div>
          {generatedLink && (
            <div className="link-box" >
              <p>Отправить ссылку:</p>

              <input type="text" value={generatedLink} readOnly />
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="20"
                height="20"
                className="copy-icon"
              >
                <path
                  d="M9 2H4a2 2 0 0 0-2 2v13h2V4h5V2zm7 4H8a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V8a2 2 0 0 0-2-2zm0 14H8V8h8v12z"
                  fill="currentColor"
                />
              </svg>
            </div>
          )}

          {shareSupported && generatedLink && (
            <>
              <Button onClick={shareText} style={{ marginTop: '10px', marginLeft: '10px', backgroundColor: "#3B79F6", color: "white",borderRadius:"16px",height:"48px",padding:"16px" }}>
                Поделиться ссылкой

                <ShareAltOutlined />
              </Button>
            </>
          )}

        </div>

      </div>
      <ToastContainer />
    </>
  );
}
