import { diff } from 'deep-diff';

const getAllTaskInitialState = {
  users: [],
  loading: false,
  error: null,
  currentPage: 1,
  itemsPerPage: 10,
  totalPages: 0,
  task_deadlines: false,
  prioritet: false,
  status: false,
  price: false,
};

// Функция сортировки в зависимости от текущего состояния
const applySorting = (users, state) => {
  let sortedUsers = [...users];

  if (state.task_deadlines) {
    sortedUsers.sort((a, b) => {
      const dateA = new Date(a.alltask[0]?.endDate);
      const dateB = new Date(b.alltask[0]?.endDate);
      return dateA - dateB;
    });
  }

  if (state.prioritet) {
    const priorityOrder = { Критический: 0, Важно: 1, Срочно: 2, Низший: 3 };
    sortedUsers.sort((a, b) => {
      const priority1 = priorityOrder[a.alltask[0]?.prioritet] || 0;
      const priority2 = priorityOrder[b.alltask[0]?.prioritet] || 0;
      return priority2 - priority1;
    });
  }

  if (state.status) {
    const statusOrder = {
      Новая: 0,
      Возвращена: 1,
      Выполняется: 2,
      Выполнена: 3,
      Отменено: 4,
      Закрыта: 5,
    };
    sortedUsers.sort((a, b) => {
      const status1 = statusOrder[a.alltask[0]?.status] || 0;
      const status2 = statusOrder[b.alltask[0]?.status] || 0;
      return status2 - status1;
    });
  }

  if (state.price) {
    sortedUsers.sort((a, b) => {
      const priceA = a.alltask[0]?.price || 0;
      const priceB = b.alltask[0]?.price || 0;
      return priceB - priceA;
    });
  }

  return sortedUsers;
};

const getAllTaskReducer = (state = getAllTaskInitialState, action) => {
  switch (action.type) {
    case 'FETCH_GETALLTASK_REQUEST':
      return { ...state, loading: true, error: null };

    case 'FETCH_GETALLTASK_SUCCESS': {
      const newUsers = action.payload.users;
      return {
        ...state,
        loading: false,
        users: applySorting(newUsers, state), // Сразу применяем сортировку
        currentPage: action.payload.currentPage,
        totalPages: action.payload.totalPages,
        itemsPerPage: action.payload.itemsPerPage || state.itemsPerPage,
      };
    }

    case 'FETCH_GETALLTASK_FAILURE':
      return { ...state, loading: false, error: action.payload };

    case 'SET_PAGE_TABLE':
      return { ...state, currentPage: action.payload.currentPage };

    case 'SORT_BY_DATE':
      return {
        ...state,
        users: [...state.users].sort((a, b) => {
          const dateA = new Date(a.alltask[0]?.endDate);
          const dateB = new Date(b.alltask[0]?.endDate);
          return state.task_deadlines ? dateA - dateB : dateB - dateA;
        }),
        task_deadlines: !state.task_deadlines,
        prioritet: false,
        price: false,
        status: false,
      };

    case 'SORT_BY_PRIORITET':
      return {
        ...state,
        users: [...state.users].sort((a, b) => {
          const priorityOrder = { Критический: 0, Важно: 1, Срочно: 2, Низший: 3 };
          const priority1 = priorityOrder[a.alltask[0]?.prioritet] || 0;
          const priority2 = priorityOrder[b.alltask[0]?.prioritet] || 0;
          return state.prioritet ? priority1 - priority2 : priority2 - priority1;
        }),
        prioritet: !state.prioritet,
        task_deadlines: false,
        price: false,
        status: false,
      };

    case 'SORT_BY_STATUS':
      return {
        ...state,
        users: [...state.users].sort((a, b) => {
          const statusOrder = {
            Новая: 0,
            Возвращена: 1,
            Выполняется: 2,
            Выполнена: 3,
            Отменено: 4,
            Закрыта: 5,
          };
          const status1 = statusOrder[a.alltask[0]?.status] || 0;
          const status2 = statusOrder[b.alltask[0]?.status] || 0;
          return state.status ? status1 - status2 : status2 - status1;
        }),
        task_deadlines: false,
        prioritet: false,
        price: false,
        status: !state.status,
      };

    case 'SORT_BY_PRICE':
      return {
        ...state,
        users: [...state.users].sort((a, b) => {
          const priceA = a.alltask[0]?.price || 0;
          const priceB = b.alltask[0]?.price || 0;
          return state.price ? priceA - priceB : priceB - priceA;
        }),
        task_deadlines: false,
        prioritet: false,
        status: false,
        price: !state.price,
      };

    default:
      return state;
  }
};

export default getAllTaskReducer;



