import { useDispatch, useSelector } from "react-redux"
import { getOzonPorductList, setValueOzon, updateCurrentPage } from "../../../../redux/reducer/ozonGetProductLIst/action/action";
import { useEffect, useRef, useState, useTransition } from "react";


export default function OzonSearch(params) {
    const dispatch = useDispatch()
    const { searchValue } = useSelector((state) => state.getOzonisListReducer.ozon);
      const [loading, setLoading] = useState(false);
      const [isPending, startTransition] = useTransition();

      useEffect(() => {
        setLoading(true);
        const delay = setTimeout(() => {
          startTransition(() => {
            dispatch(updateCurrentPage(1));
            dispatch(getOzonPorductList("/getozoList/api", 1, searchValue))
              .finally(() => setLoading(false));
          });
        }, 800);
    
        return () => clearTimeout(delay);
      }, [searchValue]);


    const handleSearch = (e) => {
        dispatch(setValueOzon(e))

    };



    return (
        <label className="inputSearchMarketplace">
            <input type="text" value={searchValue} onChange={(e) => handleSearch(e.target.value)} />
            {loading && <span>Загрузка...</span>}
            <div>
                <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 23 23" fill="none">
                    <path fillRule="evenodd" clipRule="evenodd" d="M10 0.25C4.61522 0.25 0.25 4.61522 0.25 10C0.25 15.3848 4.61522 19.75 10 19.75C15.3848 19.75 19.75 15.3848 19.75 10C19.75 4.61522 15.3848 0.25 10 0.25ZM1.75 10C1.75 5.44365 5.44365 1.75 10 1.75C14.5563 1.75 18.25 5.44365 18.25 10C18.25 14.5563 14.5563 18.25 10 18.25C5.44365 18.25 1.75 14.5563 1.75 10Z" fill="#A8A8A8" />
                    <path d="M18.5304 17.4698C18.2375 17.1769 17.7626 17.1769 17.4697 17.4698C17.1768 17.7626 17.1768 18.2375 17.4697 18.5304L21.4696 22.5304C21.7625 22.8233 22.2374 22.8233 22.5303 22.5304C22.8232 22.2375 22.8232 21.7626 22.5303 21.4697L18.5304 17.4698Z" fill="#A8A8A8" />
                </svg>
            </div>
        </label>
    )
}