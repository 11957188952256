import { useNavigate } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import { getMyCompany } from "../../../redux/reducer/getCompany/action/action";
import { useCallback, useEffect, useRef, useState } from "react";
import axios from "axios";
import { checkedCopmany } from "../../../redux/reducer/checkedCompany/action/action";
import { fetchUsers } from "../../../redux/reducer/postFetcReducer/action/action";
import { getCompanyForChangeAPI } from "../../../redux/reducer/myCompanyAPI/action/action";
import DeleteCompany from "../../page/addCompany/deleteCompany/delCompany";
import { getmyinfoAction } from "../../../redux/reducer/getmyinfo/action/action";
import EditCompany from "../../page/addCompany/editCompany/editcopnay";
import { checkedSessionAction } from "../../../redux/reducer/getCheckedCompanySession/action/action";
import CheckedRadioBTN from "../checkedBtn/checkedrdaio";
import ByAxios from "../../../hook/hookFetch/axios";
import "./changeCompanyLight.css"
import "./changeCompanyDark.css"
import sockets from "../../../socket/socket";
import ToggleAllMyProject from "../toggleAllMyProject/toggleAllMyProject";
import { getOzonPorductList } from "../../../redux/reducer/ozonGetProductLIst/action/action";
import { getOnlineUserViaRouterFunc } from "../../../redux/reducer/getOnlineUserviaRouter/action/action";
import { changeCompanyClear, getWbUnitAction } from "../../../redux/reducer/wbUnit/action/action";
import { getWaldberisListAction, updateCompany } from "../../../redux/reducer/getwildberrieslIST/action/action";
import { getAllTask, setPageAction } from "../../../redux/reducer/getTaskall/action/action";

const socket = sockets;


export default function ChangeCompanyDrower() {
    const getDarkModoe = useSelector(darkModes => darkModes);
    const [isMobile, setIsMobile] = useState(false);
    const [countTask, setCountTask] = useState([]);
    const [countMessage, setMessage] = useState([]);
    const navigate = useNavigate();

    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef(null);
    const dispatch = useDispatch();
    const [show, setShow] = useState(false);
    const [checkedINdexCopmany, setcheckedINdexCopmany] = useState(0);
    const [value, setValue] = useState('0');
    const [searchCompanyVal, setsearchCompanyVal] = useState('');
    const getAllCompany = useSelector(company => company.userReducerCompany.users);
    const checked = useSelector(company => company.userReducerCompanyChecked);
    const getDefaulyCheckedSession = useSelector(company => company.checkedSessionReducer);
    const getAllCompanyRed = useSelector(company => company);
    const myinfo = useSelector(myinfo => myinfo);
    const myIDs = myinfo.getMayInfoReducer.myinfo._id
    const myProject = useSelector((state) => state.optionReducer.myProject);
    let [data, error, loading, getData] = ByAxios("/auth/login/api");
    const { currentPage, itemsPerPage, totalPages, wnUnit, date_from, date_to, searchValue } = useSelector(getWBunitReducer => getWBunitReducer).getWBunitReducer;
    // это свойство для переключения задач
    const getAllTaskRed = useSelector(compID => compID.getAllTaskReducer);
    const filters = useSelector(state => state.optionReducer.filters);
    const taskFiltersObject = useSelector(state => state.optionReducer.taskFiltersObject);
    // ==========================================================================================
    useEffect(() => {
        setIsOpen(false);
        getData()
    }, [])
    // console.log(getDark.useChangeDarkReducer);
    // stanum enq @nkerutyun@ @st szbanakan @nterulu hamar (bydefault)

    useEffect(() => {
        dispatch(getCompanyForChangeAPI("/changeapi/api"));
        dispatch(checkedSessionAction("/getCheckedCompanySession"));
        dispatch(getMyCompany("/mycopany/getallcompany"))
        dispatch(fetchUsers())
        dispatch(getmyinfoAction("/registration/v2/api"))

        dispatch(fetchUsers())

        if (getAllCompany.data) {
            dispatch(getCompanyForChangeAPI("/changeapi/api"));
            axios({
                method: 'get',
                url: '/mycopany/getallcompany',

            })
                .then(function (response) {
                    if (Array.isArray(response.data.data) && response.data.data.length > 0 && response.data.data[0].companyId) {
                        dispatch(checkedCopmany(response.data.data[0].companyId));
                        setValue("0");
                    } else {
                        console.warn("Данные отсутствуют или пусты:", response.data.data);
                    }





                });
        }


    }, [dispatch])

    // когда переключается компания этот функционал работает чтобы переключать задачи
    const handlePageChange = (page) => {
        dispatch(setPageAction(page))
        let url;
        if (!filters && myProject) {
            url = `/getFindedTaskForAllProject/api?page=${page}&limit=${getAllTaskRed.itemsPerPage}`;
        }
        const params = new URLSearchParams({
            filters: JSON.stringify(taskFiltersObject),
            page,  // Добавляем текущую страницу
            limit: getAllTaskRed.itemsPerPage // Добавляем количество элементов на странице
        }).toString()
        if (filters) {
            url = `/getTaskfindedByFilter/api?${params}`;
        }

        if (!filters && !myProject) {
            url = `/getTaskfinded/api?page=${page}&limit=${getAllTaskRed.itemsPerPage}`;
        }

        dispatch(getAllTask(url));
    };
// это функция предназначена для того чтобы переключить  компанию когда компания будет переключена а ты это зависит весь функционал сайта чтобы переключать компании для весь функционала
    const changeCompanyID = async (companyId, index) => {
        // Сохраняем выбранный индекс компании и отображаем окно загрузки
        setcheckedINdexCopmany(index)
        setShow(true)
        dispatch(checkedCopmany(companyId))
    
        // Проверяем, что есть companyID в checked
        if (checked.companyID) {
            try {
                // Делаем запрос для смены выбранной компании
                const rezult = await axios.get(`/mycopany/changechecked?checked=${companyId}`);
    
                // После успешного запроса вызываем остальные экшены
                dispatch(getCompanyForChangeAPI("/changeapi/api"));
                dispatch(getMyCompany("/mycopany/getallcompany"));
                dispatch(changeCompanyClear());
                dispatch(updateCompany(companyId));
                socket.emit('getAllTask', { companyIDS: companyId });
                dispatch(getOnlineUserViaRouterFunc());
                socket.emit("getNotifyEmit");
                socket.emit('joinRoom', rezult.data.companyID);
                dispatch(fetchUsers());
                dispatch(getOzonPorductList("/getozoList/api"));
                handlePageChange(1);
                dispatch(getWaldberisListAction('/getWBList/api', 1, ""));
                dispatch(getWbUnitAction('/getwbUnit/api', currentPage, 10, searchValue, [date_from, date_to]));

                setIsOpen(!isOpen);
            } catch (error) {
                // Обработка ошибки запроса, можно, например, вывести сообщение об ошибке
                setIsOpen(!isOpen);
                console.error("Ошибка при смене компании:", error);
            }
        }
    };
    


    const handleGet = () => {
        console.log("updateSocket");

        dispatch(getCompanyForChangeAPI("/changeapi/api"));
        dispatch(getMyCompany("/mycopany/getallcompany"))
    }



    const getCompanyAndChange = () => {
        dispatch(getMyCompany("/mycopany/getallcompany"))
        dispatch(getCompanyForChangeAPI("/changeapi/api"))
        console.log("update socket");

    }

    async function updateALLCompany(companyID) {
        dispatch(getCompanyForChangeAPI("/changeapi/api"));
        dispatch(checkedSessionAction("/getCheckedCompanySession"));
        dispatch(fetchUsers())
        dispatch(getmyinfoAction("/registration/v2/api"))
        dispatch(getMyCompany("/mycopany/getallcompany"))
        dispatch(fetchUsers())

        if (getAllCompany.data) {
            dispatch(getCompanyForChangeAPI("/changeapi/api"));
            axios({
                method: 'get',
                url: '/mycopany/getallcompany',

            })
                .then(function (response) {
                    if (getAllCompany.data && getAllCompany.data.length > 0 && getDefaulyCheckedSession.checkedSession === companyID) {
                        if (response.data?.data?.length > 0 && response.data.data[0]?.companyId) {
                            dispatch(checkedCopmany(response.data.data[0].companyId));
                            setValue("0");
                            changeCompanyID(response.data.data[0].companyId, 0);
                        } else {
                            console.warn("companyId отсутствует или data пустой", response.data);
                        }


                    }




                });
        }


    }

    useEffect(() => {
        const updateCompanyHandler = (companyID) => {
            updateALLCompany(companyID)
        };

        socket.on('updateCompany', updateCompanyHandler);
        return () => {
            socket.off('updateCompany', updateCompanyHandler);
        };
    }, [dispatch, getAllCompany]); // Обновляем массив зависимостей

    useEffect(() => {
        if (searchCompanyVal !== "") {
            dispatch(getMyCompany("/mycopany/getallcompanySearch", searchCompanyVal))

        } else {
            dispatch(getMyCompany("/mycopany/getallcompany", searchCompanyVal))
        }
    }, [searchCompanyVal])



    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener("click", handleClickOutside);
        return () => {
            document.removeEventListener("click", handleClickOutside);
        };
    }, []);


    // for mobile and dekstop queries for toggleBTN className
    useEffect(() => {
        const handleMediaQueryChange = (event) => {
            setIsMobile(event.matches);
        };

        // Create a media query for max-width: 617px
        const mediaQuery = window.matchMedia('(max-width: 617px)');

        // Initial check for the media query
        setIsMobile(mediaQuery.matches);

        // Add a listener for changes to the media query
        mediaQuery.addListener(handleMediaQueryChange);

        // Clean up the listener when the component unmounts
        return () => {
            mediaQuery.removeListener(handleMediaQueryChange);
        };

    }, [isMobile]);
    const svgRotate = useRef();

    useEffect(() => {
        if (isOpen === true && svgRotate.current) {
            svgRotate.current.style.transform = "rotate(180deg)";

        } else if (svgRotate.current) {
            svgRotate.current.style.transform = "rotate(0)";

        }
        if (svgRotate.current) {
            svgRotate.current.style.transition = "0.3s";
        }
    }, [isOpen]);




    useEffect(() => {
        // Функция для получения данных
        function getCounterSetForListOfCompanies() {
            axios.get('/getcounterSetforlistNotifyofCompanies/api')
                .then(function (response) {
                    setCountTask(response.data);
                })
                .catch(function (error) {
                    console.error(error);
                });
        }

        // Получаем данные при первом рендере и при получении нового сообщения
        getCounterSetForListOfCompanies();
        socket.on('getNotify', getCounterSetForListOfCompanies);

        // Очистка эффекта
        return () => {
            socket.off('getNotify', getCounterSetForListOfCompanies);
        };
    }, [isOpen]); // Зависимость isOpen определяет, когда эффект запускается

    useEffect(() => {
        // Функция для получения данных
        function getCounterSetForListOfCompanies() {
            axios.get('/getcounterSetforChats/api')
                .then(function (response) {
                    setMessage(response.data);


                })
                .catch(function (error) {
                    console.error(error);
                });
        }

        // Получаем данные при первом рендере и при получении нового сообщения
        getCounterSetForListOfCompanies();
        console.log(countMessage);

        socket.on('updateCreatMessage', getCounterSetForListOfCompanies);
        socket.on('updateSubMessage', getCounterSetForListOfCompanies);
        socket.on('deleteChat', getCounterSetForListOfCompanies);

        // Очистка эффекта
        return () => {
            socket.off('newMessage', getCounterSetForListOfCompanies);
        };
    }, [isOpen]); // Зависимость isOpen определяет, когда эффект запускается


    return (
        <div ref={dropdownRef} className={getDarkModoe.ToggleDarkModeReducer.chekced ? "dropDownForChangeCompanyContDark" : "dropDownForChangeCompanyContLight"} onClick={getCompanyAndChange}>
            <button className="dropdown-toggle" onClick={() => [toggleDropdown(), handleGet()]}>
                {
                    (() => {
                        if (getAllCompanyRed.getMyCompanyAPIReducer.company && myProject === false) {
                            return <p className={getDarkModoe.ToggleDarkModeReducer.chekced ? "isOpenP" : "isOpenPNot"}>{getAllCompanyRed.getMyCompanyAPIReducer.company.sellersname}</p>
                        } else if (getAllCompanyRed.getMyCompanyAPIReducer.company && myProject === true) {
                            return <p className={getDarkModoe.ToggleDarkModeReducer.chekced ? "isOpenP" : "isOpenPNot"}>Все проекты</p>

                        } else {
                            return <p className={getDarkModoe.ToggleDarkModeReducer.chekced ? "isOpenP" : "isOpenPNot"}>"Выберите компанию"</p>
                        }
                    })()
                }
                {
                    isOpen ?
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" ref={svgRotate}>
                            <path d="M18 9L12 15L6 9" stroke="#5E7DE8" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                        :
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" ref={svgRotate}>
                            <path d="M18 9L12 15L6 9" stroke="#353535" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                }

            </button>
            {isOpen && (
                <div>
                    <button onClick={() => [navigate("/sellertnewuserpage"), setIsOpen(false)]}>
                        <div>
                            <div>
                                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
                                    <path d="M11.75 8C11.75 7.58579 11.4142 7.25 11 7.25C10.5858 7.25 10.25 7.58579 10.25 8V10.25H8C7.58579 10.25 7.25 10.5858 7.25 11C7.25 11.4142 7.58579 11.75 8 11.75H10.25V14C10.25 14.4142 10.5858 14.75 11 14.75C11.4142 14.75 11.75 14.4142 11.75 14V11.75H14C14.4142 11.75 14.75 11.4142 14.75 11C14.75 10.5858 14.4142 10.25 14 10.25H11.75V8Z" fill="white" />
                                    <path fillRule="evenodd" clipRule="evenodd" d="M11 0.25C5.06294 0.25 0.25 5.06294 0.25 11C0.25 16.9371 5.06294 21.75 11 21.75C16.9371 21.75 21.75 16.9371 21.75 11C21.75 5.06294 16.9371 0.25 11 0.25ZM1.75 11C1.75 5.89137 5.89137 1.75 11 1.75C16.1086 1.75 20.25 5.89137 20.25 11C20.25 16.1086 16.1086 20.25 11 20.25C5.89137 20.25 1.75 16.1086 1.75 11Z" fill="white" />
                                </svg>
                            </div>
                            <p> Добавить компанию</p>
                        </div>

                    </button>
                    <div>
                        <label>
                            <input type="text" placeholder="Поиск" onChange={(e) => setsearchCompanyVal(e.target.value)} value={searchCompanyVal} />
                            <div>
                                <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 23 23" fill="none">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M10 0.25C4.61522 0.25 0.25 4.61522 0.25 10C0.25 15.3848 4.61522 19.75 10 19.75C15.3848 19.75 19.75 15.3848 19.75 10C19.75 4.61522 15.3848 0.25 10 0.25ZM1.75 10C1.75 5.44365 5.44365 1.75 10 1.75C14.5563 1.75 18.25 5.44365 18.25 10C18.25 14.5563 14.5563 18.25 10 18.25C5.44365 18.25 1.75 14.5563 1.75 10Z" fill="#A8A8A8" />
                                    <path d="M18.5304 17.4698C18.2375 17.1769 17.7626 17.1769 17.4697 17.4698C17.1768 17.7626 17.1768 18.2375 17.4697 18.5304L21.4696 22.5304C21.7625 22.8233 22.2374 22.8233 22.5303 22.5304C22.8232 22.2375 22.8232 21.7626 22.5303 21.4697L18.5304 17.4698Z" fill="#A8A8A8" />
                                </svg>
                            </div>
                        </label>
                    </div>
                    <ToggleAllMyProject />
                    <ul className="dropDownForChangeCompanydropdown-menu">
                        {
                            getAllCompany.data?.map((items, index) => {


                                const taskCount = countTask.find(task => task._id === items.companyId) || { taskCount: 0 };
                                const messageCount = countMessage.find(message => message._id.companyID === items.companyId) || { messageCount: 0 };



                                return (
                                    <div className="companiListCont" key={index}>
                                        <label htmlFor={`${"chekedCompany"}` + index} className={getDefaulyCheckedSession.checkedSession == items.companyId ? "thisChecked" : "thisCheckedNot"} key={index}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                changeCompanyID(items.companyId, index);
                                            }}
                                        >
                                            <li>
                                                <div className="Frame625952">

                                                    {
                                                        (getDefaulyCheckedSession.checkedSession == items.companyId) ?
                                                            <CheckedRadioBTN index={index} checked={true} setShow={setShow} />
                                                            :
                                                            <CheckedRadioBTN index={index} checked={false} setShow={setShow} />
                                                    }

                                                    <p>{items.sellersname}</p>
                                                </div>

                                            </li>
                                            <li>
                                                <div>
                                                    <div>
                                                        <p>Чат:</p>
                                                        <p>{messageCount?.unreadCount ? messageCount?.unreadCount : 0}</p>
                                                    </div>
                                                </div>
                                                <div>
                                                    <div>
                                                        <p>События:</p>
                                                        <p>{taskCount.taskCount || 0}</p> {/* Используем значение taskCount */}
                                                    </div>
                                                </div>
                                            </li>
                                        </label>
                                        <div className={getDefaulyCheckedSession.checkedSession == items.companyId ? "EditDelCont" : "EditDelContChekedNo"}>
                                            {
                                                (() => {
                                                    if (items.adminID == myIDs) {
                                                        return (
                                                            <>
                                                                <div>
                                                                    <EditCompany items={items} />
                                                                </div>
                                                                <div>
                                                                    <DeleteCompany items={items} />
                                                                </div>
                                                            </>
                                                        )
                                                    }
                                                })()
                                            }
                                        </div>
                                    </div>
                                )
                            })
                        }

                    </ul>
                </div>

            )}
        </div>
    )
}   