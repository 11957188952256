
import { useSelector } from "react-redux"
import WBtable from "./wbtable/wbTable"
import "./wildberries.css"
import "./wildberriesDark.css"
import WBSearch from "./wbSearch/wbSearch"
import { Outlet, Link, useLocation,NavLink  } from "react-router-dom";
import { useEffect } from "react"



export default function Wildberries() {
  const getDarkModoe = useSelector(darkModes => darkModes);
  const location =useLocation()
  useEffect(()=>{
      console.log(location.pathname === "/wildberries/wbDashbord");
      
  },[location])
  return (
    <div className={getDarkModoe.ToggleDarkModeReducer.chekced ? "wbContDark" : "wbCont"}>
      <nav>
        <ul>
          <li>
            <NavLink  to="wbDashbord" className={({ isActive }) => (isActive ? "active" : "")}>Аудит</NavLink >
          </li>
          <li>
            <NavLink   to="wnubit" className={({ isActive }) => (isActive ? "active" : "")}>Юнит</NavLink >
          </li>
        </ul>
      </nav>
        {
          location.pathname === "/wildberries/wbDashbord" &&  <WBSearch />
        }
     
      <Outlet />
      {/* < WBtable />      */}
    </div>
  )
}