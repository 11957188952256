import axios from "axios";
import sockets from "../../../../socket/socket"; // Убедитесь, что этот путь правильный

export const getWaldberisListAction = (url, page,searchVal) => {

  return (dispatch) => {
    dispatch({ type: "FETCH_WB_REQUEST" });

    // Получаем данные через Axios
    return axios // Вернем Promise от axios
      .get(`${url}?page=${page}&searchVal=${searchVal}`)
      .then((response) => {
        console.log(response.data.data,"actionn")
        dispatch({
          type: "FETCH_WB_SUCCESS",
          payload: {
            data: response.data.data, // Предполагается, что в ответе есть поле products
            weeks: response.data.weeks, // Предполагается, что в ответе есть поле weeks
          },
        });
      })
      .catch((error) => {
        dispatch({
          type: "FETCH_WB_FAILURE",
          payload: error.message,
        });
        throw error; // Пробрасываем ошибку для обработки в loadMoreData
      });
  };
};


export function handleCommentUpdate(id, value, date, commentType) {
  return (dispatch) => {
    dispatch({
      type: "UPDATE_WB_COMMENT",
      payload: {
        id,
        value, // Отправляем значение комментария
        date,  // Отправляем дату
        commentType, // Передаем тип комментария
      },
    });
  };
}


export function reorderWBProducts(newOrder) {
  return (dispatch) => {
    dispatch({
      type: "REORDER_WB_PRODUCTS",
      payload: newOrder,
    });
  };
}

export const reorderWBColumns = (columns) => ({
  type: "REORDER_WB_COLUMNS",
  payload: columns,
});


export const handleClickRow = (val) => ({
  type: "CLICK_ROWS_WB",
  payload: val,
});


export const updateCurrentPage = (page) => ({
  type: "UPDATE_CURRENT_PAGE_WB",
  payload: page,
});



export const updateCompany = (companyID) => ({
  type: "SET_CURRENT_COMPANY_WB",
  payload: companyID,
});



export const sortBYdateWB = (weekName) => ({
  type: "SORT_WEEKS_BY_DATE_WB",
  payload: weekName,
});





export const sortWeeksByDateUpdatewb = () => {
  return {
    type: "SORT_WEEKS_BY_DATE_UPDATE_WB",
  };
};


export const sortPricewb= () => {
  return {
    type: "SORT_PRICE_WB",
  };
};



export const sortStockwb = () => {
  return {
    type: "SORT_STOCK_WB",
  };
};


export const sortArcticulewb = () => {
  return {
    type: "SORT_ACTICULE_WB",
  };
};



export const sortWeek8dayswb = (weekName) => {
  return {
    type: "SORT_LASTWEEKDAY8_WB",
    payload: weekName,
  };
};



export const sortAverageOrderswb = (weekName) => {
  return {
    type: "SORT_AVERAGEORDERS_WB",
    payload: weekName,
  };
};

export const sortDaysOfStockwb = (weekName) => {
  return {
    type: "SORT_DAYS_STOCK_WB",
    payload: weekName,
  };
};

export const sortTurnoverwb = (weekName) => {
  return {
    type: "SORT_TURNOVER_WB",
    payload: weekName,
  };
};




export const setValueWB = (searchValue) => {


  return {
    type: "SET_SEARCH_VALUEWB",
    payload: searchValue,
  };
};