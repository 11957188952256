import {    
     FETCH_NOTIFICATIONS_REQUEST,
    FETCH_NOTIFICATIONS_SUCCESS,
    FETCH_NOTIFICATIONS_FAILURE,
    UPDATE_NOTIFICATION_SUCCESS,
    DELETE_NOTIFICATION_SUCCESS,
    MARK_NOTIFICATION_AS_READ
 } from "./action.js/action";


const initialState = {
    loading: false,
    notificationsByCompany: {},
    error: null,
};

const notificationReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_NOTIFICATIONS_REQUEST:
            return { ...state, loading: true, error: null };
        case FETCH_NOTIFICATIONS_SUCCESS:
            return {
                ...state,
                loading: false,
                notificationsByCompany: {
                    ...state.notificationsByCompany,
                    [action.payload.companyID]: action.payload.notifications.map(newNotification => {
                        const existingNotifications = state.notificationsByCompany[action.payload.companyID] || [];
                        const existingNotificationIndex = existingNotifications.findIndex(notification => notification._id === newNotification._id);

                        // Если уведомление уже существует, обновляем его
                        if (existingNotificationIndex !== -1) {
                            return { ...existingNotifications[existingNotificationIndex], ...newNotification };
                        }
                        // Если уведомление не существует, добавляем его
                        return newNotification;
                    }),
                },
            };
        case FETCH_NOTIFICATIONS_FAILURE:
            return { ...state, loading: false, error: action.payload };
        case UPDATE_NOTIFICATION_SUCCESS: {
            const updatedNotification = action.payload;
            const companyID = updatedNotification.companyID;

            return {
                ...state,
                notificationsByCompany: {
                    ...state.notificationsByCompany,
                    [companyID]: state.notificationsByCompany[companyID].map(notification =>
                        notification._id === updatedNotification._id ? { ...notification, ...updatedNotification } : notification
                    ),
                },
            };
        }
        case DELETE_NOTIFICATION_SUCCESS: {
            const notificationId = action.payload;
            const updatedNotifications = Object.keys(state.notificationsByCompany).reduce((acc, companyID) => {
                acc[companyID] = state.notificationsByCompany[companyID].filter(notification => notification._id !== notificationId);
                return acc;
            }, {});

            return {
                ...state,
                notificationsByCompany: updatedNotifications,
            };
        }
        case MARK_NOTIFICATION_AS_READ: {
            const { companyID, notificationId } = action.payload;
            return {
                ...state,
                notificationsByCompany: {
                    ...state.notificationsByCompany,
                    [companyID]: state.notificationsByCompany[companyID].map(notification =>
                        notification._id === notificationId ? { ...notification, isRead: true } : notification
                    ),
                },
            };
        }
        default:
            return state;
    }
};



export default notificationReducer;
