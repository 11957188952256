import { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import "./mydata.css";
import "./mydataDark.css"
import { getmyinfoAction } from "../../../../../redux/reducer/getmyinfo/action/action";
import { getMyImageAction } from "../../../../../redux/reducer/getMyimg/action/action";
import { Avatar, background } from '@chakra-ui/react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  useDisclosure
} from '@chakra-ui/react'
import axios from "axios";
import sockets from "../../../../../socket/socket";
import { useNavigate } from "react-router";
import { useToast } from '@chakra-ui/react'
import { Switch } from '@chakra-ui/react'
import { toggleDarkModeAction } from "../../../../../redux/reducer/toggleDarkMode/action/action";
import { color } from "framer-motion";
import ViaSocialWidget from "../../../../aouth/viaSocialWidget/viaSocialWidget";
import { notification } from "antd";
const isDarkstorage = JSON.parse(localStorage.getItem('isDark'));
const socket = sockets;



export default function Mydata() {
  const getmyinforeducer = useSelector(compID => compID.getMayInfoReducer);
  const [data, setData] = useState([])
  const dispatch = useDispatch()
  const myinfo = useSelector(data => data.getMayInfoReducer.myinfo)
  const myinfos = useSelector(data => data.getMyImageReducer)
  const navigate = useNavigate()
  const modalRef = useRef()
  const [show, setShow] = useState(false);
  const [isSend, setisSend] = useState(false);
  const [loading, setLoading] = useState(false)
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [selectedFile, setSelectedFile] = useState(null);
  const [imgURL, setimgURL] = useState('');
  const [imgUrl, setImgUrl] = useState('');
  const toast = useToast();
  const [isDark, setSsDark] = useState(false)
  const getDarkModoe = useSelector(darkModes => darkModes)
  const dipatch = useDispatch()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const hasShownNotification = useRef(false);
  useEffect(() => {


    if (
      !hasShownNotification.current && // Проверяем, было ли уже показано уведомление
      !getmyinforeducer.myinfo?.email &&
      getmyinforeducer.loading === false &&
      !Array.isArray(getmyinforeducer.myinfo) &&
      getmyinforeducer &&
      getmyinforeducer.myinfo.firstVisit === true
    ) {
      hasShownNotification.current = true; // Запоминаем, что уведомление показано
      notification.error({
        message: "Ошибка",
        description: "У вас не указан email. Пожалуйста, заполните данные!",
        duration: 5,
      });
    }
  }, [getmyinforeducer]);
  useEffect(() => {

    dispatch(getmyinfoAction("/registration/v2/api"))
    dispatch(getMyImageAction("/registration/v2/api"))

  }, [dispatch])
  useEffect(() => {
    const myVisit = localStorage.getItem("myVisit");
    if (myVisit === null) {
      handleShow()
      localStorage.setItem("myVisit", true);
    }
    dispatch(getmyinfoAction("/registration/v2/api"))


  }, [dispatch, isSend])


  // Здесь мы получаем информацию о нас
  const updateData = (e) => {

    setisSend(true)
    e.preventDefault()
    setLoading(true)

    axios.patch('/update/data/v2', {
      data: {
        name: e.target.name.value,
        email: e.target.email.value,
        tel: e.target.tel.value,
        telegram: e.target.telegram.value,
        WhatsApp: e.target.WhatsApp.value,
      }
    })
      .then(function (response) {

        setLoading(false)
        setisSend(false)
        dispatch(getmyinfoAction("/registration/v2/api"))
        dispatch(getMyImageAction("/registration/v2/api"))
        setImgUrl('')
        // update in task my data
        axios.post('/updateMydataInTask/api', {
          myinfo: {
            name: e.target.name.value,
            email: e.target.email.value,
            tel: e.target.tel.value,

          }
        })
          .then(function (response) {
            console.log(response);

            // update sockets
            socket.emit('getallTaskforUpdateServer');
          })
          .catch(function (error) {
            console.log(error);
          });
      })
      .catch(function (error) {
        console.log(error);
        setLoading(false)
        toast({
          title: 'Ошибка',
          description: error.response.data.message,
          status: "error",
          duration: 3000,
          isClosable: true,
          position: "top"
        })
      });




    const formData = new FormData();
    formData.append('image', selectedFile);

    // здесь мы загружаем наше изображение на сервер
    // здесь мы загружаем наше изображение на сервер
    if (selectedFile !== null) {
      if (selectedFile.size <= 5 * 1024 * 1024) {
        try {
          const response = axios.post('/upload/myimage', formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          });
          response.then(data => {
            console.log(data.data.data);
            setimgURL(data.data.data)
            dispatch(getmyinfoAction("/registration/v2/api"))
            dispatch(getMyImageAction("/registration/v2/api"))
          })
        } catch (error) {
          console.error(error);
        }
      } else {
        toast({
          position: "top",
          title: 'Размер изображения не более 5мб, форматы: jpg, png.',
          description: "Размер изображения не более 5мб, форматы: jpg, png.",
          status: 'error',
          duration: 2000,
          isClosable: true,
        })
      }
    }

  }

  const logoutHandle = () => {
    socket.emit("updateForOnlineUsersStatus")
    axios({
      method: 'get',
      url: '/logout/api',
    })
      .then(function (response) {
        socket.emit('logout', getmyinforeducer.myinfo._id)

        sessionStorage.clear()
        navigate("/login")
        socket.emit('updateMydataOnlineTrueForServer');
        // window.location.replace("/login")

      });
    // return redirect("/login");
  }

  const handleUploadIMNG = (e) => {
    setSelectedFile([]);
    setSelectedFile(e.target.files[0]);
    setImgUrl(URL.createObjectURL(e.target.files[0]))


  }

  const handleDrop = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0]; // Получаем первый файл из перетаскиваемых
    if (file && file.type.startsWith('image/')) {
      setSelectedFile(file);
      setImgUrl(URL.createObjectURL(file));
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault(); // Предотвращаем стандартное поведение для работы drag and drop
  };

  useEffect(() => {
    if (isDarkstorage == null || isDarkstorage == false) {
      setSsDark(false)
      dipatch(toggleDarkModeAction(false))
    } else {
      setSsDark(true)
      dipatch(toggleDarkModeAction(true))
    }

  }, [])
  const handleChangetema = () => {
    setSsDark(!isDark)
    localStorage.setItem('isDark', JSON.stringify(!isDark)); // or false
    dipatch(toggleDarkModeAction(!isDark))

  }






  if (myinfo) {
    return (
      <>


        <form onSubmit={updateData} className={getDarkModoe.ToggleDarkModeReducer.chekced ? "mydataContDark" : "mydataCont"}>
          {/* <EditMyProfile /> */}
          <div>
            <div>
              <div
                onDrop={handleDrop}
                onDragOver={handleDragOver}
              >
                {(() => {
                  if (imgUrl) {
                    return <Avatar size='xl' src={imgUrl} alt="" className='urlimg' />
                  }
                  else if (myinfos.users.myavatar) {
                    return <Avatar size='xl' src={myinfos.users.myavatar} alt="" className='urlimg' />
                  } else {
                    return <Avatar size='xl' src={"https://bit.ly/broken-link"} alt="" className='urlimg' />
                  }
                })()}
                <label
                  htmlFor="upload"
                >
                  <input
                    type="file"
                    id="upload"
                    onChange={handleUploadIMNG}
                    accept="image/*"
                    style={{ display: 'none' }}
                  />
                  <p>Загрузить фото</p>
                  <div>
                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                      <path d="M13.4118 7.00266V14.9947C13.4118 15.4987 13.0038 15.9067 12.4998 15.9067C11.9958 15.9067 11.6118 15.4987 11.6118 14.9947V7.00266L8.58781 9.66666C8.22781 10.0027 7.65181 9.97866 7.33981 9.59466C7.00381 9.23466 7.02781 8.65866 7.41181 8.32266L11.8998 4.33865C12.2358 4.02665 12.7398 4.02665 13.0998 4.31465C13.0998 4.33865 13.0998 4.33865 13.0998 4.33865L17.5878 8.32266C17.9718 8.65866 17.9958 9.23466 17.6838 9.59466C17.3478 9.97866 16.7718 10.0027 16.4118 9.66666L13.4118 7.00266ZM18.6918 17.3947C18.6918 16.8907 19.0998 16.5067 19.6038 16.5067C20.1078 16.5067 20.4918 16.8907 20.4918 17.3947V19.6027C20.4918 20.0827 20.1078 20.4907 19.6038 20.4907H5.39581C4.91581 20.4907 4.50781 20.0827 4.50781 19.6027V17.3947C4.50781 16.8907 4.91581 16.5067 5.39581 16.5067C5.89981 16.5067 6.30781 16.8907 6.30781 17.3947V18.6907H18.6918V17.3947Z" fill="#1B2B41" fill-opacity="0.69" />
                      <path d="M13.7438 6.62879L12.9118 5.88997V7.00266V14.9947C12.9118 15.2225 12.7277 15.4067 12.4998 15.4067C12.2893 15.4067 12.1118 15.2401 12.1118 14.9947V7.00266V5.89583L11.2813 6.62748L8.2573 9.29148L8.25191 9.29623L8.24665 9.30113C8.08674 9.45038 7.84733 9.42639 7.72787 9.27936L7.71705 9.26605L7.70534 9.2535C7.56295 9.10094 7.56862 8.84984 7.74107 8.69895L7.74107 8.69896L7.74375 8.69658L12.2317 4.71258L12.2318 4.71267L12.24 4.70505C12.3561 4.59726 12.5307 4.56793 12.6909 4.64423L12.7679 4.71258L17.2559 8.69658L17.2559 8.69659L17.2586 8.69895C17.4322 8.85089 17.4402 9.11126 17.3067 9.2663C17.1558 9.43787 16.9053 9.44327 16.753 9.30113L16.7531 9.30102L16.7438 9.29279L13.7438 6.62879ZM5.80781 18.6907V19.1907H6.30781H18.6918H19.1918V18.6907V17.3947C19.1918 17.1841 19.3583 17.0067 19.6038 17.0067C19.8317 17.0067 19.9918 17.1668 19.9918 17.3947V19.6027C19.9918 19.8154 19.8229 19.9907 19.6038 19.9907H5.39581C5.19196 19.9907 5.00781 19.8065 5.00781 19.6027V17.3947C5.00781 17.1756 5.1831 17.0067 5.39581 17.0067C5.64129 17.0067 5.80781 17.1841 5.80781 17.3947V18.6907Z" stroke="#1C375A" stroke-opacity="0.16" />
                    </svg>
                    <p>перетащите фото сюда</p>
                  </div>
                </label>
              </div>
              <div>
                <label htmlFor="Имя">
                  Полное имя
                  <input type="text" defaultValue={myinfos.users.data && myinfos.users.data.name} id='Имя' name='name' placeholder="Полное имя" />
                </label>
                <label htmlFor="tel">
                  Номер телефона
                  <div>
                    <div>
                      <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M6.61183 0.97505C5.55974 0.00831676 3.94259 0.00831676 2.8905 0.97505C2.84741 1.01464 2.80142 1.06065 2.74169 1.12039L1.84419 2.01788C0.941081 2.921 0.561962 4.22292 0.839024 5.4697C2.41099 12.5436 7.93553 18.0681 15.0094 19.6401C16.2562 19.9171 17.5581 19.538 18.4612 18.6349L19.3586 17.7375C19.4184 17.6777 19.4644 17.6317 19.504 17.5886C20.4708 16.5365 20.4708 14.9193 19.504 13.8673C19.4644 13.8241 19.4184 13.7781 19.3586 13.7184L17.8894 12.2491C16.8693 11.229 15.3287 10.9372 14.0063 11.5137C13.2492 11.8437 12.3672 11.6767 11.7832 11.0927L9.38639 8.69589C8.80239 8.11188 8.63536 7.22994 8.96538 6.47283C9.54184 5.15037 9.25009 3.60984 8.22999 2.58974L6.76066 1.12041C6.70093 1.06066 6.65492 1.01464 6.61183 0.97505ZM3.90541 2.07957C4.38363 1.64014 5.1187 1.64014 5.59692 2.07957C5.61343 2.09474 5.63507 2.11613 5.71114 2.1922L7.16933 3.6504C7.75333 4.2344 7.92036 5.11635 7.59034 5.87345C7.01388 7.19592 7.30563 8.73644 8.32573 9.75655L10.7225 12.1534C11.7426 13.1735 13.2832 13.4652 14.6056 12.8887C15.3627 12.5587 16.2447 12.7258 16.8287 13.3098L18.2869 14.768C18.3629 14.844 18.3843 14.8657 18.3995 14.8822C18.8389 15.3604 18.8389 16.0955 18.3995 16.5737C18.3843 16.5902 18.3629 16.6118 18.2869 16.6879L17.4005 17.5742C16.8601 18.1147 16.0809 18.3416 15.3348 18.1758C8.82946 16.7302 3.74893 11.6496 2.3033 5.1443C2.1375 4.39816 2.36438 3.61902 2.90485 3.07855L3.7912 2.1922C3.86726 2.11614 3.8889 2.09474 3.90541 2.07957Z" fill="#353535" />
                      </svg>
                    </div>
                    <input type="number" defaultValue={myinfos.users.data && myinfos.users.data.tel} placeholder="Номер телефона" id='tel' name='tel' required />
                  </div>
                </label>
              </div>
              <div>
                <label htmlFor="email">
                  Почта
                  <input type="email" defaultValue={myinfos.users.data && myinfos.users.data.email} placeholder="электронная почта" id='email' name='email' required />
                </label>

              </div>
              <div>
                <label htmlFor="telegram">
                  Телеграмм
                  <input type="text" defaultValue={myinfos.users.data && myinfos.users.data.telegram} placeholder="@uprosti.rf" id='telegram' name='telegram' />
                </label>

              </div>
              <div>
                <label htmlFor="WhatsApp">
                  WhatsApp
                  <input type="text" defaultValue={myinfos.users.data && myinfos.users.data.whatsUp} placeholder="+79000000000" id='WhatsApp' name='WhatsApp' />
                </label>

              </div>
              {
                loading ?
                  <button disabled>
                  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                    <path d="M5.5 13L9.5 17L19.5 7" stroke="#FAFAFA" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                  Загрузка...
                </button>
                  :
                  <button>
                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                      <path d="M5.5 13L9.5 17L19.5 7" stroke="#FAFAFA" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                    Сохранить
                  </button>
              }

            </div>
            <div>
              <div>
                <div>
                  <div>
                    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
                      <g clip-path="url(#clip0_1740_33173)">
                        <path d="M3.5 9.56142C3.5 14.222 7.27808 18 11.9386 18C15.2519 18 18.1192 16.0905 19.5 13.3119C11.9386 13.3119 8.1881 9.56142 8.1881 2C5.40952 3.38084 3.5 6.24813 3.5 9.56142Z" stroke="#353535" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                      </g>
                      <defs>
                        <clipPath id="clip0_1740_33173">
                          <rect width="20" height="20" fill="white" transform="translate(0.5)" />
                        </clipPath>
                      </defs>
                    </svg>
                    <p>Темная тема</p>
                  </div>
                  <Switch id='email-alerts' isChecked={getDarkModoe.ToggleDarkModeReducer.chekced} onChange={handleChangetema} />
                </div>
              </div>

              <div>
                <div>
                  <div onClick={onOpen}>
                    <div >
                      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="14" viewBox="0 0 20 14" fill="none">
                        <path d="M14.1417 6.99777L11.7846 4.64062M5.57031 6.99777H14.1417H5.57031ZM14.1417 6.99777L11.7846 9.35491L14.1417 6.99777Z" stroke="#DD5E98" stroke-width="1.28571" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M1.5 9.57143V4.42857C1.5 2.53502 3.03502 1 4.92857 1H15.2143C17.1078 1 18.6429 2.53502 18.6429 4.42857V9.57143C18.6429 11.4649 17.1078 13 15.2143 13H4.92857C3.03502 13 1.5 11.4649 1.5 9.57143Z" stroke="#DD5E98" stroke-width="1.28571" />
                      </svg>
                    </div>
                    <p className="outhP">Выйти</p>
                    <Modal isOpen={isOpen} onClose={onClose} size={"xl"}>
                      <ModalOverlay />
                      <ModalContent className={getDarkModoe.ToggleDarkModeReducer.chekced ? "logoutConfirmConteDark" : "logoutConfirmConteLight"}>
                        <ModalHeader>Выйти</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody>
                          <p>Вы уверены, что хотите выйти из аккаунта?</p>
                        </ModalBody>
                        <ModalFooter>
                          <Button onClick={onClose}>Отменить</Button>
                          <Button onClick={logoutHandle}>Выйти</Button>
                        </ModalFooter>
                      </ModalContent>
                    </Modal>
                  </div>
                </div>
              </div>
              <ViaSocialWidget />
            </div>
          </div>
        </form>
        <button onClick={logoutHandle}>Выйти</button>
      </>

    );
  } else {
    <h1>OOps</h1>
  }

}
