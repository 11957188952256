import React, { useRef, useEffect, useState } from "react";
import { Button, Modal, ConfigProvider, theme } from 'antd';

import "./instructionLight.css";
import "./instructionDark.css";
import ContenInstruct from "./instructionCont";

import ru from 'antd/locale/ru_RU';
import { useSelector } from "react-redux";
const { darkAlgorithm } = theme;

const InstructionModal = () => {


    // Ссылка для целевого элемента тура (кнопки)
    const questionMarkRef = useRef(null);
    const modalBodyRef = useRef(null);
    const getDarkModoe = useSelector(darkModes => darkModes)
    const [isModalOpen, setIsModalOpen] = useState(false);


    // Если initialFocusRef не срабатывает, добавляем небольшой setTimeout для скролла
    // useEffect(() => {
    //     if (isOpen && modalBodyRef.current) {
    //         setTimeout(() => {
    //             modalBodyRef.current.scrollTop = 0;
    //         }, 0);
    //     }
    // }, [isOpen]);


    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    return (
        <>
            <div className="questionMark" onClick={showModal} style={{ cursor: "pointer" }}>
                <svg
                    ref={questionMarkRef}
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                >
                    <path
                        d="M7.90039 8.07955C7.90039 3.30679 15.4004 3.30683 15.4004 8.07956C15.4004 11.4886 11.9913 10.8067 11.9913 14.8976"
                        stroke="#353535"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        d="M12 19.0101L12.01 18.999"
                        stroke="#353535"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </svg>
            </div>
            <ConfigProvider
            theme={{
                algorithm: darkAlgorithm,
                token: {
                  colorBgBase:getDarkModoe.ToggleDarkModeReducer.chekced ?"#292F39": 'white',      // базовый фон
                  colorBgContainer: getDarkModoe.ToggleDarkModeReducer.chekced ?"#292F39": 'white', // фон контейнеров
                  colorTextBase: getDarkModoe.ToggleDarkModeReducer.chekced ?"#292F39": 'white',       // основной цвет текста
                },
              }}
            >
            <Modal
                title="Инстуркция"
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
                footer={null}
                width="100vw" // задаём ширину во весь экран
                style={{ top: 0, padding: '10px',backgroundColorL:"red" }}
                bodyStyle={{
                    boxSizing: 'border-box',
                    
                    
                }}
            >
                <ContenInstruct isOpens={isModalOpen} />
            </Modal>
            </ConfigProvider>


        </>
    );
};

export default InstructionModal;
