import { Outlet, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";

const ProtectedRoutes = () => {
  const getmyinfo = useSelector((state) => state.getMayInfoReducer);
  const navigate = useNavigate();
  const [shouldRender, setShouldRender] = useState(true);
  

   

  useEffect(() => {
    if (!getmyinfo.myinfo?.email && getmyinfo.loading === false && !Array.isArray(getmyinfo.myinfo) && getmyinfo) {
      console.log("Пользователь не указал email. Перенаправляем на /mydata");
      
      navigate("/mydata", { replace: true });
     
    } 
  }, [getmyinfo, navigate]);

  return <Outlet  />
};

export default ProtectedRoutes;
