// actions.js
import axios from 'axios';

export const getAllTask = (url) => {
  return (dispatch) => {

    dispatch({ type: 'FETCH_GETALLTASK_REQUEST' });
    axios.get(url)
      .then(response => {
        const { sortedTasks, currentPage, totalPages, itemsPerPage } = response.data;


        dispatch({
          type: 'FETCH_GETALLTASK_SUCCESS',
          payload: {
            users: response.data.data,         // Массив данных
            currentPage,   // Текущая страница
            totalPages,    // Общее количество страниц
            itemsPerPage   // Количество элементов на странице
          }
        });
      })
      .catch(error => {
        dispatch({
          type: 'FETCH_GETALLTASK_FAILURE',
          payload: error.message
        });
      });
  };
};


export const setPageAction = (currentPage) => ({
  type: 'SET_PAGE_TABLE',
  payload: { currentPage }
});


export const filterBydate = () => ({
  type: 'SORT_BY_DATE',
});


export const filterByprioritet = () => ({
  type: 'SORT_BY_PRIORITET',
});


export const filterBypstatus = () => ({
  type: 'SORT_BY_STATUS',
});

export const filterByprice = () => ({
  type: 'SORT_BY_PRICE',
});