

import {
  Box,
  Flex,
  Avatar,
  HStack,
  Text,
  IconButton,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
  useDisclosure,
  useColorModeValue,
  Stack,
  useBreakpointValue,
  Center
} from '@chakra-ui/react'
import { HamburgerIcon, CloseIcon, AddIcon } from '@chakra-ui/icons'
import { useEffect, useState } from 'react'
import axios from 'axios'
import { Outlet, useNavigate, Navigate } from 'react-router'
import logosvg from "../../../../img/LogoU.png"
import Deldb from '../deldb/deldb'


const Links = [{ nameLink: 'Компании', link: "allcompany" }, { nameLink: 'Пользователи', link: "allusers" }, { nameLink: 'Добавить администратора', link: "alladmin" },{ nameLink: 'Промо-код', link: "promocode" },{ nameLink: 'Платежная панель', link: "paymentList" }]

const NavLink = ({ children, myLink }) => {
  const navigate = useNavigate();
  return (
    <Box
      cursor={"pointer"}
      as="a"
      px={2}
      py={1}
      rounded={'md'}
      _hover={{
        textDecoration: 'none',
        bg: useColorModeValue('gray.200', 'gray.700'),
      }}
      onClick={() => navigate(myLink)}>
      {children}
    </Box>
  )
}




export default function AdmiNavigation() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const navigate = useNavigate();
  const [admingInfo, setAdminInfo] = useState({});


  useEffect(() => {
    axios
      .get("/getadmininfo/admin/api")
      .then((response) => {
        setAdminInfo(response.data.data);
      })
      .catch((error) => {
        navigate("/admin");
        console.error(error);
      });
  }, []);

  const logoutAdmin = () => {
    axios
      .get("/logoutAdmin/api")
      .then(() => {
        sessionStorage.clear();
        window.location.replace("/admin");
      })
      .catch((error) => {
        console.error(error);
      });
  };


  return (
    <>
      <Box bg={useColorModeValue('gray.100', 'gray.900')} px={4}>
        <Flex h={16} alignItems={'center'} justifyContent={'space-between'}>
          <IconButton
            size={'md'}
            icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
            aria-label={'Open Menu'}
            display={{ md: 'none' }}
            onClick={isOpen ? onClose : onOpen}
          />
          <HStack spacing={8} alignItems={'center'}>
            <Text
              textAlign={useBreakpointValue({ base: 'center', md: 'left' })}
              fontFamily={'heading'}
              color={useColorModeValue('gray.800', 'white')}>
              <Avatar onClick={() => navigate("/adminPage")}
                size={'sm'}
                src={
                  logosvg
                }
              />
            </Text>
            <HStack as={'nav'} spacing={4} display={{ base: 'none', md: 'flex' }}>
              {Links.map((link, index) => (
                <NavLink key={index} myLink={link.link}>{link.nameLink}</NavLink>
              ))}

            </HStack>
          </HStack>
          <Flex alignItems={'center'}>
            <Button
              variant={'solid'}
              colorScheme={'teal'}
              size={'sm'}
              mr={4}
              leftIcon={<AddIcon />}>
              Action
            </Button>
            <Menu>
              <MenuButton
                as={Button}
                rounded={'full'}
                variant={'link'}
                cursor={'pointer'}
                minW={0}>
                <Avatar
                  size={'sm'}
                  src={
                    `/${admingInfo.img ? admingInfo.img : "https://bit.ly/broken-link"}`
                  }
                />
              </MenuButton>
              <MenuList alignItems={'center'}>
                <br />
                <Center>
                  <Avatar
                    size={'2xl'}
                    src={`/${admingInfo.img ? admingInfo.img : "https://bit.ly/broken-link"}`}
                  />
                </Center>
                <br />
                <Center>
                  <p>{admingInfo.tel}</p>
                </Center>
                <br />
                <MenuDivider />
                <MenuItem>Your Servers</MenuItem>
                <MenuItem onClick={() => navigate("myPageAdmin")}>Настройки учетной записи</MenuItem>
                <MenuItem> <Deldb /></MenuItem>

                <MenuItem onClick={logoutAdmin}>Выйти</MenuItem>
              </MenuList>
            </Menu>
          </Flex>
        </Flex>

        {isOpen ? (
          <Box pb={4} display={{ md: 'none' }}>
            <Stack as={'nav'} spacing={4}>
              {Links.map((link, index) => (
                <NavLink key={index} myLink={link.link}>{link.nameLink}</NavLink>
              ))}
            </Stack>
          </Box>
        ) : null}
      </Box>

      <Box p={4}>
        <Outlet />
      </Box>
    </>
  )
}