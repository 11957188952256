// CsrfProvider.jsx
import { useEffect } from "react";
import axios from "axios";

export default function CsrfProvider() {
  useEffect(() => {
    const fetchToken = async () => {
      try {
        const res = await axios.get("/api/csrf-token");
        axios.defaults.headers.common["x-csrf-token"] = res.data.csrfToken;
        console.log("✅ CSRF token получен:", res.data.csrfToken);
      } catch (err) {
        console.error("❌ Ошибка загрузки CSRF токена:", err);
      }
    };

    fetchToken();
  }, []);

  return null;
}
