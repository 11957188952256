// DashboardPayment.jsx
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  Box,
  Grid,
  Heading,
  Text,
  Spinner,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Button,
  Input,
  Flex,
  Container,
  Divider,
  Select,
  useColorModeValue,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
} from '@chakra-ui/react';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const DashboardPayment = () => {
  // Цветовые переменные (светлая тема по умолчанию)
  const cardBg = useColorModeValue('white', 'gray.700');
  const cardText = useColorModeValue('gray.800', 'white');
  const inputBg = useColorModeValue('white', 'gray.700');

  // Состояния компонента с дефолтными значениями
  const [summary, setSummary] = useState({ totalRevenue: 0, totalTransactions: 0, averagePayment: 0, maxPayment: 0, minPayment: 0 });
  const [chartData, setChartData] = useState({ labels: [], datasets: [] });
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [dateFilter, setDateFilter] = useState({ start: '', end: '' });
  const [granularity, setGranularity] = useState('month'); // варианты: 'day', 'month', 'year'
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const pageSize = 10; // количество записей на страницу

  // Получаем сводные данные
  useEffect(() => {
    setLoading(true);
    axios.get('/reports/summary')
      .then(response => setSummary(response.data))
      .catch(error => console.error("Ошибка загрузки сводки:", error))
      .finally(() => setLoading(false));
  }, []);

  // Получаем данные для графика с учетом выбранной детализации
  useEffect(() => {
    setLoading(true);
    axios.get(`/api/reports/chart?granularity=${granularity}`)
      .then(response => {
        const data = response.data;
        const labels = data?.map(item => item._id) || [];
        const revenue = data?.map(item => item.totalRevenue) || [];
        setChartData({
          labels,
          datasets: [{
            label: `Выручка (₽) (${granularity === 'day' ? 'День' : granularity === 'year' ? 'Год' : 'Месяц'})`,
            data: revenue,
            borderColor: 'blue',
            backgroundColor: 'rgba(0, 123, 255, 0.5)',
            fill: true,
          }],
        });
      })
      .catch(error => console.error("Ошибка загрузки графика:", error))
      .finally(() => setLoading(false));
  }, [granularity]);

  // Функция для получения детальных транзакций с серверной пагинацией
  const fetchTransactions = (page = 1) => {
    setLoading(true);
    axios.get('/api/reports/detailed', {
      params: {
        startDate: dateFilter.start,
        endDate: dateFilter.end,
        page,
        limit: pageSize,
      },
    })
      .then(response => {
        const { transactions, totalPages, currentPage } = response.data;
        setTransactions(transactions);
        setTotalPages(totalPages);
        setCurrentPage(currentPage);
      })
      .catch(error => console.error("Ошибка загрузки транзакций:", error))
      .finally(() => setLoading(false));
  };

  // Запрос транзакций при изменении фильтра (сброс на первую страницу)
  useEffect(() => {
    fetchTransactions(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateFilter]);

  const handleFilterSubmit = (e) => {
    e.preventDefault(); // предотвращаем перезагрузку страницы
    fetchTransactions(1);
  };

  // Обработчик переключения страниц
  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      fetchTransactions(newPage);
    }
  };

  // Вычисляем максимальную сумму платежа (для выделения ячейки)
  const maxAmountValue = transactions.reduce((max, tx) => {
    const amount = tx.amount || 0;
    return amount > max ? amount : max;
  }, 0);

  return (
    <Container maxW="container.xl" py={6} position="relative">
      <Heading mb={8} textAlign="center">Финансовый отчет (в рублях)</Heading>

      {/* KPI карточки */}
      <Grid templateColumns={{ base: "1fr", md: "repeat(4, 1fr)" }} gap={6} mb={8}>
        <Box p={6} bg={cardBg} boxShadow="lg" borderRadius="md">
          <Text fontSize="lg" color="gray.500">Общая выручка</Text>
          <Heading size="lg" mt={2} color={cardText}>₽{ (summary.totalRevenue || 0).toFixed(2) }</Heading>
        </Box>
        <Box p={6} bg={cardBg} boxShadow="lg" borderRadius="md">
          <Text fontSize="lg" color="gray.500">Всего транзакций</Text>
          <Heading size="lg" mt={2} color={cardText}>{ summary.totalTransactions || 0 }</Heading>
        </Box>
        <Box p={6} bg={cardBg} boxShadow="lg" borderRadius="md">
          <Text fontSize="lg" color="gray.500">Средний чек</Text>
          <Heading size="lg" mt={2} color={cardText}>₽{ (summary.averagePayment || 0).toFixed(2) }</Heading>
        </Box>
        <Box p={6} bg={cardBg} boxShadow="lg" borderRadius="md">
          <Text fontSize="lg" color="gray.500">Максимальный платеж</Text>
          <Heading size="lg" mt={2} color={cardText}>₽{ (summary.maxPayment || 0).toFixed(2) }</Heading>
        </Box>
      </Grid>

      <Divider mb={8} />

      {/* Элемент управления для выбора детализации графика */}
      <Box mb={6}>
        <Flex alignItems="center" gap={4}>
          <Text fontSize="md">Детализация графика:</Text>
          <Select value={granularity} onChange={(e) => setGranularity(e.target.value)} maxWidth="200px" bg={inputBg}>
            <option value="day">День</option>
            <option value="month">Месяц</option>
            <option value="year">Год</option>
          </Select>
        </Flex>
      </Box>

      {/* График выручки */}
      <Box mb={10}>
        <Heading size="md" mb={4}>Динамика выручки</Heading>
        <Box p={4} bg={cardBg} boxShadow="md" borderRadius="md">
          <Line data={chartData} />
        </Box>
      </Box>

      <Divider mb={8} />

      {/* Форма фильтра транзакций */}
      <Box mb={8}>
        <Heading size="md" mb={4}>Фильтр транзакций</Heading>
        <Flex as="form" onSubmit={handleFilterSubmit} alignItems="center" gap={4} flexWrap="wrap">
          <Input type="date" placeholder="Начало" value={dateFilter.start} onChange={(e) => setDateFilter({ ...dateFilter, start: e.target.value })} maxWidth="200px" bg={inputBg} />
          <Input type="date" placeholder="Конец" value={dateFilter.end} onChange={(e) => setDateFilter({ ...dateFilter, end: e.target.value })} maxWidth="200px" bg={inputBg} />
          <Button type="submit" colorScheme="blue" mt={{ base: 4, md: 0 }}>Применить</Button>
        </Flex>
      </Box>

      {/* Таблица транзакций с серверной пагинацией */}
      <Box overflowX="auto" bg={cardBg} p={4} boxShadow="md" borderRadius="md">
        <Table variant="striped" colorScheme="gray">
          <Thead>
            <Tr>
              <Th>Инвойс</Th>
              <Th>КОМПАНИЯ</Th>
              <Th>Сумма (₽)</Th>
              <Th>Дата платежа</Th>
            </Tr>
          </Thead>
          <Tbody>
            {transactions?.map(tx => (
              <Tr key={tx._id}>
                <Td>{tx.invoiceID}</Td>
                <Td>{tx.username}</Td>
                <Td fontWeight={(tx.amount || 0) === maxAmountValue ? "bold" : "normal"}
                    textDecoration={(tx.amount || 0) === maxAmountValue ? "underline" : "none"}>
                  ₽{ (tx.amount || 0).toFixed(2) }
                </Td>
                <Td>{new Date(tx.createdAt).toLocaleString()}</Td>
              </Tr>
            ))}
          </Tbody>
        </Table>

        {/* Пагинация */}
        <Flex justifyContent="space-between" alignItems="center" mt={4} flexWrap="wrap">
          <Button onClick={() => handlePageChange(currentPage - 1)} isDisabled={currentPage === 1} colorScheme="blue" mr={2}>
            Предыдущая
          </Button>
          <Text>Страница {currentPage} из {totalPages}</Text>
          <Button onClick={() => handlePageChange(currentPage + 1)} isDisabled={currentPage === totalPages} colorScheme="blue" ml={2}>
            Следующая
          </Button>
        </Flex>
      </Box>

      {/* Модальное окно с индикатором загрузки */}
      <Modal isOpen={loading} onClose={() => {}} isCentered closeOnOverlayClick={false} motionPreset="scale">
        <ModalOverlay />
        <ModalContent bg="transparent" boxShadow="none" maxW="100px">
          <ModalBody display="flex" justifyContent="center" alignItems="center" p={0}>
            <Spinner size="xl" color="blue.500" />
          </ModalBody>
        </ModalContent>
      </Modal>
    </Container>
  );
};

export default DashboardPayment;
