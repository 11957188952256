import axios from "axios";
import sockets from "../../../../socket/socket"; // Убедитесь, что этот путь правильный

export const getOzonPorductList = (url, page, searchVal) => {

  return (dispatch) => {
    dispatch({ type: "FETCH_OZON_REQUEST" });

    // Получаем данные через Axios
    return axios // Вернем Promise от axios
      .get(`${url}?page=${page}&searchVal=${searchVal}`)
      .then((response) => {
        dispatch({
          type: "FETCH_OZON_SUCCESS",
          payload: {
            data: response.data.data, // Предполагается, что в ответе есть поле products
            weeks: response.data.weeks, // Предполагается, что в ответе есть поле weeks
          },
        });
      })
      .catch((error) => {
        dispatch({
          type: "FETCH_OZON_FAILURE",
          payload: error.message,
        });
        throw error; // Пробрасываем ошибку для обработки в loadMoreData
      });
  };
};


export function handleCommentUpdate(id, value, date, commentType) {
  return (dispatch) => {
    dispatch({
      type: "UPDATE_OZON_COMMENT",
      payload: {
        id,
        value, // Отправляем значение комментария
        date,  // Отправляем дату
        commentType, // Передаем тип комментария
      },
    });
  };
}


export function reorderOzonProducts(newOrder) {
  return (dispatch) => {
    dispatch({
      type: "REORDER_OZON_PRODUCTS",
      payload: newOrder,
    });
  };
}

export const reorderOzonColumns = (columns) => ({
  type: "REORDER_OZON_COLUMNS",
  payload: columns,
});


export const handleClickRow = (val) => ({
  type: "CLICK_ROWS",
  payload: val,
});


export const updateCurrentPage = (page) => ({
  type: "UPDATE_CURRENT_PAGE",
  payload: page,
});



export const updateCompany = (companyID) => ({
  type: "SET_CURRENT_COMPANY",
  payload: companyID,
});



export const sortBYdate = (weekName) => ({
  type: "SORT_WEEKS_BY_DATE",
  payload: weekName,
});





export const sortWeeksByDateUpdate = () => {
  return {
    type: "SORT_WEEKS_BY_DATE_UPDATE",
  };
};


export const sortPriceOzon = () => {
  return {
    type: "SORT_PRICE_OZON",
  };
};



export const sortStockozon = () => {
  return {
    type: "SORT_STOCK_OZON",
  };
};


export const sortArcticule = () => {
  return {
    type: "SORT_ACTICULE_OZON",
  };
};



export const sortWeek8days = (weekName) => {
  return {
    type: "SORT_LASTWEEKDAY8_OZON",
    payload: weekName,
  };
};



export const sortAverageOrders = (weekName) => {
  return {
    type: "SORT_AVERAGEORDERS_OZON",
    payload: weekName,
  };
};

export const sortDaysOfStock = (weekName) => {
  return {
    type: "SORT_DAYS_STOCK_OZON",
    payload: weekName,
  };
};

export const sortTurnover = (weekName) => {
  return {
    type: "SORT_TURNOVER_OZON",
    payload: weekName,
  };
};



export const setValueOzon = (searchValue) => {


  return {
    type: "SET_SEARCH_VALUE",
    payload: searchValue,
  };
};

