import moment from "moment";
import { diff } from 'deep-diff';

const weekMapping = {
  id1: "week1",
  id2: "week2",
  id3: "week3",
  id4: "week4",
  id5: "week5",
  id6: "week6",
  id7: "week7",
  id8: "week8",
};


const week8Days = {
  id1id: "day1",
  id2id: "day2",
  id3id: "day3",
  id4id: "day4",
  id5id: "day5",
  id6id: "day6",
  id7id: "day7",

};

const getWBState = {
  wb: {
    data: [],
    weeks: [],
    columns: [],
    isClick: false,
    hasMore: true,
    currentPage: 1,
    totalItems: 0,
    currentCompanyId: null, // Добавлено новое состояние для текущей компании
    sortByWeek: null,
    sortByWeekLastDay8: null,
    isAscending: false,
    sortPrice: false,
    sortStock: false,
    sorTarticle: false,
    averageOrdersBool: false,
    daysOfStockBool: false,
    turnoverVersion2Bool: false,
    sortedProducts: [],
    searchValue: "", // Хранит текст поиска
    isSearching: false, // Флаг активности поиска
  },
  loading: false,
  error: null,
};

// Функция для обновления вложенных значений
const getWaldberisListReducer = (state = getWBState, action) => {
  switch (action.type) {
    case "FETCH_WB_REQUEST":
      return {
        ...state,
        loading: true,
        error: null,
      };

    case "FETCH_WB_SUCCESS":
      const newData = action.payload.data || []; // Fallback to an empty array if undefined
      const currentData = state.wb.data || []; // Fallback to an empty array if undefined


      // Проверка на наличие данных
      if (newData.length === 0 && state.wb.currentPage === 1 && state.wb.searchValue  === "") {
        return {
          ...state,
          loading: false,
          wb: {
            ...getWBState.wb, // Сброс всех данных
            data: [], // Устанавливаем пустой массив
            currentPage: 1,
          },
        };
      }

      // Получаем различия между текущими и новыми данными
      const differences = diff(currentData, newData);
      const filteredData = state.wb.currentCompanyId
        ? newData.filter(item => item.companiID === state.wb.currentCompanyId) // Фильтрация по текущей компании
        : newData;

      // Обновляем данные, добавляя новые элементы или заменяя существующие
      const updatedData = [...currentData];
      filteredData.forEach((newItem) => {
        const existingIndex = updatedData.findIndex((item) => item.id === newItem.id);
        if (existingIndex > -1) {
          updatedData[existingIndex] = newItem; // Замена целого элемента
        } else {
          updatedData.push(newItem); // Если элемента нет, добавляем его
        }
      });

      // Удаляем дубликаты
      let uniqueData = updatedData.filter((item, index, self) =>
        index === self.findIndex((t) => t.id === item.id)
      );


        // Если включена сортировка по цене, применяем сортировку
        if (state.wb.sortPrice) {
          uniqueData = uniqueData.sort((a, b) => {
            return state.wb.isAscending ? (b.price.sizes[0].price || 0) - (a.price.sizes[0].price || 0) : (a.price.sizes[0].price || 0) - (b.price.sizes[0].price || 0);
          });
        }
        // сортировка для остатка товара
        if (state.wb.sortStock) {
          uniqueData = uniqueData.sort((a, b) => {
            return state.wb.isAscending ? (b.stock || 0) - (a.stock || 0) : (a.stock || 0) - (b.stock || 0);
          });
        }
  
        // сортировка для остатка товара
        if (state.wb.sorTarticle) {
          uniqueData = uniqueData.sort((a, b) => {
            return state.wb.isAscending ? (b.nmID || 0) - (a.nmID || 0) : (a.nmID || 0) - (b.nmID || 0);
          });
        }
  
        // сортировка для остатка товара
        if (state.wb.sortByWeekLastDay8) {
  
          uniqueData = uniqueData.sort((a, b) => {
            const aTotal = a.weeks.week8?.[state.wb.sortByWeekLastDay8]?.total ?? 0; // Безопасный доступ с использованием nullish coalescing
            const bTotal = b.weeks.week8?.[state.wb.sortByWeekLastDay8]?.total ?? 0; // Безопасный доступ с использованием nullish coalescing
  
            return state.wb.isAscending ? bTotal - aTotal : aTotal - bTotal; // Сравнение и сортировка
          });
        }
  
  
        // сортировка среднее количество заказов
        if (state.wb.averageOrdersBool) {
          uniqueData = uniqueData.sort((a, b) => {
            return state.wb.isAscending
              ? (b.averageOrders || 0) - (a.averageOrders || 0)
              : (a.averageOrders || 0) - (b.averageOrders || 0);
          });
        }
        // сортировка Остатка хватит на 
        if (state.wb.daysOfStockBool) {
          uniqueData = uniqueData.sort((a, b) => {
            return state.wb.isAscending
              ? (b.daysOfStock || 0) - (a.daysOfStock || 0)
              : (a.daysOfStock || 0) - (b.daysOfStock || 0);
          });
        }
        // сортировка Оборачиваемость 
        if (state.wb.turnoverVersion2Bool) {
          uniqueData = uniqueData.sort((a, b) => {
            return state.wb.isAscending
              ? (b.turnover || 0) - (a.turnover || 0)
              : (a.turnover || 0) - (b.turnover || 0);
          });
        }

      // Обновление недель
      const weeks = Array.isArray(action.payload.weeks) ? action.payload.weeks : []; // Fallback to an empty array if undefined
      const updatedWeeks = weeks?.map((week, index) => ({
        ...week,
        id: `id${index + 1}`,
        days: Object.entries(week.days).map(([, day], dayIndex) => ({
          ...day,
          id: `id${dayIndex + 1}id`,
        })),
      }));

      // Генерация столбцов
      const transformedColumns = [
        { id: "name", label: "Артикул продавца" },
        { id: "price", label: "Цена" },
        { id: "stock", label: "Остаток" },
        ...updatedWeeks.map((week, index) => ({
          id: week.id || index,
          label: (
            <>
              {moment(week.startDate).format("L")}
              <br />
              {moment(week.endDate).format("L")}
            </>
          ),
        })),
      ];

      const days = updatedWeeks[7]?.days || [];
      const transformedDays = days.map((day) => ({
        id: day.id,
        label: `${moment(day.date).format("L")}`,
      }));

      // Объединяем старые и новые колонки, исключая дубли
      const previousColumns = state.wb.columns || [];
      const newColumns = [
        ...transformedColumns,
        ...transformedDays,
        { id: "Ads", label: "Заказы в день, шт" },
        { id: "idc", label: "Остаток хватит на дн" },
        // { id: "turnover", label: "Оборачиваемость" },
        { id: "strategy", label: "Стратегия" },
        { id: "marketing", label: "Маркетинг" },
        { id: "quality", label: "Качество" },
        { id: "content", label: "Контент" },
      ];

      const mergedColumns = previousColumns.concat(
        newColumns.filter(
          (newCol) => !previousColumns.some((oldCol) => oldCol.id === newCol.id)
        )
      );

      const hasMore = newData.length > 0;

      return {
        ...state,
        loading: false,
        wb: {
          ...state.wb,
          isClick: state.wb.isClick, // Сохраняем значение isClick
          totalItems: action.payload.totalItems,
          hasMore, // Обновляем информацию о том, есть ли еще данные
          currentPage: state.wb.currentPage, // Увеличиваем только при наличии данных
          data: uniqueData, // Обновляем состояние с уникальными данными
          weeks: updatedWeeks,
          columns: mergedColumns,
          sortByWeek: state.wb.sortByWeek,
          sortPrice: state.wb.sortPrice,
          sorTarticle: state.wb.sorTarticle,
          sortByWeekLastDay8: state.wb.sortByWeekLastDay8,
          averageOrdersBool: state.wb.averageOrdersBool,
          daysOfStockBool: state.wb.daysOfStockBool,
          turnoverVersion2Bool: state.wb.turnoverVersion2Bool,
          searchValue:state.wb.searchValue
        },
      };

    case "FETCH_WB_FAILURE":
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case "UPDATE_WB_COMMENT":
      const findComment = state.wb.data.find(item => item.id === action.payload.id);

      if (findComment) {
        const updatedData = state.wb.data.map(item =>
          item.id === action.payload.id
            ? {
              ...item,
              comments: {
                ...item.comments,
                [action.payload.commentType]: {
                  value: action.payload.comments,
                  date: action.payload.date,
                },
              },
            }
            : item
        );

        return {
          ...state,
          wb: {
            ...state.wb,
            data: updatedData,
          },
        };
      }

      return state; // Если элемент не найден

    case "REORDER_WB_PRODUCTS":
      return {
        ...state,
        wb: {
          ...state.wb,
          data: action.payload,
        },
      };

    case "REORDER_WB_COLUMNS":
      return {
        ...state,
        wb: {
          ...state.wb,
          columns: action.payload, // Сохраняем новый порядок колонок
        },
      };

    case "CLICK_ROWS_WB":
      return {
        ...state,
        wb: {
          ...state.wb,
          isClick: action.payload, // Это должно переключать значение
        },
      };

    case "UPDATE_CURRENT_PAGE_WB":
      return {
        ...state,
        wb: {
          ...state.wb,
          currentPage: action.payload, // Обновление currentPage
        },
      };

    case "SET_CURRENT_COMPANY_WB":
      return {
        ...state,
        wb: {
          ...state.wb,
          currentCompanyId: action.payload, // Устанавливаем текущую компанию
          currentPage: 1,
          data:[]
        },
      };




      // это из клиента подкрутки обновляем сортировку
    case "SORT_WEEKS_BY_DATE_UPDATE_WB":
      // Проверяем, существует ли неделя для сортировки
      if (state.wb.sortByWeek) {
        const isAscending = state.wb.isAscending;
        let sortDate = [...state.wb.data].sort((a, b) => {
          // Используем state.wb.sortByWeek для правильного доступа
          const aPercentage = a.weeks[state.wb.sortByWeek]?.totalPercentage || 0;
          const bPercentage = b.weeks[state.wb.sortByWeek]?.totalPercentage || 0;
          return isAscending ? bPercentage - aPercentage : aPercentage - bPercentage;
        });

        return {
          ...state,
          wb: {
            ...state.wb,
            data: sortDate,
            sortByWeek: state.wb.sortByWeek, // Сохраняем текущую неделю для сортировки
            isAscending,
            sortStock: false,
            sorTarticle: false,
            sortByWeekLastDay8: null,
          },
        };
      }
      return state;
    // здесь назначить сортировку для недель
    case "SORT_WEEKS_BY_DATE_WB": {
      const weekName = action.payload; // Получаем название недели из Action
      if (weekName) {
        const weekIndex = weekMapping[weekName]; // Находим индекс недели по идентификатору

        // Проверяем, существует ли неделя для сортировки
        if (weekIndex) {
          // Проверяем текущую сортировку для этой недели
          const isAscending = state.wb.sortByWeek === weekIndex ? !state.wb.isAscending : true;

          // Сортировка данных в зависимости от текущего направления
          state.wb.data.sort((a, b) => {
            const aPercentage = a.weeks[weekIndex]?.totalPercentage || 0;
            const bPercentage = b.weeks[weekIndex]?.totalPercentage || 0;
            return isAscending ? bPercentage - aPercentage : aPercentage - bPercentage;
          });

          return {
            ...state,
            wb: {
              ...state.wb,
              sortByWeek: weekIndex, // Сохраняем текущую неделю для сортировки
              isAscending, // Добавляем флаг для направления сортировки
              sortPrice: false,
              sortStock: false,
              sorTarticle: false,
              sortByWeekLastDay8: null,
            },
          };
        }
      }

      return state; // Возвращаем текущее состояние, если неделя не найдена
    }

    // сортировка для цены
    case "SORT_PRICE_WB":
      const isAscending = !state.wb.isAscending
      return {
        ...state,
        wb: {
          ...state.wb,
          sortPrice: !state.wb.sortPrice,
          sortPrice: true,
          sortStock: false,
          sorTarticle: false,
          isAscending: isAscending,
          sortByWeek: null,
          sortByWeekLastDay8: null,
          data: state.wb.data.sort((a, b) => {
            return state.wb.isAscending ? (a.price.sizes[0].price || 0) - (b.price.sizes[0].price || 0) : (b.price.sizes[0].price || 0) - (a.price.sizes[0].price || 0);
          }),
        },
      };


    // Сортировка по остатку товара
    case "SORT_STOCK_WB": {
      const isAscending = !state.wb.isAscending; // Объявляем переменную перед использованием
      return {
        ...state,
        wb: {
          ...state.wb,
          sortPrice: false,
          sortStock: true,
          sorTarticle: false,
          isAscending: isAscending,
          sortByWeek: null,
          sortByWeekLastDay8: null,
          data: state.wb.data.sort((a, b) => {
            return isAscending
              ? (b.stock || 0) - (a.stock || 0)
              : (a.stock || 0) - (b.stock || 0);
          }),
        },
      };
    }



    // сортировка по артикулу товара
    case "SORT_ACTICULE_WB": {
      const isAscending = !state.wb.isAscending; // Объявляем переменную перед использованием
      return {
        ...state,
        wb: {
          ...state.wb,
          sortPrice: false,
          sortStock: false,
          isAscending: isAscending,
          sortByWeek: null,
          sortByWeekLastDay8: null,
          sorTarticle: true,
          data: state.wb.data.sort((a, b) => {
            return isAscending
              ? (b.nmID || 0) - (a.nmID || 0)
              : (a.nmID || 0) - (b.nmID || 0);
          }),
        },
      };
    }



    // сортировка по дням для последней недели
    case "SORT_LASTWEEKDAY8_WB": {
      const weekName = action.payload; // Получаем название недели из Action
      console.log(weekName, "action weekName");

      if (weekName) {
        const weekKey = week8Days[weekName]; // Получаем строковый ключ, например "day1"
        console.log(weekKey, "weekKey");

        if (weekKey) {
          // Проверяем текущую сортировку для этой недели
          const isAscending = state.wb.sortByWeekLastDay8 === weekKey ? !state.wb.isAscending : true;

          // Создаем копию массива перед сортировкой
          const sortedData = [...state.wb.data].sort((a, b) => {
            // Проверяем, что `week8` существует и содержит нужный день, иначе 0
            const aPercentage = a.weeks.week8?.[weekKey]?.total ?? 0;
            const bPercentage = b.weeks.week8?.[weekKey]?.total ?? 0;
            return isAscending ? bPercentage - aPercentage : aPercentage - bPercentage;
          });

          return {
            ...state,
            wb: {
              ...state.wb,
              data: sortedData, // Используем отсортированные данные
              sortByWeekLastDay8: weekKey, // Сохраняем текущую неделю для сортировки
              sortByWeek: null,
              isAscending, // Добавляем флаг для направления сортировки
              sortPrice: false,
              sortStock: false,
              sorTarticle: false,
            },
          };
        }
      }

      return state; // Возвращаем текущее состояние, если неделя не найдена
    }



    // сортировка для средних заказа
    case "SORT_AVERAGEORDERS_WB": {
      const isAscending = !state.wb.isAscending; // Объявляем переменную перед использованием
      return {
        ...state,
        wb: {
          ...state.wb,
          sortPrice: false,
          sortStock: false,
          isAscending: isAscending,
          sortByWeek: null,
          sortByWeekLastDay8: null,
          sorTarticle: false,
          averageOrdersBool: true,
          daysOfStockBool: false,
          turnoverVersion2Bool: false,
          data: state.wb.data.sort((a, b) => {
            return isAscending
              ? (b.averageOrders || 0) - (a.averageOrders || 0)
              : (a.averageOrders || 0) - (b.averageOrders || 0);
          }),
        },
      };
    }


    //сортировка для  Остатка хватит на
    case "SORT_DAYS_STOCK_WB": {
      const isAscending = !state.wb.isAscending; // Объявляем переменную перед использованием
      return {
        ...state,
        wb: {
          ...state.wb,
          sortPrice: false,
          sortStock: false,
          isAscending: isAscending,
          sortByWeek: null,
          sortByWeekLastDay8: null,
          sorTarticle: false,
          averageOrdersBool: false,
          daysOfStockBool: true,
          turnoverVersion2Bool: false,
          data: state.wb.data.sort((a, b) => {
            return isAscending
              ? (b.daysOfStock || 0) - (a.daysOfStock || 0)
              : (a.daysOfStock || 0) - (b.daysOfStock || 0);
          }),
        },
      };
    }

    // сортировка для Оборачиваемость
    case "SORT_TURNOVER_WB": {
      const isAscending = !state.wb.isAscending; // Объявляем переменную перед использованием
      return {
        ...state,
        wb: {
          ...state.wb,
          sortPrice: false,
          sortStock: false,
          isAscending: isAscending,
          sortByWeek: null,
          sortByWeekLastDay8: null,
          sorTarticle: false,
          averageOrdersBool: false,
          daysOfStockBool: false,
          turnoverVersion2Bool: true,
          data: state.wb.data.sort((a, b) => {
            return isAscending
              ? (b.turnover || 0) - (a.turnover || 0)
              : (a.turnover || 0) - (b.turnover || 0);
          }),
        },
      };
    }


    case "SET_SEARCH_VALUEWB":
      return {
        ...state,
        loading: false,
        wb: {
          ...state.wb,
          searchValue: action.payload,
          currentPage: 1, // При новом поиске сбрасываем страницу
          data: [], // Очищаем данные перед новым запросом
          hasMore: true, // Снова включаем подгрузку

        },
      };

    default:
      return state;
  }
};

export default getWaldberisListReducer;
