import {
    Drawer,
    DrawerBody,
    DrawerFooter,
    DrawerHeader,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
    useDisclosure,
} from '@chakra-ui/react';

import React, { useEffect, useState } from 'react'
import "./filterLight.css"
import "./filterDark.css"
import PerfromerFilter from './perfromerFilter';
import FilterDate from './filterDate';
import MarketPlaceFilter from './marketPlaceFilter';
import PriceFilter from './priceFilter';
import ComplitedTaskFilter from './complitedTaskFilter';
import { getAllTask, setPageAction } from '../../../../../redux/reducer/getTaskall/action/action';
import { useDispatch, useSelector } from 'react-redux';
import { updateOptions } from '../../../../../redux/reducer/option/action/action';

export default function Filter() {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const btnRef = React.useRef()
    const [status, setStatus] = useState("");
    const [prioritet, setPrioritet] = useState("");
    const [perfromers, setPerfromers] = useState([]);
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [ozon, setOzon] = useState([]);
    const [wb, setWb] = useState([]);
    const [yandex, setYandex] = useState([]);
    const [megaMarket, setMegaMarket] = useState([]);
    const [minPrice, setMinPrice] = useState(0);
    const [maxPrice, setMaxPrice] = useState(0);
    const [completedTask, setcompletedTask] = useState(false);
    const [load, setLoad] = useState(false);
    const dispatch = useDispatch();
    const getDarkModoe = useSelector(darkModes => darkModes);
    const getAllTaskRed = useSelector(compID => compID.getAllTaskReducer)
    const myProject = useSelector((state) => state.optionReducer.myProject);
    const taskFiltersObject = useSelector(state => state.optionReducer.taskFiltersObject);
    const optionReducer = useSelector(state => state.optionReducer);

    const handleStatusChange = (event) => {
        setStatus(event.target.value); // Обновляем состояние при выборе
    };

    const handlePrioritetChange = (event) => {
        setPrioritet(event.target.value); // Обновляем состояние при выборе
    };

    const handleChangeEndDate = (e) => {
        setEndDate(e)
    }
    const handleChangeStartdDate = (e) => {
        setStartDate(e)
    }
    const handleFilter = async () => {
           dispatch(setPageAction(1))
        setLoad(true);
        const filters = {
            status,
            prioritet,
            perfromers,
            startDate,
            endDate,
            ozon,
            wb,
            yandex,
            megaMarket,
            minPrice,
            maxPrice,
            completedTask,
            myProject
        };

        try {
            const params = new URLSearchParams({
                filters: JSON.stringify(filters),
                page:getAllTaskRed.currentPage,  // Добавляем текущую страницу
                limit: getAllTaskRed.itemsPerPage // Добавляем количество элементов на странице
              }).toString()
            await Promise.all([
                dispatch(getAllTask(`/getTaskfindedByFilter/api?${params}`)),
                dispatch(updateOptions(filters, 'UPDATE_FILTERS_OBJECT')),
                dispatch(updateOptions(true, 'UPDATE_FILTERS')),
            ]);
        } catch (error) {
            console.error("Ошибка при отправке фильтров:", error);
        } finally {
            setLoad(false);
        }
    };
    
    const handleResst = () => {
        setStatus("");
        setPrioritet("");
        setPerfromers([]);
        setStartDate("");
        setEndDate("");
        setOzon([]);
        setWb([]);
        setYandex([]);
        setMegaMarket([]);
        setMinPrice(0);
        setMaxPrice(0);
        setcompletedTask(false);
        if (myProject) {
            dispatch(getAllTask(`/getFindedTaskForAllProject/api?page=${getAllTaskRed.currentPage}&limit=${getAllTaskRed.itemsPerPage}`));
        } else {
            dispatch(getAllTask(`/getTaskfinded/api?page=${getAllTaskRed.currentPage}&limit=${getAllTaskRed.itemsPerPage}`));
        }

        dispatch(updateOptions(false, 'UPDATE_FILTERS'));
        dispatch(updateOptions({}, 'UPDATE_FILTERS_OBJECT'));
    };


    const openDowp = () => {
        onOpen()

    }

    useEffect(() => {
        if (completedTask===true) {
            handleFilter()
        } else {
            handleResst();
        }
    }, [completedTask,myProject])

    



    return (
        <>
            <button ref={btnRef} colorScheme='teal' onClick={openDowp} className={getDarkModoe.ToggleDarkModeReducer.chekced ? "filterBntDark" : "filterBntLight"}>
                <div>
                    <p>Фильтр</p>
                </div>
                <div>
                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="20" viewBox="0 0 18 20" fill="none">
                        <path fillRule="evenodd" clipRule="evenodd" d="M3 0.25C1.48122 0.25 0.25 1.48122 0.25 3C0.25 4.51878 1.48122 5.75 3 5.75C4.51878 5.75 5.75 4.51878 5.75 3C5.75 1.48122 4.51878 0.25 3 0.25ZM1.75 3C1.75 2.30964 2.30964 1.75 3 1.75C3.69036 1.75 4.25 2.30964 4.25 3C4.25 3.69036 3.69036 4.25 3 4.25C2.30964 4.25 1.75 3.69036 1.75 3Z" fill="#353535" />
                        <path d="M9 2.25C8.58579 2.25 8.25 2.58579 8.25 3C8.25 3.41421 8.58579 3.75 9 3.75L17 3.75C17.4142 3.75 17.75 3.41421 17.75 3C17.75 2.58579 17.4142 2.25 17 2.25L9 2.25Z" fill="#353535" />
                        <path fillRule="evenodd" clipRule="evenodd" d="M15 7.25C13.4812 7.25 12.25 8.48122 12.25 10C12.25 11.5188 13.4812 12.75 15 12.75C16.5188 12.75 17.75 11.5188 17.75 10C17.75 8.48122 16.5188 7.25 15 7.25ZM13.75 10C13.75 9.30964 14.3096 8.75 15 8.75C15.6904 8.75 16.25 9.30964 16.25 10C16.25 10.6904 15.6904 11.25 15 11.25C14.3096 11.25 13.75 10.6904 13.75 10Z" fill="#353535" />
                        <path d="M1 9.25C0.585787 9.25 0.25 9.58579 0.25 10C0.25 10.4142 0.585786 10.75 1 10.75L9 10.75C9.41421 10.75 9.75 10.4142 9.75 10C9.75 9.58579 9.41421 9.25 9 9.25L1 9.25Z" fill="#353535" />
                        <path fillRule="evenodd" clipRule="evenodd" d="M3 14.25C1.48122 14.25 0.25 15.4812 0.25 17C0.25 18.5188 1.48122 19.75 3 19.75C4.51878 19.75 5.75 18.5188 5.75 17C5.75 15.4812 4.51878 14.25 3 14.25ZM1.75 17C1.75 16.3096 2.30964 15.75 3 15.75C3.69036 15.75 4.25 16.3096 4.25 17C4.25 17.6904 3.69036 18.25 3 18.25C2.30964 18.25 1.75 17.6904 1.75 17Z" fill="#353535" />
                        <path d="M9 16.25C8.58579 16.25 8.25 16.5858 8.25 17C8.25 17.4142 8.58579 17.75 9 17.75H17C17.4142 17.75 17.75 17.4142 17.75 17C17.75 16.5858 17.4142 16.25 17 16.25H9Z" fill="#353535" />
                    </svg>
                </div>
            </button>
            <Drawer
                isOpen={isOpen}
                placement='right'
                onClose={onClose}
                finalFocusRef={btnRef}
                size={"lg"}

            >
                <DrawerOverlay />
                <DrawerContent className={getDarkModoe.ToggleDarkModeReducer.chekced ? "filetContDark" : "filetContLight"}>
                    <DrawerCloseButton />
                    <DrawerHeader>Фильтр</DrawerHeader>

                    <DrawerBody>
                        <div className='statisFilterAndPrioritet'>
                            <div>
                                <p>Статус</p>
                                <p onClick={() => setStatus("")}>Очистить</p>
                            </div>
                            <label htmlFor='filterPrioritet'>
                                <select name="cars" id="filterPrioritet" value={status} onChange={handleStatusChange}>
                                    <option value="" selected disabled>Выберите статус</option>
                                    <option value="Новая">Новая</option>
                                    <option value="Выполнена">Выполнена</option>
                                    <option value="Закрыта">Закрыта</option>
                                    <option value="Возвращена">Возвращена</option>
                                    <option value="Выполняется">Выполняется</option>
                                    <option value="Отменено">Отменено</option>
                                </select>
                            </label>
                        </div>
                        <div className='statisFilterAndPrioritet'>
                            <div>
                                <p>Приоритет</p>
                                <p onClick={() => setPrioritet("")}>Очистить</p>
                            </div>
                            <label htmlFor='filterStatus'>
                                <select name="cars" id="filterStatus" value={prioritet} onChange={handlePrioritetChange}>
                                    <option value="" selected disabled>Выберите приоритет</option>
                                    <option value="Критический">Критический</option>
                                    <option value="Важно">Важно</option>
                                    <option value="Срочно">Срочно</option>
                                    <option value="Низший">Низший</option>
                                </select>
                            </label>
                        </div>

                        <PerfromerFilter perfromers={perfromers} setPerfromers={setPerfromers} />
                        <FilterDate handleChangeEndDate={handleChangeEndDate} handleChangeStartdDate={handleChangeStartdDate} startDate={startDate} setStartDate={setStartDate} setEndDate={setEndDate} endDate={endDate} />
                        <MarketPlaceFilter
                            ozon={ozon}
                            setOzon={setOzon}
                            setWb={setWb}
                            wb={wb}
                            setYandex={setYandex}
                            yandex={yandex}
                            setMegaMarket={setMegaMarket}
                            megaMarket={megaMarket}
                        />
                        < PriceFilter
                            minPrice={minPrice}
                            setMinPrice={setMinPrice}
                            maxPrice={maxPrice}
                            setMaxPrice={setMaxPrice}
                        />
                        <ComplitedTaskFilter
                            completedTask={completedTask}
                            setcompletedTask={setcompletedTask}
                        />
                    </DrawerBody>

                    <DrawerFooter>
                        <div>
                            <button variant='outline' mr={3} onClick={handleResst}>
                                Сбросить
                            </button>
                            <button colorScheme='blue' onClick={handleFilter} disabled={completedTask}>
                                <div>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                        <path d="M5 13L9 17L19 7" stroke="#F0F0F0" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                    <p>{load ? "Загрузка..." : "Применить"}</p>
                                </div>
                            </button>
                        </div>
                    </DrawerFooter>
                </DrawerContent>
            </Drawer>
        </>
    )
}