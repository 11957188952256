import { useState } from "react";
import "./paymentLight.css"
import { Button, Modal, Input, Typography, Space, Divider, Flex } from 'antd';
import PaymentModal from "./PaymentModal/PaymentModal";
const { Title, Text } = Typography;


export default function Payment(params) {

    return (
        <div className="paymentContLight">
            <div>
                <div>
                    <h2>Выберите ваш вариант Подписки</h2>
                    <p>Оплачивая подписку, вы соглашаетесь с условиями оферты.</p>
                </div>
                <div className="content">
                    <div className="toggleMountCont">
                        <button className="active">Месяц</button>
                        <button>Годовой</button>
                    </div>
                    <div className="paymentContInfo">
                        <div className="monthlySelectedPlan">
                            <div>
                                <div>
                                    <div>
                                        <div>
                                            <p>Пробный</p>
                                            <div>
                                                <p>Бесплатно</p>
                                                <p></p>
                                            </div>
                                        </div>
                                        <p>7-дневный бесплатный период для тестирования всех функций</p>
                                    </div>
                                    <div>
                                        <div>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                <path d="M8.5 12.5L10.5 14.5L15.5 9.5" stroke="#7A7A7A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M7 3.33801C8.51952 2.45891 10.2445 1.99731 12 2.00001C17.523 2.00001 22 6.47701 22 12C22 17.523 17.523 22 12 22C6.477 22 2 17.523 2 12C2 10.179 2.487 8.47001 3.338 7.00001" stroke="#7A7A7A" stroke-width="1.5" stroke-linecap="round" />
                                            </svg>
                                            <p>Бесплатный доступ для всех сотрудников</p>
                                        </div>
                                        <div>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                <path d="M8.5 12.5L10.5 14.5L15.5 9.5" stroke="#7A7A7A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M7 3.33801C8.51952 2.45891 10.2445 1.99731 12 2.00001C17.523 2.00001 22 6.47701 22 12C22 17.523 17.523 22 12 22C6.477 22 2 17.523 2 12C2 10.179 2.487 8.47001 3.338 7.00001" stroke="#7A7A7A" stroke-width="1.5" stroke-linecap="round" />
                                            </svg>
                                            <p>Изучите все функции нашего сервиса</p>
                                        </div>
                                        <div>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                <path d="M8.5 12.5L10.5 14.5L15.5 9.5" stroke="#7A7A7A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M7 3.33801C8.51952 2.45891 10.2445 1.99731 12 2.00001C17.523 2.00001 22 6.47701 22 12C22 17.523 17.523 22 12 22C6.477 22 2 17.523 2 12C2 10.179 2.487 8.47001 3.338 7.00001" stroke="#7A7A7A" stroke-width="1.5" stroke-linecap="round" />
                                            </svg>
                                            <p>Нет органичений на количество компаний</p>
                                        </div>
                                    </div>
                                </div>
                                {/*  */}

                                <button>
                                    <div>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                                            <path d="M5.25 13L9.25 17L19.25 7" stroke="#3B79F6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                        </svg>
                                        <p>Ваш план</p>
                                    </div>
                                </button>
                            </div>

                        </div>
                        <div className="selectThisPlan">
                            <div>
                                <div>
                                    <div>
                                        <div>
                                            <div>
                                                <div>
                                                    <p>Полный</p>
                                                    <div>
                                                        <p>2399₽/месяц</p>
                                                        <p>$20</p>
                                                    </div>
                                                </div>
                                                <p>Разблокируйте все возможности 
                                                с помощью полной подписки</p>
                                            </div>
                                            <svg width="120" height="99" viewBox="0 0 120 99" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <circle opacity="0.1" cx="123.738" cy="27.1787" r="71" transform="rotate(45 123.738 27.1787)" fill="white" />
                                                <circle opacity="0.1" cx="71.4102" cy="-9.59084" r="71" transform="rotate(45 71.4102 -9.59084)" fill="white" />
                                            </svg>

                                        </div>
                                    </div>
                                    <div>
                                        <div>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                <path d="M8.5 12.5L10.5 14.5L15.5 9.5" stroke="#7A7A7A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M7 3.33801C8.51952 2.45891 10.2445 1.99731 12 2.00001C17.523 2.00001 22 6.47701 22 12C22 17.523 17.523 22 12 22C6.477 22 2 17.523 2 12C2 10.179 2.487 8.47001 3.338 7.00001" stroke="#7A7A7A" stroke-width="1.5" stroke-linecap="round" />
                                            </svg>
                                            <p>Полный доступ для одной компании</p>
                                        </div>
                                        <div>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                <path d="M8.5 12.5L10.5 14.5L15.5 9.5" stroke="#7A7A7A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M7 3.33801C8.51952 2.45891 10.2445 1.99731 12 2.00001C17.523 2.00001 22 6.47701 22 12C22 17.523 17.523 22 12 22C6.477 22 2 17.523 2 12C2 10.179 2.487 8.47001 3.338 7.00001" stroke="#7A7A7A" stroke-width="1.5" stroke-linecap="round" />
                                            </svg>
                                            <p>1 API на каждый маркетплейс</p>
                                        </div>
                                        <div>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                <path d="M8.5 12.5L10.5 14.5L15.5 9.5" stroke="#7A7A7A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M7 3.33801C8.51952 2.45891 10.2445 1.99731 12 2.00001C17.523 2.00001 22 6.47701 22 12C22 17.523 17.523 22 12 22C6.477 22 2 17.523 2 12C2 10.179 2.487 8.47001 3.338 7.00001" stroke="#7A7A7A" stroke-width="1.5" stroke-linecap="round" />
                                            </svg>
                                            <p>Безлимитная команда</p>
                                        </div>
                                        <div>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                <path d="M8.5 12.5L10.5 14.5L15.5 9.5" stroke="#7A7A7A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M7 3.33801C8.51952 2.45891 10.2445 1.99731 12 2.00001C17.523 2.00001 22 6.47701 22 12C22 17.523 17.523 22 12 22C6.477 22 2 17.523 2 12C2 10.179 2.487 8.47001 3.338 7.00001" stroke="#7A7A7A" stroke-width="1.5" stroke-linecap="round" />
                                            </svg>
                                            <p>adipiscing elit, sed do</p>
                                        </div>
                                    </div>
                                    <div>

                                    </div>
                                </div>
                                <PaymentModal />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}