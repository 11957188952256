import Offcanvas from "react-bootstrap/Offcanvas";

import { Badge, Grid } from "@nextui-org/react";
import { Input } from "@chakra-ui/react";
import "./drawerTaskNotidy.css";
import "./drawerTaskNotidyDark.css"
import React, { useEffect, useRef, useState } from "react";
import ModalEditTask from "../modalEditTask/modalEditTask";
import axios from "axios";

import DeeletNotifybtn from "../deleteNotifyBTN/deleteNotifyBTN";
import { Avatar, AvatarBadge, AvatarGroup } from '@chakra-ui/react'
import { useSelector } from "react-redux";

import { Navigate, useNavigate } from "react-router";
import sockets from "../../../../socket/socket";
const socket = sockets;
const months = [
  "января", "февраля", "марта", "апреля", "мая", "июня",
  "июля", "августа", "сентября", "октября", "ноября", "декабря"
];
export default function DrawerTaskNotiify({
  mynotify,
  lengthNotify,
  update,
  handleGetNotify,
  item,
  index
}) {
  const [show, setShow] = useState(false);
  const getDarkModoe = useSelector(darkModes => darkModes)
  const navigate = useNavigate()
  const innerRef = useRef(null)
  useEffect(() => {
    var stringWithoutQuotes = item.notifyText.replace(/'/g, '');
    innerRef.current.innerHTML = stringWithoutQuotes;
    console.log(item);
    
}, [item])

  // здесь мы подтверждаем дату или статус
  const handleDelete = () => {
    axios.post("/handleDelete/api", {
      itemID: item._id,
    })
      .then(function (response) {

        socket.emit("getNotifyEmit");

      })
      .catch(function (error) {
        console.log(error);

      });
  };

  const handleApply = (e) => {
    console.log(e.senderEmail, "eee");

    if (e.typenotify === "date" && e.isChild === "false") {
      e.alltask[0][0].alltask[0].endDate = e.messageConfirm;
      let idTask = e.alltask[0][0]._id;
      let alltask = e.alltask[0][0].alltask;
      console.log(e.alltask[0][0]);

      axios
        .post("/handleapply/api", {
          idTask: idTask,
          alltask: alltask,
          pushItemID: e._id,
          adminID: e.adminIDTask,
          senderEmail: e.senderEmail,
          isChild: e.isChild,
          index: 0,
          task: alltask = e.alltask[0][0]
        })
        .then(function (response) {
          console.log(response);
          socket.emit("getNotifyEmit");
          socket.emit('getallTaskforUpdateServer');
          isUnread(item)
          update();
        })
        .catch(function (error) {
          console.log(error);
        });
    } else if (e.typenotify === "status" && e.isChild === "false") {
      e.alltask[0][0].alltask[0].status = "Закрыта";
      let idTask = e.alltask[0][0]._id;
      let alltask = e.alltask[0][0].alltask;

      axios
        .post("/handleapply/api", {
          idTask: idTask,
          alltask: alltask,
          pushItemID: e._id,
          adminID: e.adminIDTask,
          senderEmail: e.senderEmail,
          isChild: e.isChild,
          index: 0,
          task: alltask = e.alltask[0][0]
        })
        .then(function (response) {
          console.log(response);
          socket.emit("getNotifyEmit");
          socket.emit('getallTaskforUpdateServer');
          isUnread(item)
          update();
        })
        .catch(function (error) {
          console.log(error);
        });
    }
    else if (e.typenotify === "date" && e.isChild === "true") {
      e.alltask[0][0].alltask[0].child[e.indexTask].endDate = e.messageConfirm
      let idTask = e.alltask[0][0]._id;
      let alltask = e.alltask[0][0].alltask;
      axios
        .post("/handleapply/api", {
          idTask: idTask,
          alltask: alltask,
          pushItemID: e._id,
          adminID: e.adminIDTask,
          senderEmail: e.senderEmail,
          isChild: e.isChild,
          index: e.indexTask,
          task: alltask = e.alltask[0][0]
        })
        .then(function (response) {
          console.log(response);
          socket.emit("getNotifyEmit");
          socket.emit('getallTaskforUpdateServer');
          isUnread(item)
          update();
        })
        .catch(function (error) {
          console.log(error);
        });
    }
    else if (e.typenotify === "status" && e.isChild === "true") {
      e.alltask[0][0].alltask[0].child[e.indexTask].status = "Закрыта"
      let idTask = e.alltask[0][0]._id;
      let alltask = e.alltask[0][0].alltask;
      axios
        .post("/handleapply/api", {
          idTask: idTask,
          alltask: alltask,
          pushItemID: e._id,
          adminID: e.adminIDTask,
          senderEmail: e.senderEmail,
          isChild: e.isChild,
          index: e.indexTask,
          task: alltask = e.alltask[0][0]
        })
        .then(function (response) {
          console.log(response);
          socket.emit("getNotifyEmit");
          socket.emit('getallTaskforUpdateServer');
          isUnread(item)
          update();
        })
        .catch(function (error) {
          console.log(error);
        });
    }

  };

  // с детьми отменяем дату или статус
  const handleCanhel = (e) => {

    if (e.typenotify === "date" && e.isChild === "false") {
      e.alltask[0][0].alltask[0].endDate = e.oldDate;
      let idTask = e.alltask[0][0]._id;
      let alltask = e.alltask[0][0].alltask;

      console.log(e.alltask[0][0].alltask[0].endDate);
      axios
        .post("/handleCanchel/api", {
          idTask: idTask,
          alltask: alltask,
          pushItemID: e._id,
          adminID: e.adminIDTask,
          senderEmail: e.senderEmail,
          isChild: e.isChild,
          index: 0,
          task: alltask = e.alltask[0][0]
        })
        .then(function (response) {
          socket.emit("getNotifyEmit");
          socket.emit('getallTaskforUpdateServer');
          isUnread(item)
          update();
        })
        .catch(function (error) {
          console.log(error);
        });
    } else if (e.typenotify === "status" && e.isChild === "false") {
      e.alltask[0][0].alltask[0].status = "Возвращена";
      let idTask = e.alltask[0][0]._id;
      let alltask = e.alltask[0][0].alltask;

      console.log(e.alltask[0][0].alltask[0].endDate);
      axios
        .post("/handleCanchel/api", {
          idTask: idTask,
          alltask: alltask,
          pushItemID: e._id,
          adminID: e.adminIDTask,
          senderEmail: e.senderEmail,
          isChild: e.isChild,
          index: 0,
          task: alltask = e.alltask[0][0]
        })
        .then(function (response) {
          socket.emit("getNotifyEmit");
          socket.emit('getallTaskforUpdateServer');
          isUnread(item)
          update();
        })
        .catch(function (error) {
          console.log(error);
        });
    }
    else if (e.typenotify === "date" && e.isChild === "true") {
      e.alltask[0][0].alltask[0].child[e.indexTask].endDate = e.oldDate
      let idTask = e.alltask[0][0]._id;
      let alltask = e.alltask[0][0].alltask;
      axios
        .post("/handleCanchel/api", {
          idTask: idTask,
          alltask: alltask,
          pushItemID: e._id,
          adminID: e.adminIDTask,
          senderEmail: e.senderEmail,
          isChild: e.isChild,
          index: e.indexTask,
          task: alltask = e.alltask[0][0]
        })
        .then(function (response) {
          console.log(response);
          socket.emit("getNotifyEmit");
          socket.emit('getallTaskforUpdateServer');
          isUnread(item)
          update();
        })
        .catch(function (error) {
          console.log(error);
        });
    }
    else if (e.typenotify === "status" && e.isChild === "true") {
      e.alltask[0][0].alltask[0].child[e.indexTask].status = "Возвращена"
      let idTask = e.alltask[0][0]._id;
      let alltask = e.alltask[0][0].alltask;
      axios
        .post("/handleCanchel/api", {
          idTask: idTask,
          alltask: alltask,
          pushItemID: e._id,
          adminID: e.adminIDTask,
          senderEmail: e.senderEmail,
          isChild: e.isChild,
          index: e.indexTask,
          task: alltask = e.alltask[0][0]
        })
        .then(function (response) {
          console.log(response);
          socket.emit("getNotifyEmit");
          socket.emit('getallTaskforUpdateServer');
          isUnread(item)
          update();
        })
        .catch(function (error) {
          console.log(error);
        });
    }

  };

  const isUnread = async (taskId) => {
    try {
      const response = await axios.patch(`/api/notificationRead/`, { id: taskId._id });
      socket.emit("getNotifyEmit");
    } catch (error) {
      console.error('Error updating task:', error);
      socket.emit("getNotifyEmit");
    }
  }


  function convertDate(isoDate) {

    let date = new Date(isoDate);
    // Получаем день и месяц
    let day = date.getUTCDate();
    let month = months[date.getUTCMonth()];
    let year = date.getUTCFullYear();

    // Форматируем дату
    let formattedDate = `${day} ${month} ${year}`;
    return formattedDate;


  }
  useEffect(()=>{
      console.log(item,"item");
      
  },[item])
  return (
    <>
      <div className={getDarkModoe.ToggleDarkModeReducer.chekced ? "notifyContDark" : "notifyCont"}>

        <div>
          <Avatar size='sm' src={item?.myAvatar ? `/${item.myAvatar}`: ':https://bit.ly/broken-link'}  loading="lazy"/>
          <div>
            <p>Почта</p>
            <input type="text" name="" id="" placeholder="Почта" disabled value={item.senderEmail?item.senderEmail:"Электронная почта не указана"} />
          </div>
          <div>
            <p>Номер телефона</p>
            <div>
              <div>
                <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M6.61183 0.97505C5.55974 0.00831676 3.94259 0.00831676 2.8905 0.97505C2.84741 1.01464 2.80142 1.06065 2.74169 1.12039L1.84419 2.01788C0.941081 2.921 0.561962 4.22292 0.839024 5.4697C2.41099 12.5436 7.93553 18.0681 15.0094 19.6401C16.2562 19.9171 17.5581 19.538 18.4612 18.6349L19.3586 17.7375C19.4184 17.6777 19.4644 17.6317 19.504 17.5886C20.4708 16.5365 20.4708 14.9193 19.504 13.8673C19.4644 13.8241 19.4184 13.7781 19.3586 13.7184L17.8894 12.2491C16.8693 11.229 15.3287 10.9372 14.0063 11.5137C13.2492 11.8437 12.3672 11.6767 11.7832 11.0927L9.38639 8.69589C8.80239 8.11188 8.63536 7.22994 8.96538 6.47283C9.54184 5.15037 9.25009 3.60984 8.22999 2.58974L6.76066 1.12041C6.70093 1.06066 6.65492 1.01464 6.61183 0.97505ZM3.90541 2.07957C4.38363 1.64014 5.1187 1.64014 5.59692 2.07957C5.61343 2.09474 5.63507 2.11613 5.71114 2.1922L7.16933 3.6504C7.75333 4.2344 7.92036 5.11635 7.59034 5.87345C7.01388 7.19592 7.30563 8.73644 8.32573 9.75655L10.7225 12.1534C11.7426 13.1735 13.2832 13.4652 14.6056 12.8887C15.3627 12.5587 16.2447 12.7258 16.8287 13.3098L18.2869 14.768C18.3629 14.844 18.3843 14.8657 18.3995 14.8822C18.8389 15.3604 18.8389 16.0955 18.3995 16.5737C18.3843 16.5902 18.3629 16.6118 18.2869 16.6879L17.4005 17.5742C16.8601 18.1147 16.0809 18.3416 15.3348 18.1758C8.82946 16.7302 3.74893 11.6496 2.3033 5.1443C2.1375 4.39816 2.36438 3.61902 2.90485 3.07855L3.7912 2.1922C3.86726 2.11614 3.8889 2.09474 3.90541 2.07957Z" fill="#353535" />
                </svg>
                <input type="text" name="" id="" value={`+${item.senderTel}`} placeholder="Номер телефона" disabled />
              </div>

            </div>

          </div>
        </div>
        <div>
          <div>
            <div>
              <div>
              
              </div>
            </div>
              <p ref={innerRef}></p>


          </div>
          <div>
            {/* <button onClick={()=>isUnread(item)}>
              <p>
                {
                  item.isUnread?
                  "Прочитано"
                  :
                  "Прочитать"
                }
              </p>
            </button> */}
            <div className="confirmBtnNotify">
              {
                (() => {
                  if (item.statusNotify == "Требует подтверждения") {
                    return <>
                      <button className="confirm" onClick={() => handleApply(item)}>Подтвердить</button>
                      <button className="cancel" onClick={() => handleCanhel(item)}>Отменить</button>
                    </>
                  } else if (item.statusNotify == "Подтверждено") {
                    return <button className="confirmed" onClick={() => handleCanhel(item)}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
                        <path d="M4.16406 11.3359L7.10275 13.6869C7.79218 14.2384 8.7921 14.1566 9.38273 13.5004L15.8307 6.33594" stroke="#22C55E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                      </svg>
                      <p>Подтверждено</p>
                    </button>
                  }
                  else if (item.statusNotify == "Отменено") {
                    return <button className="canceled" onClick={() => handleApply(item)}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
                        <path d="M13.532 6.96098L6.46094 14.032M13.532 14.032L6.46094 6.96094" stroke="#EB5757" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                      </svg>
                      <p>Отмененно</p>
                    </button>
                  }
                })()
              }
              {/* <button onClick={() => isUnread(item)} className="noReadBtn">
                <p>
                  {
                    item.isUnread ?
                      "Прочитано"
                      :
                      "Прочитать"
                  }
                </p>
              </button> */}

            </div>


            <button onClick={handleDelete}>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                <path d="M10.6712 12.5L7.27521 9.10406C6.90826 8.73711 6.90826 8.14216 7.27521 7.77521C7.64216 7.40826 8.23711 7.40826 8.60406 7.77521L12 11.1712L15.3959 7.77521C15.7629 7.40826 16.3578 7.40826 16.7248 7.77521C17.0917 8.14216 17.0917 8.73711 16.7248 9.10406L13.3288 12.5L16.7248 15.8959C17.0917 16.2629 17.0917 16.8578 16.7248 17.2248C16.3578 17.5917 15.7629 17.5917 15.3959 17.2248L12 13.8288L8.60406 17.2248C8.23711 17.5917 7.64216 17.5917 7.27521 17.2248C6.90826 16.8578 6.90826 16.2629 7.27521 15.8959L10.6712 12.5Z" fill="#64748B" />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
