import React, { useEffect, useState } from 'react';
import { Button, Modal, Input, Typography, Space, Divider, CollapseProps, notification, Spin } from 'antd';
import { Collapse } from 'antd';
import "./PaymentModal.css"
import axios from "axios"
const { Title, Text } = Typography;


const PaymentModal = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isload, setload] = useState(false);
    const [isloadPromocode, setloadPromocode] = useState(false);
    const [toggle, setToggle] = useState(false);
    const [promocode, setpromocode] = useState('');
    const [promocodeVal, setpromocodeVal] = useState('');
    const [promoCodeInfo, setPromoCodeInfo] = useState(null);
    const [api, contextHolder] = notification.useNotification();


    const showModal = () => {

        setIsModalOpen(true);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const handlePay = () => {
        setload(true)
        // GET request for remote image in node.js
        axios({
            method: 'get',
            url: `/generate-payment?promoCode=${promocode}`,

        })
            .then(function (response) {
                window.location.href = response.data.paymentUrl;
                setload(false)
            });
    };

    const togglePromocode = () => {
        setToggle(!toggle)
    }


    const handleApplayPromokode = () => {
        if (promocodeVal.trim() === "") {
            api.open({
                message: "Промокод пуст",
                duration: 0,
                type: "error"
            });
            setPromoCodeInfo(null)
            return
        }

        setloadPromocode(true)
        axios.post('/applyPromoCode', {
            promoCode: promocodeVal
        })
            .then(function (response) {
                setPromoCodeInfo(response.data)
                setloadPromocode(false)
                api.open({
                    message: response.data.message,
                    duration: 2,
                    type: "success"
                });
                setpromocode(promocodeVal)
            })
            .catch(function (error) {
                console.log(error);
                setloadPromocode(false)
                setPromoCodeInfo(null)
                api.open({
                    message: error.response.data.message,
                    duration: 0,
                    type: "error"
                });
            });

        console.log(promocodeVal, "promocodepromocode");

    }

    useEffect(()=>{
        console.log(promoCodeInfo);
        
    },[promoCodeInfo])

    return (
        <>
            {contextHolder}
            <button onClick={showModal}>
                <div>
                    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
                        <path d="M15.25 11L11.75 14.5M7.25 11H15.25H7.25ZM15.25 11L11.75 7.5L15.25 11Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M11.25 21C16.7728 21 21.25 16.5228 21.25 11C21.25 5.47715 16.7728 1 11.25 1C5.72715 1 1.25 5.47715 1.25 11C1.25 16.5228 5.72715 21 11.25 21Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                    <p>Выбрать план</p>
                </div>
            </button>

            <Modal
                title={<span style={{ 
                    color: "#353535",
                    fontFamily:"Inter",
                    fontSize:"20px",
                    fontStyle:"normal",
                    fontWeight:"600",
                    lineHeight:"20px"
                    
                }}>Оплата подписки</span>}
                visible={isModalOpen}
                onCancel={handleCancel}
                footer={null} // Убираем стандартные кнопки футера
                
            >
                <div className='modal-overlay'>
                    <div>
                        <p>Вы собираетесь оплатить подписку на 1 месяц в сервисе Упрости.рф. Будет подключено автопродление подписки. </p>
                    </div>
                    <label htmlFor="emailPayment">
                        Почта
                        <input type="email" name="" id="emailPayment" />
                    </label>
                    <div>
                        <div>
                            <div>
                                <div>
                                    <div>
                                        <div>
                                            <p>Подписка на месяц</p>
                                            <p>2399.00 руб/мес</p>
                                        </div>
                                    </div>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="456" height="2" viewBox="0 0 456 2" fill="none">
                                        <path opacity="0.1" d="M0 1C7.01538 1 306.923 1 456 1" stroke="black" />
                                    </svg>
                                    <button onClick={togglePromocode} >
                                        <p>Добавить промокод</p>
                                        {
                                            toggle ?
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                    <path d="M7.50511 14.663C7.13401 14.999 6.5634 14.9676 6.23061 14.5929C5.89783 14.2183 5.92889 13.6422 6.29999 13.3062L11.2566 8.71414C11.5994 8.40372 12.1188 8.40372 12.4617 8.71414L17.5334 13.4104C17.9045 13.7464 17.9355 14.3225 17.6027 14.6971C17.27 15.0718 16.6993 15.1032 16.3282 14.7672L11.8591 10.6165L7.50511 14.663Z" fill="#3B79F6" />
                                                </svg>
                                                :
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                    <path d="M7.50511 9.33702C7.13401 9.00104 6.5634 9.0324 6.23061 9.40706C5.89783 9.78173 5.92889 10.3578 6.29999 10.6938L11.2566 15.2859C11.5994 15.5963 12.1188 15.5963 12.4617 15.2859L17.5334 10.5896C17.9045 10.2536 17.9355 9.67754 17.6027 9.30287C17.27 8.9282 16.6993 8.89684 16.3282 9.23283L11.8591 13.3835L7.50511 9.33702Z" fill="#3B79F6" />
                                                </svg>
                                        }

                                    </button>
                                    {
                                        toggle && <div>
                                            <input type="text" onChange={(e) => setpromocodeVal(e.target.value)} value={promocodeVal} />
                                            <button onClick={handleApplayPromokode} disabled={isloadPromocode}>

                                                {
                                                    isloadPromocode === true ?
                                                        " Загрузка..."
                                                        :
                                                        "Применить"
                                                }
                                            </button>
                                        </div>
                                    }

                                    <svg xmlns="http://www.w3.org/2000/svg" width="456" height="2" viewBox="0 0 456 2" fill="none">
                                        <path opacity="0.1" d="M0 1C7.01538 1 306.923 1 456 1" stroke="black" />
                                    </svg>
                                    <div className='priceCont'>
                                        <div>
                                            <p>Будет списано сегодня</p>
                                            {
                                                promoCodeInfo?
                                                <p>Скидка по промокоду  
                                                
                                                {
                                                    `    -${promoCodeInfo?.promo?.discountValue}%`
                                                }
                                            </p>
                                                :
                                                null
                                            }
                                           
                                        </div>
                        
                                       
                                        {
                                            promoCodeInfo?
                                            <p className='finalPrice'>{promoCodeInfo.finalPrice}₽</p>
                                            :
                                          null
                                        }

                                        {
                                            promoCodeInfo?
                                            <p className='skidkaUnderLine'>2399₽</p>
                                            :
                                            <p>2399₽</p>
                                        }
                                        
                                    </div>
                                    <p>Нажимая «Оплатить», вы соглашаетесь с офертой  Ваша подписка будет автоматически продлеваться ежемесячно, пока вы не отключите подлписку.</p>
                                </div>
                            </div>
                            <div className='footer'>
                                <button onClick={handleCancel}>Отменить</button>
                                <button onClick={handlePay}>
                                    <div>
                                        <div>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
                                                <path d="M15 11L11.5 14.5M7 11H15H7ZM15 11L11.5 7.5L15 11Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M11 21C16.5228 21 21 16.5228 21 11C21 5.47715 16.5228 1 11 1C5.47715 1 1 5.47715 1 11C1 16.5228 5.47715 21 11 21Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                            </svg>
                                        </div>
                                        <p>Оплатить</p>
                                    </div>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default PaymentModal;
