import React, { useEffect, useRef, useState } from "react";

import {
    Drawer,
    DrawerBody,
    DrawerFooter,
    DrawerHeader,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
    useDisclosure,
    Button,
    Input
} from '@chakra-ui/react'
import { Image, ConfigProvider } from 'antd';


import homePage1 from "./image/homePage1.png"
import homePage2 from "./image/homePage2.png"
import homePage3 from "./image/homePage3.png"
import homePage4 from "./image/homePage4.png"
import homePage5 from "./image/homePage5.png"
import homePage6 from "./image/homePage6.png"
import homePage7 from "./image/homePage7.png"
import homePage8 from "./image/homePage8.png"
import homePage9 from "./image/homePage9.png"
import homePage10 from "./image/homePage10.png"
import homePage11 from "./image/homePage11.png"
import test from "./image/test.jpg"
import { useSelector } from "react-redux";
import ruRU from "antd/locale/ru_RU";
import dayjs from "dayjs";
import "dayjs/locale/ru";
// Функция debounce, которая откладывает вызов fn на delay мс
function debounce(fn, delay) {
    let timeoutId;
    return (...args) => {
        if (timeoutId) clearTimeout(timeoutId);
        timeoutId = setTimeout(() => fn(...args), delay);
    };
}
export default function ContenInstruct({ isOpens }) {

    // Создаем объект состояния для всех 57 полей ввода
    const [inputs, setInputs] = useState({
        input1: "",
        input2: "",
        input3: "",
        input4: "",
        input5: "",
        input6: "",
        input7: "",
        input8: "",
        input9: "",
        input10: "",
        input11: "",
        input12: "",
        input13: "",
        input14: "",
        input15: "",
        input16: "",
        input17: "",
        input18: "",
        input19: "",
        input20: "",
        input21: "",
        input22: "",
        input23: "",
        input24: "",
        input25: "",
        input26: "",
        input27: "",
        input28: "",
        input29: "",
        input30: "",
        input31: "",
        input32: "",
        input33: "",
        input34: "",
        input35: "",
        input36: "",
        input37: "",
        input38: "",
        input39: "",
        input40: "",
        input41: "",
        input42: "",
        input43: "",
        input44: "",
        input45: "",
        input46: "",
        input47: "",
        input48: "",
        input49: "",
        input50: "",
        input51: "",
        input52: "",
        input53: "",
        input54: "",
        input55: "",
        input56: "",
        input57: ""
    });
    const modalBodyRef = useRef(null);
    // Состояние для коллапсируемого меню API ключей
    const [apiOpen, setApiOpen] = useState(false);
    const [isOpenDasboard, setisOpenDasboard] = useState(false);
    const [ispivotTable, setpivotTable] = useState(false);
    const [activeItem, setActiveItem] = useState('Главная страница');
    const { isOpen, onOpen, onClose } = useDisclosure()
    const isMobile = () => /Android|iPhone|iPad|iPod/i.test(navigator.userAgent);
    const isMobiles = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

    const getDarkModoe = useSelector(darkModes => darkModes)
    const [activeSection, setActiveSection] = useState('Главная страница');


    const handleChange = (key) => (event) => {
        // setInputs((prev) => ({ ...prev, [key]: event.target.value }));
    };

    const toggleApiMenu = () => {
        setApiOpen(!apiOpen);
    };

    const toggleDashboard = () => {
        console.log("toggleDashboard");

        setApiOpen(false);
        setisOpenDasboard(!isOpenDasboard);
    };


    const toggpivotTable = () => {
        console.log("toggleDashboard");

        setApiOpen(false);
        setisOpenDasboard(false);
        setpivotTable(!ispivotTable);
    };


    // Функция для установки активного элемента
    const handleItemClick = (item) => {
        // Находим элемент страницы по id, совпадающему с названием пункта меню
        const element = document.getElementById(item);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    };
    // Создаем дебаунсированную функцию для setActiveItem с задержкой 100 мс
    const debouncedSetActiveItem = useRef(
        debounce((sectionId) => {
            if (sectionId) {
                console.log(sectionId);
                if (sectionId == `Секция: API ключи (Wildberries)` || sectionId == `Секция: API ключи  OZON`) {
                    console.log("hi");
                    setisOpenDasboard(false)
                    setpivotTable(false)
                    setApiOpen(true)

                } else if (sectionId == `WB` || sectionId == `Ozon`) {
                    console.log("hi");
                    setisOpenDasboard(true)
                    setpivotTable(false)
                    setApiOpen(false)

                } else if (sectionId == `Ozon pivot` || sectionId == `WB pivot`) {
                    console.log("hi");
                    setisOpenDasboard(false)
                    setpivotTable(true)
                    setApiOpen(false)

                }
            }
            setActiveItem(sectionId);
        }, 100)
    ).current;

    useEffect(() => {
        const container = modalBodyRef.current;
        if (!container) return;

        // Получаем все секции внутри контейнера
        const sections = container.querySelectorAll('.section');

        const observer = new IntersectionObserver(
            (entries) => {
                let maxRatio = 0;
                let currentSectionId = activeSection;
                entries.forEach((entry) => {
                    if (entry.intersectionRatio > maxRatio) {
                        maxRatio = entry.intersectionRatio;
                        currentSectionId = entry.target.id;
                    }
                });
                setActiveSection(currentSectionId);
                debouncedSetActiveItem(currentSectionId);
            },
            {
                root: isMobiles ? null : modalBodyRef.current, // для мобильных используем окно
                threshold: Array.from({ length: 101 }, (_, i) => i / 100),
            }
        );

        sections.forEach((section) => observer.observe(section));

        return () => observer.disconnect();
    }, [activeSection]);

    const handleCloseDrowerAndSetSection = (item) => {
        handleItemClick(item)
        onClose()
    }

    return (

        <div className={getDarkModoe.ToggleDarkModeReducer.chekced ? "conten-instructDark" : "conten-instruct"}>
            {/* Header */}


            <div className="main-wrapper">
                {/* Sidebar */}
                {
                    isMobile() === true && <div onClick={onOpen} className="mobileDrowerBtnForInstrcution">
                        <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M5.88297 17.6416H19.323C19.785 17.6416 20.163 17.2636 20.163 16.8016C20.163 16.3396 19.785 15.9616 19.323 15.9616H5.88297C5.42097 15.9616 5.04297 16.3396 5.04297 16.8016C5.04297 17.2636 5.42097 17.6416 5.88297 17.6416ZM5.88297 13.442H19.323C19.785 13.442 20.163 13.064 20.163 12.602C20.163 12.14 19.785 11.762 19.323 11.762H5.88297C5.42097 11.762 5.04297 12.14 5.04297 12.602C5.04297 13.064 5.42097 13.442 5.88297 13.442ZM5.04297 8.4025C5.04297 8.8645 5.42097 9.2425 5.88297 9.2425H19.323C19.785 9.2425 20.163 8.8645 20.163 8.4025C20.163 7.9405 19.785 7.5625 19.323 7.5625H5.88297C5.42097 7.5625 5.04297 7.9405 5.04297 8.4025Z" fill="white" />
                        </svg>
                    </div>
                }
                <Drawer
                    isOpen={isOpen}
                    placement='left'
                    onClose={onClose}
                    lockFocusAcrossFrames={false}
                    returnFocusOnClose={false}

                >
                    <DrawerOverlay />
                    <DrawerContent>
                        <DrawerCloseButton color={getDarkModoe.ToggleDarkModeReducer.chekced ? "white" : "black"} />


                        <DrawerBody className={getDarkModoe.ToggleDarkModeReducer.chekced ? "mobileDrowerDark" : "mobileDrower"}>
                            <aside className="sidebar">
                                <nav>
                                    <ul>
                                        <li
                                            className={activeItem === 'Главная страница' ? 'active' : ''}
                                            onClick={() => handleCloseDrowerAndSetSection('Главная страница')}
                                        >
                                            Главная страница
                                        </li>
                                        <li
                                            className={activeItem === 'Добавление компании' ? 'active' : ''}
                                            onClick={() => handleCloseDrowerAndSetSection('Добавление компании')}
                                        >
                                            Добавление компании
                                        </li>
                                        <li
                                            className={activeItem === 'Заполнение профиля' ? 'active' : ''}
                                            onClick={() => handleCloseDrowerAndSetSection('Заполнение профиля')}
                                        >
                                            Заполнение профиля
                                        </li>
                                        <li
                                            className={activeItem === 'Задачи' ? 'active' : ''}
                                            onClick={() => handleCloseDrowerAndSetSection('Задачи')}
                                        >
                                            Задачи
                                        </li>
                                        <li
                                            className={`collapsible-header ${activeItem === 'API ключи' ? 'active' : ''}`}
                                            onClick={toggleApiMenu}
                                        >
                                            API ключи
                                            {apiOpen ? (
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                    <path d="M7.50511 14.663C7.13401 14.999 6.5634 14.9676 6.23061 14.5929C5.89783 14.2183 5.92889 13.6422 6.29999 13.3062L11.2566 8.71414C11.5994 8.40372 12.1188 8.40372 12.4617 8.71414L17.5334 13.4104C17.9045 13.7464 17.9355 14.3225 17.6027 14.6971C17.27 15.0718 16.6993 15.1032 16.3282 14.7672L11.8591 10.6165L7.50511 14.663Z" fill="#3B79F6" />
                                                </svg>
                                            ) : (
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                    <path d="M7.50511 9.33702C7.13401 9.00104 6.5634 9.0324 6.23061 9.40706C5.89783 9.78173 5.92889 10.3578 6.29999 10.6938L11.2566 15.2859C11.5994 15.5963 12.1188 15.5963 12.4617 15.2859L17.5334 10.5896C17.9045 10.2536 17.9355 9.67754 17.6027 9.30287C17.27 8.9282 16.6993 8.89684 16.3282 9.23283L11.8591 13.3835L7.50511 9.33702Z" fill="#353535" />
                                                </svg>
                                            )}
                                        </li>
                                        {apiOpen && (
                                            <ul className="nested-menu">
                                                <li
                                                    className={activeItem === 'Секция: API ключи (Wildberries)' ? 'active' : ''}
                                                    onClick={() => handleCloseDrowerAndSetSection('Секция: API ключи (Wildberries)')}
                                                >
                                                    WB
                                                </li>
                                                <li
                                                    className={activeItem === 'Секция: API ключи  OZON' ? 'active' : ''}
                                                    onClick={() => handleCloseDrowerAndSetSection('Секция: API ключи  OZON')}
                                                >
                                                    Ozon
                                                </li>
                                            </ul>
                                        )}
                                        <li
                                            className={activeItem === 'Сотрудники' ? 'active' : ''}
                                            onClick={() => handleCloseDrowerAndSetSection('Сотрудники')}
                                        >
                                            Сотрудники
                                        </li>
                                        <li
                                            className={activeItem === 'Чат' ? 'active' : ''}
                                            onClick={() => handleCloseDrowerAndSetSection('Чат')}
                                        >
                                            Чат
                                        </li>
                                        <li
                                            className={activeItem === 'Дэшборд' ? 'active' : ''}
                                            onClick={() => toggleDashboard()}
                                        >
                                            Дэшборд
                                            {isOpenDasboard ? (
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                    <path d="M7.50511 14.663C7.13401 14.999 6.5634 14.9676 6.23061 14.5929C5.89783 14.2183 5.92889 13.6422 6.29999 13.3062L11.2566 8.71414C11.5994 8.40372 12.1188 8.40372 12.4617 8.71414L17.5334 13.4104C17.9045 13.7464 17.9355 14.3225 17.6027 14.6971C17.27 15.0718 16.6993 15.1032 16.3282 14.7672L11.8591 10.6165L7.50511 14.663Z" fill="#3B79F6" />
                                                </svg>
                                            ) : (
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                    <path d="M7.50511 9.33702C7.13401 9.00104 6.5634 9.0324 6.23061 9.40706C5.89783 9.78173 5.92889 10.3578 6.29999 10.6938L11.2566 15.2859C11.5994 15.5963 12.1188 15.5963 12.4617 15.2859L17.5334 10.5896C17.9045 10.2536 17.9355 9.67754 17.6027 9.30287C17.27 8.9282 16.6993 8.89684 16.3282 9.23283L11.8591 13.3835L7.50511 9.33702Z" fill="#353535" />
                                                </svg>
                                            )}
                                        </li>
                                        {isOpenDasboard && (
                                            <ul className="nested-menu">
                                                <li
                                                    className={activeItem === 'WB' ? 'active' : ''}
                                                    onClick={() => handleCloseDrowerAndSetSection('WB')}
                                                >
                                                    WB
                                                </li>
                                                <li
                                                    className={activeItem === 'Ozon' ? 'active' : ''}
                                                    onClick={() => handleCloseDrowerAndSetSection('Ozon')}
                                                >
                                                    Ozon
                                                </li>
                                            </ul>
                                        )}
                                        <li
                                            className={activeItem === 'Сноски для сводной таблицы' ? 'active' : ''}
                                            onClick={() => toggpivotTable()}
                                        >
                                            Сноски для сводной таблицы
                                            {ispivotTable ? (
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                    <path d="M7.50511 14.663C7.13401 14.999 6.5634 14.9676 6.23061 14.5929C5.89783 14.2183 5.92889 13.6422 6.29999 13.3062L11.2566 8.71414C11.5994 8.40372 12.1188 8.40372 12.4617 8.71414L17.5334 13.4104C17.9045 13.7464 17.9355 14.3225 17.6027 14.6971C17.27 15.0718 16.6993 15.1032 16.3282 14.7672L11.8591 10.6165L7.50511 14.663Z" fill="#3B79F6" />
                                                </svg>
                                            ) : (
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                    <path d="M7.50511 9.33702C7.13401 9.00104 6.5634 9.0324 6.23061 9.40706C5.89783 9.78173 5.92889 10.3578 6.29999 10.6938L11.2566 15.2859C11.5994 15.5963 12.1188 15.5963 12.4617 15.2859L17.5334 10.5896C17.9045 10.2536 17.9355 9.67754 17.6027 9.30287C17.27 8.9282 16.6993 8.89684 16.3282 9.23283L11.8591 13.3835L7.50511 9.33702Z" fill="#353535" />
                                                </svg>
                                            )}
                                        </li>
                                        {ispivotTable && (
                                            <ul className="nested-menu">
                                                <li
                                                    className={activeItem === 'Ozon pivot' ? 'active' : ''}
                                                    onClick={() => handleCloseDrowerAndSetSection('Ozon pivot')}
                                                >
                                                    Ozon
                                                </li>
                                                <li
                                                    className={activeItem === 'WB pivot' ? 'active' : ''}
                                                    onClick={() => handleCloseDrowerAndSetSection('WB pivot')}
                                                >
                                                    WB
                                                </li>

                                            </ul>
                                        )}
                                    </ul>
                                </nav>
                            </aside>
                        </DrawerBody>

                    </DrawerContent>
                </Drawer>
                {
                    isMobile() === false && <aside className="sidebar">
                        <nav>
                            <ul>
                                <li
                                    className={activeItem === 'Главная страница' ? 'active' : ''}
                                    onClick={() => handleItemClick('Главная страница')}
                                >
                                    Главная страница
                                </li>
                                <li
                                    className={activeItem === 'Добавление компании' ? 'active' : ''}
                                    onClick={() => handleItemClick('Добавление компании')}
                                >
                                    Добавление компании
                                </li>
                                <li
                                    className={activeItem === 'Заполнение профиля' ? 'active' : ''}
                                    onClick={() => handleItemClick('Заполнение профиля')}
                                >
                                    Заполнение профиля
                                </li>
                                <li
                                    className={activeItem === 'Задачи' ? 'active' : ''}
                                    onClick={() => handleItemClick('Задачи')}
                                >
                                    Задачи
                                </li>
                                <li
                                    className={`collapsible-header ${activeItem === 'Секция: API ключи (Wildberries) и OZON' ? 'active' : ''}`}
                                    onClick={toggleApiMenu}
                                >
                                    API ключи
                                    {apiOpen ? (
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                            <path d="M7.50511 14.663C7.13401 14.999 6.5634 14.9676 6.23061 14.5929C5.89783 14.2183 5.92889 13.6422 6.29999 13.3062L11.2566 8.71414C11.5994 8.40372 12.1188 8.40372 12.4617 8.71414L17.5334 13.4104C17.9045 13.7464 17.9355 14.3225 17.6027 14.6971C17.27 15.0718 16.6993 15.1032 16.3282 14.7672L11.8591 10.6165L7.50511 14.663Z" fill="#3B79F6" />
                                        </svg>
                                    ) : (
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                            <path d="M7.50511 9.33702C7.13401 9.00104 6.5634 9.0324 6.23061 9.40706C5.89783 9.78173 5.92889 10.3578 6.29999 10.6938L11.2566 15.2859C11.5994 15.5963 12.1188 15.5963 12.4617 15.2859L17.5334 10.5896C17.9045 10.2536 17.9355 9.67754 17.6027 9.30287C17.27 8.9282 16.6993 8.89684 16.3282 9.23283L11.8591 13.3835L7.50511 9.33702Z" fill="#353535" />
                                        </svg>
                                    )}
                                </li>
                                {apiOpen && (
                                    <ul className="nested-menu">
                                        <li
                                            className={activeItem === 'Секция: API ключи (Wildberries)' ? 'active' : ''}
                                            onClick={() => handleItemClick('Секция: API ключи (Wildberries)')}
                                        >
                                            WB
                                        </li>
                                        <li
                                            className={activeItem === 'Секция: API ключи  OZON' ? 'active' : ''}
                                            onClick={() => handleItemClick('Секция: API ключи  OZON')}
                                        >
                                            Ozon
                                        </li>
                                    </ul>
                                )}
                                <li
                                    className={activeItem === 'Сотрудники' ? 'active' : ''}
                                    onClick={() => handleItemClick('Сотрудники')}
                                >
                                    Сотрудники
                                </li>
                                <li
                                    className={activeItem === 'Чат' ? 'active' : ''}
                                    onClick={() => handleItemClick('Чат')}
                                >
                                    Чат
                                </li>
                                <li
                                    className={activeItem === 'Дэшборд' ? 'active' : ''}
                                    onClick={() => toggleDashboard()}
                                >
                                    Дэшборд
                                    {isOpenDasboard ? (
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                            <path d="M7.50511 14.663C7.13401 14.999 6.5634 14.9676 6.23061 14.5929C5.89783 14.2183 5.92889 13.6422 6.29999 13.3062L11.2566 8.71414C11.5994 8.40372 12.1188 8.40372 12.4617 8.71414L17.5334 13.4104C17.9045 13.7464 17.9355 14.3225 17.6027 14.6971C17.27 15.0718 16.6993 15.1032 16.3282 14.7672L11.8591 10.6165L7.50511 14.663Z" fill="#3B79F6" />
                                        </svg>
                                    ) : (
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                            <path d="M7.50511 9.33702C7.13401 9.00104 6.5634 9.0324 6.23061 9.40706C5.89783 9.78173 5.92889 10.3578 6.29999 10.6938L11.2566 15.2859C11.5994 15.5963 12.1188 15.5963 12.4617 15.2859L17.5334 10.5896C17.9045 10.2536 17.9355 9.67754 17.6027 9.30287C17.27 8.9282 16.6993 8.89684 16.3282 9.23283L11.8591 13.3835L7.50511 9.33702Z" fill="#353535" />
                                        </svg>
                                    )}
                                </li>
                                {isOpenDasboard && (
                                    <ul className="nested-menu">
                                        <li
                                            className={activeItem === 'WB' ? 'active' : ''}
                                            onClick={() => handleItemClick('WB')}
                                        >
                                            WB
                                        </li>
                                        <li
                                            className={activeItem === 'Ozon' ? 'active' : ''}
                                            onClick={() => handleItemClick('Ozon')}
                                        >
                                            Ozon
                                        </li>
                                    </ul>
                                )}
                                <li
                                    className={activeItem === 'Сноски для сводной таблицы' ? 'active' : ''}
                                    onClick={() => toggpivotTable()}
                                >
                                    Сноски для сводной таблицы
                                    {ispivotTable ? (
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                            <path d="M7.50511 14.663C7.13401 14.999 6.5634 14.9676 6.23061 14.5929C5.89783 14.2183 5.92889 13.6422 6.29999 13.3062L11.2566 8.71414C11.5994 8.40372 12.1188 8.40372 12.4617 8.71414L17.5334 13.4104C17.9045 13.7464 17.9355 14.3225 17.6027 14.6971C17.27 15.0718 16.6993 15.1032 16.3282 14.7672L11.8591 10.6165L7.50511 14.663Z" fill="#3B79F6" />
                                        </svg>
                                    ) : (
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                            <path d="M7.50511 9.33702C7.13401 9.00104 6.5634 9.0324 6.23061 9.40706C5.89783 9.78173 5.92889 10.3578 6.29999 10.6938L11.2566 15.2859C11.5994 15.5963 12.1188 15.5963 12.4617 15.2859L17.5334 10.5896C17.9045 10.2536 17.9355 9.67754 17.6027 9.30287C17.27 8.9282 16.6993 8.89684 16.3282 9.23283L11.8591 13.3835L7.50511 9.33702Z" fill="#353535" />
                                        </svg>
                                    )}
                                </li>
                                {ispivotTable && (
                                    <ul className="nested-menu">
                                        <li
                                            className={activeItem === 'Ozon pivot' ? 'active' : ''}
                                            onClick={() => handleItemClick('Ozon pivot')}
                                        >
                                            Ozon
                                        </li>
                                        <li
                                            className={activeItem === 'WB pivot' ? 'active' : ''}
                                            onClick={() => handleItemClick('WB pivot')}
                                        >
                                            WB
                                        </li>

                                    </ul>
                                )}
                            </ul>
                        </nav>
                    </aside>
                }


                {/* Main Content */}
                <ConfigProvider locale={ruRU}>
                    <main className="content" ref={modalBodyRef} tabIndex="-1">
                        {/* Секция: Главная страница */}
                        <section className="section" id="Главная страница">
                            <h2>Главная страница</h2>
                            <p>
                                Добро пожаловать в таск-трекер Упрости!

                            </p>
                            <p>
                                Обращаем Ваше внимание, что для работы возможно понадобится изменить масштаб, т.к для ПК с низким разрешением может по умолчанию показываться мобильная версия трекера. Чтобы увеличить или уменьшить масштаб, удерживайте клавишу Ctrl (для Windows) или Command (для Mac) при повороте колесика прокрутки вперед или назад. Также изменить масштаб можно при помощи горячих клавиш Ctrl или Command + Плюс (+) для увеличения и Ctrl или Command + Минус (-) для уменьшения.

                            </p>

                            <p>На главной странице слева расположен блок с настройками Вашего профиля, перечнем задач, чатами, списком сотрудников, QR-кодом для входа в приложение и инструкцией по его установке, а также Вы можете добавить API ключи, сотрудников и изменить тему с помощью переключателя.
                            </p>

                            <p>Скрыть/раскрыть панель можно нажав на иконку с тремя полосками.</p>
                            <div className="images-row">
                                <Image
                                    src="https://storage.googleapis.com/tagjs-prod.appspot.com/HWlyNDeUdw/4yr7rg2b.png"
                                    alt="Инструкция 1"
                                />
                                <Image
                                    src="https://storage.googleapis.com/tagjs-prod.appspot.com/HWlyNDeUdw/0si20fn1.png"
                                    alt="Инструкция 2"
                                />
                                <Image
                                    src="https://storage.googleapis.com/tagjs-prod.appspot.com/HWlyNDeUdw/u2hmmvvb.png"
                                    alt="Инструкция 3"
                                />
                            </div>
                            {/* ============== */}
                            <p>
                                В верхней части на панели расположены дэшборды WB и Ozon, отражающие ключевые показатели в режиме реального времени, а также список добавленных ранее компаний, кнопка для добавления новой компании, навигация по разделам и уведомления.
                            </p>
                            <Image
                                className="full-width-image"
                                src="https://storage.googleapis.com/tagjs-prod.appspot.com/HWlyNDeUdw/3d1srsxs.png"
                                alt="Дэшборд верхней панели"
                                loading="lazy"

                            />

                            {/* ============== */}
                            <p>
                                Для начала работы в таск-трекере Упрости перейдите в настройки на панели слева, нажав на шестеренку, и заполните информацию о себе в профиле.
                            </p>
                            <Image
                                className="full-width-image"
                                src="https://storage.googleapis.com/tagjs-prod.appspot.com/HWlyNDeUdw/s840pb17.png"
                                alt="Дэшборд верхней панели"
                            />
                            {/* ============== */}
                            <p>
                                Далее в верхней панели добавьте компанию, нажав на синюю кнопку.
                            </p>
                            <Image
                                className="full-width-image"
                                src="https://storage.googleapis.com/tagjs-prod.appspot.com/HWlyNDeUdw/z7kfbfr7.png"
                                alt="Дэшборд верхней панели"
                            />

                            {/* ============== */}
                            <p>
                                Добавить сотрудников Вы можете как с помощью кнопки в нижней части на панели слева, так и в разделе «Сотрудники».
                            </p>
                            <Image
                                className="full-width-image"
                                src="https://storage.googleapis.com/tagjs-prod.appspot.com/HWlyNDeUdw/p8g164ih.png"
                                alt="Дэшборд верхней панели"
                            />
                            {/* ============== */}
                            <p>
                                Добавить сотрудников Вы можете как с помощью кнопки в нижней части на панели слева, так и в разделе «Сотрудники».
                            </p>

                            <div className="images-row">
                                <Image
                                    src="https://storage.googleapis.com/tagjs-prod.appspot.com/HWlyNDeUdw/oxpkpydx.png"
                                    alt="Инструкция 1"
                                />
                                <Image
                                    src="https://storage.googleapis.com/tagjs-prod.appspot.com/HWlyNDeUdw/897wo135.png"
                                    alt="Инструкция 2"
                                />
                            </div>

                            {/* ============== */}
                            <p>
                                В всплывающем окне введите номер телефона сотрудника и выберите роль из выпадающего списка, нажав на стрелку. Теперь Вы можете добавить сотрудника или сразу отправить ему ссылку-приглашение.
                            </p>

                            <div className="images-row">
                                <Image
                                    src="https://storage.googleapis.com/tagjs-prod.appspot.com/HWlyNDeUdw/7dsus50m.png"
                                    alt="Инструкция 1"
                                />
                                <Image
                                    src="https://storage.googleapis.com/tagjs-prod.appspot.com/HWlyNDeUdw/xuatnx4h.png"
                                    alt="Инструкция 2"
                                />
                            </div>

                        </section>

                        {/* Секция: Добавление компании */}
                        <section className="section" id="Добавление компании">
                            <h2>Добавление компании</h2>
                            <p>
                                Для добавления компании заполните информацию и нажмите кнопку «Создать компанию».
                            </p>
                            <Image
                                className="full-width-image"
                                src="https://storage.googleapis.com/tagjs-prod.appspot.com/HWlyNDeUdw/npakwlua.png"
                                alt="Добавление компании"
                            />
                            <p>
                                Найти все созданные компании, а также переключаться между ними можно в выпадающем списке на верхней панели.
                            </p>
                            <Image
                                className="full-width-image"
                                src="https://storage.googleapis.com/tagjs-prod.appspot.com/HWlyNDeUdw/qzlhhpx6.png"
                                alt="Список компаний"
                            />
                            <p>
                                Найденные компании отображаются с возможностью переключения.
                            </p>
                            <Image
                                className="full-width-image"
                                src="https://storage.googleapis.com/tagjs-prod.appspot.com/HWlyNDeUdw/eiclkvp5.png"
                                alt="Детали компании"
                            />
                        </section>

                        {/* Секция: Заполнение профиля */}
                        <section className="section" id="Заполнение профиля">
                            <h2>Заполнение профиля</h2>
                            <p>
                                Заполните информацию профиля вручную или автоматически через Яндекс, Google или Mail.ru. После загрузки фотографии и внесения информации нажмите кнопку «Сохранить».
                            </p>
                            <Image
                                className="full-width-image"
                                src="https://storage.googleapis.com/tagjs-prod.appspot.com/HWlyNDeUdw/ygtsthla.png"
                                alt="Заполнение профиля"
                            />
                        </section>

                        {/* Секция: Задачи */}
                        <section className="section" id="Задачи">
                            <h2>Задачи</h2>
                            <p>
                                Для создания новой задачи нажмите на плюс в центре экрана или кнопку «Добавить задачу» в левом верхнем углу.
                            </p>
                            <Image
                                className="full-width-image tall-image"
                                src="https://storage.googleapis.com/tagjs-prod.appspot.com/HWlyNDeUdw/8b45ar87.png"
                                alt="Новая задача"
                                width={"auto"}
                            />
                            <p>Заполните информацию по новой задаче:</p>
                            <Image
                                className="full-width-image tall-image"
                                src="https://storage.googleapis.com/tagjs-prod.appspot.com/HWlyNDeUdw/ygywrll1.png"
                                alt="Информация по задаче"

                            />

                            <div className="task-step">
                                <Image
                                    className="full-width-image"
                                    src="https://storage.googleapis.com/tagjs-prod.appspot.com/HWlyNDeUdw/gvjv979e.png"
                                    alt="Шаг 1"
                                    width={"auto"}
                                />
                                <button className="task-button">
                                    1. По умолчанию приоритет задачи устанавливается как «Низший». Вы можете изменить его, выбрав значение из списка.
                                </button>
                            </div>

                            <div className="task-step">
                                <Image
                                    className="full-width-image"
                                    src="https://storage.googleapis.com/tagjs-prod.appspot.com/HWlyNDeUdw/jozdteo4.png"
                                    alt="Шаг 2"
                                />
                                <input
                                    placeholder="2. Статус задачи «Новая» устанавливается по умолчанию. При необходимости измените его."
                                    value={inputs.input9}
                                    onChange={handleChange("input9")}
                                    className="input"
                                />
                            </div>

                            <div className="task-step">
                                <input
                                    placeholder="4. Введите название задачи;"
                                    value={inputs.input10}
                                    onChange={handleChange("input10")}
                                    className="input"
                                />
                                <input
                                    placeholder="5. Установите цену за выполненную задачу, если необходимо;"
                                    value={inputs.input11}
                                    onChange={handleChange("input11")}
                                    className="input"
                                />
                                <input
                                    placeholder="6. Установите сроки начала и окончания задачи. Нажмите на иконку с календарем для выбора даты."
                                    value={inputs.input12}
                                    onChange={handleChange("input12")}
                                    className="input"
                                />
                                <Image
                                    className="full-width-image"
                                    src="https://storage.googleapis.com/tagjs-prod.appspot.com/HWlyNDeUdw/7bmtb7g7.png"
                                    alt="Выбор даты"
                                />
                                <span>В левой части выберите месяц и число, а в правой – время.</span>
                                <Image
                                    className="full-width-image"
                                    src="https://storage.googleapis.com/tagjs-prod.appspot.com/HWlyNDeUdw/kxr03fih.png"
                                    alt="Выбор времени"
                                />
                                <span>Затем закройте окно с помощью крестика или кнопки «закрыть».</span>
                                <Image
                                    className="full-width-image"
                                    src="https://storage.googleapis.com/tagjs-prod.appspot.com/HWlyNDeUdw/wnlych0h.png"
                                    alt="Закрыть окно"
                                />
                                <input
                                    placeholder="7. Установите повтор задачи (ежедневно/еженедельно/и т.д.)."
                                    value={inputs.input13}
                                    onChange={handleChange("input13")}
                                    className="input"
                                />
                                <Image
                                    className="full-width-image"
                                    src="https://storage.googleapis.com/tagjs-prod.appspot.com/HWlyNDeUdw/tazk9r1d.png"
                                    alt="Настройка повторения"
                                />
                                <span>
                                    В всплывающем окне название задачи, создатель и дата заполняются автоматически. При необходимости измените дату первого повтора.
                                </span>
                                <Image
                                    className="full-width-image"
                                    src="https://storage.googleapis.com/tagjs-prod.appspot.com/HWlyNDeUdw/k5k65mwc.png"
                                    alt="Первый повтор задачи"
                                />
                                <span>Выберите частоту повторений с помощью раскрывающегося списка.</span>
                                <div className="task-images-row">
                                    <Image
                                        className="half-image"
                                        src="https://storage.googleapis.com/tagjs-prod.appspot.com/HWlyNDeUdw/4q5x9lc9.png"
                                        alt="Повтор 1"
                                    />
                                    <Image
                                        className="half-image"
                                        src="https://storage.googleapis.com/tagjs-prod.appspot.com/HWlyNDeUdw/kmx68fh2.png"
                                        alt="Повтор 2"
                                    />
                                </div>
                                <span>Закройте окно, нажав на крестик или кнопку «закрыть».</span>
                                <Image
                                    className="full-width-image"
                                    src="https://storage.googleapis.com/tagjs-prod.appspot.com/HWlyNDeUdw/o92wim2d.png"
                                    alt="Закрыть окно"
                                />
                                <span>
                                    В таблице повторяющиеся задачи будут помечены значком (часики) рядом с названием.
                                </span>
                                <Image
                                    className="full-width-image"
                                    src="https://storage.googleapis.com/tagjs-prod.appspot.com/HWlyNDeUdw/fduzm5wn.png"
                                    alt="Повторяющаяся задача"
                                />
                            </div>

                            <div className="task-description">
                                <input
                                    placeholder="8. Добавьте описание задачи;"
                                    value={inputs.input14}
                                    onChange={handleChange("input14")}
                                    className="input"
                                />
                                <input
                                    placeholder="9. Выберите одну или несколько площадок для выполнения задачи;"
                                    value={inputs.input15}
                                    onChange={handleChange("input15")}
                                    className="input"
                                />
                                <input
                                    placeholder="10. При необходимости добавьте файл с ПК;"
                                    value={inputs.input16}
                                    onChange={handleChange("input16")}
                                    className="input"
                                />
                                <input
                                    placeholder="11. Добавьте теги для поиска. Нажмите Enter или кнопку «Добавить»."
                                    value={inputs.input17}
                                    onChange={handleChange("input17")}
                                    className="input"
                                />
                                <Image
                                    className="full-width-image"
                                    src="https://storage.googleapis.com/tagjs-prod.appspot.com/HWlyNDeUdw/87aluekk.png"
                                    alt="Теги"
                                />
                                <div className="task-step">
                                    <span>
                                        12. Вы можете разбить задачу на подзадачи – введите название и нажмите Enter или синюю кнопку с плюсом.
                                    </span>
                                </div>
                                <Image
                                    className="full-width-image"
                                    src="https://storage.googleapis.com/tagjs-prod.appspot.com/HWlyNDeUdw/gj9jpm7l.png"
                                    alt="Подзадачи"
                                />
                                <span>Чтобы перейти к заполнению подзадачи, щелкните на неё.</span>
                            </div>

                            <div className="subtask-section">
                                <Image
                                    className="full-width-image"
                                    src="https://storage.googleapis.com/tagjs-prod.appspot.com/HWlyNDeUdw/a374dnbz.png"
                                    alt="Окно подзадачи"
                                />
                                <span>
                                    Перед Вами откроется новое окно. По умолчанию приоритет – «низший», статус – «новая». При необходимости измените их.
                                </span>
                                <Image
                                    className="full-width-image"
                                    src="https://storage.googleapis.com/tagjs-prod.appspot.com/HWlyNDeUdw/vbhehhad.png"
                                    alt="Изменение сотрудника"
                                />
                                <span>
                                    Всплывающее окно со списком сотрудников. Отметьте галочкой нужных и нажмите «Добавить».
                                </span>
                                <Image
                                    className="full-width-image"
                                    src="https://storage.googleapis.com/tagjs-prod.appspot.com/HWlyNDeUdw/f3qotp5e.png"
                                    alt="Выбор сотрудников"
                                />
                                <span>
                                    Введите цену и измените сроки начала/окончания. В подзадаче также можно добавить описание и файлы.
                                </span>
                                <Image
                                    className="full-width-image"
                                    src="https://storage.googleapis.com/tagjs-prod.appspot.com/HWlyNDeUdw/icjjf0da.png"
                                    alt="Изменение сроков"
                                />
                                <span>Комментарий можно удалить или изменить, нажав на три точки.</span>
                                <Image
                                    className="full-width-image"
                                    src="https://storage.googleapis.com/tagjs-prod.appspot.com/HWlyNDeUdw/qan3mfud.png"
                                    alt="Редактирование комментария"
                                />
                                <span>После внесения информации по подзадаче закройте её.</span>
                                <Image
                                    className="full-width-image"
                                    src="https://storage.googleapis.com/tagjs-prod.appspot.com/HWlyNDeUdw/xdv1ad93.png"
                                    alt="Закрыть подзадачу"
                                />
                                <span>
                                    Подзадача создана. Чтобы удалить её, нажмите на крестик рядом с названием.
                                </span>
                                <Image
                                    className="full-width-image"
                                    src="https://storage.googleapis.com/tagjs-prod.appspot.com/HWlyNDeUdw/8hu9w5bb.png"
                                    alt="Удаление подзадачи"
                                />
                                <button className="task-button" onClick={() => alert("Pressed!")}>
                                    13. Чтобы оставить комментарий в задаче, введите текст и/или прикрепите файл, затем нажмите кнопку.
                                </button>
                                <input
                                    placeholder="14. Завершите создание задачи с помощью кнопки «Создать задачу»."
                                    value={inputs.input18}
                                    onChange={handleChange("input18")}
                                    className="input"
                                />
                                <span>
                                    Все созданные задачи будут отображаться в разделе «Задачи». На дэшборде видны название, площадки, исполнитель, срок, приоритет, статус и цена.
                                </span>
                                <Image
                                    className="full-width-image tall-image"
                                    src="https://storage.googleapis.com/tagjs-prod.appspot.com/HWlyNDeUdw/z0cc5r8d.png"
                                    alt="Сводная таблица задач"
                                />
                                <span>Вы можете сортировать задачи по срокам, приоритету, статусу или цене.</span>
                                <Image
                                    className="full-width-image tall-image"
                                    src="https://storage.googleapis.com/tagjs-prod.appspot.com/HWlyNDeUdw/dqjh7of7.png"
                                    alt="Сортировка задач"
                                />
                                <span>
                                    Также доступны поиск задач и их фильтрация по различным параметрам. При помощи ползунка можно скрыть выполненные задачи.
                                </span>
                                <Image
                                    className="full-width-image tall-image"
                                    src="https://storage.googleapis.com/tagjs-prod.appspot.com/HWlyNDeUdw/pcqqdyb0.png"
                                    alt="Фильтрация задач"
                                />
                                <span>
                                    После установки фильтров нажмите «Применить», а затем закройте окно.
                                </span>
                                <div className="task-images-row" style={{ display: "flex" }}>
                                    <Image
                                        className="half-image"
                                        src="https://storage.googleapis.com/tagjs-prod.appspot.com/HWlyNDeUdw/d0ha2743.png"
                                        alt="Фильтр 1"
                                        width={"50%"}
                                    />
                                    <Image
                                        className="half-image"
                                        src="https://storage.googleapis.com/tagjs-prod.appspot.com/HWlyNDeUdw/sf3vogie.png"
                                        alt="Фильтр 2"
                                        width={"50%"}
                                    />
                                </div>
                                <span>
                                    Чтобы сбросить фильтрацию, снова перейдите в фильтры, нажмите «Сбросить» и закройте окно.
                                </span>
                                <div className="task-images-row">
                                    <Image
                                        className="half-image"
                                        src="https://storage.googleapis.com/tagjs-prod.appspot.com/HWlyNDeUdw/f72dgwzp.png"
                                        alt="Сброс фильтра 1"
                                    />
                                    <Image
                                        className="half-image"
                                        src="https://storage.googleapis.com/tagjs-prod.appspot.com/HWlyNDeUdw/0uw4o0rh.png"
                                        alt="Сброс фильтра 2"
                                    />
                                </div>
                                <span>
                                    Вы можете применять поиск и сортировку задач не только по выбранной компании, но и во всех проектах.
                                </span>
                                <Image
                                    className="full-width-image"
                                    src="https://storage.googleapis.com/tagjs-prod.appspot.com/HWlyNDeUdw/lkvqqksf.png"
                                    alt="Все проекты"
                                />
                            </div>
                        </section>

                        {/* Секция: API ключи (Wildberries) */}
                        <section className="section" id="Секция: API ключи (Wildberries)">
                            <h2 id="API WB">API ключи (Wildberries)</h2>
                            <p>
                                Необходимо сгенерировать ключи для категорий «Контент», «Аналитика», «Цены и скидки», «Маркетплейс», «Статистика». Зайдите в личный кабинет поставщика и перейдите в настройки.
                            </p>
                            <Image
                                className="full-width-image"
                                src="https://storage.googleapis.com/tagjs-prod.appspot.com/HWlyNDeUdw/ontbg2y4.png"
                                alt="Настройки API Wildberries"
                            />
                            <p>
                                Откройте вкладку “Доступ к новому API” и нажмите кнопку “Создать новый ключ”.
                            </p>
                            <Image
                                className="full-width-image"
                                src="https://storage.googleapis.com/tagjs-prod.appspot.com/HWlyNDeUdw/e0lu1uc9.png"
                                alt="Создание ключа"
                            />
                            <p>
                                Введите имя токена, выберите категории, поставьте галочку «Только на чтение» и нажмите «Создать токен». Затем скопируйте ключ и внесите его в трекер.
                            </p>
                            <Image
                                className="full-width-image"
                                src="https://storage.googleapis.com/tagjs-prod.appspot.com/HWlyNDeUdw/vkmde23h.png"
                                alt="Ключ Wildberries"
                            />
                        </section>

                        {/* Секция: API ключи  OZON */}
                        <section className="section" id="Секция: API ключи  OZON">

                            <h2 id="API OZON">OZON</h2>
                            <p>
                                Необходимо сгенерировать ключ «Admin read only». Перейдите в личный кабинет Ozon Seller и затем на страницу настроек.
                            </p>
                            <Image
                                className="full-width-image"
                                src="https://storage.googleapis.com/tagjs-prod.appspot.com/HWlyNDeUdw/ei9nlfta.png"
                                alt="Настройки Ozon"
                            />
                            <p>
                                Перейдите на вкладку Seller API и нажмите кнопку “Сгенерировать ключ”.
                            </p>
                            <Image
                                className="full-width-image"
                                src="https://storage.googleapis.com/tagjs-prod.appspot.com/HWlyNDeUdw/vg9pa0zm.png"
                                alt="Ключ Seller API"
                            />
                            <p>
                                Введите название ключа, выберите тип «Admin read only», нажмите «Сгенерировать», скопируйте ключ и внесите его в трекер. Для подключения также скопируйте Client ID.
                            </p>
                            <div className="ozon-images">
                                <Image
                                    className="half-image"
                                    src="https://storage.googleapis.com/tagjs-prod.appspot.com/HWlyNDeUdw/zrarmwc1.png"
                                    alt="OZON ключ 1"
                                    width={"50%"}
                                />
                                <Image
                                    className="half-image"
                                    src="https://storage.googleapis.com/tagjs-prod.appspot.com/HWlyNDeUdw/zsny3by7.png"
                                    alt="OZON ключ 2"
                                    width={"50%"}
                                />
                            </div>
                            <div className="ozon-images">
                                <Image
                                    className="full-width-image"
                                    src="https://storage.googleapis.com/tagjs-prod.appspot.com/HWlyNDeUdw/g6ah81nb.png"
                                    alt="Client ID"
                                />
                                <Image
                                    className="full-width-image"
                                    src="https://storage.googleapis.com/tagjs-prod.appspot.com/HWlyNDeUdw/tcfjmi1b.png"
                                    alt="Client ID 2"

                                />
                                <Image
                                    className="full-width-image"
                                    src="https://storage.googleapis.com/tagjs-prod.appspot.com/HWlyNDeUdw/von0qhtu.png"
                                    alt="OZON итоговый"
                                />
                            </div>
                        </section>

                        {/* Секция: Сотрудники */}
                        <section className="section" id="Сотрудники">
                            <h2>Сотрудники</h2>
                            <p>
                                В данном разделе Вы можете добавить новых сотрудников в выбранной компании, осуществлять поиск и удалять.
                            </p>
                            <Image
                                className="full-width-image"
                                src="https://storage.googleapis.com/tagjs-prod.appspot.com/HWlyNDeUdw/bnml3tps.png"
                                alt="Сотрудники"
                            />
                            <p>
                                Чтобы добавить сотрудника, нажмите кнопку «Добавить сотрудника» и введите его номер телефона и выберите роль.
                            </p>
                            <div className="employee-images">
                                <Image
                                    className="half-image"
                                    src="https://storage.googleapis.com/tagjs-prod.appspot.com/HWlyNDeUdw/my7u1eai.png"
                                    alt="Сотрудник 1"
                                />
                                <Image
                                    className="half-image"
                                    src="https://storage.googleapis.com/tagjs-prod.appspot.com/HWlyNDeUdw/qzzz391y.png"
                                    alt="Сотрудник 2"
                                />
                            </div>
                            <Image
                                className="full-width-image"
                                src="https://storage.googleapis.com/tagjs-prod.appspot.com/HWlyNDeUdw/qlp21gks.png"
                                alt="Приглашение сотрудника"
                            />
                        </section>

                        {/* Секция: Чат */}
                        <section className="section" id="Чат">
                            <h2>Чат</h2>
                            <p>
                                В данном разделе Вы можете создать чаты для обсуждения. После нажатия на кнопку «Создать чат» откроется всплывающее окно с настройками.
                            </p>
                            <div className="chat-images">
                                <Image
                                    className="half-image"
                                    src="https://storage.googleapis.com/tagjs-prod.appspot.com/HWlyNDeUdw/61os1fgg.png"
                                    alt="Чат 1"
                                />
                                <Image
                                    className="half-image"
                                    src="https://storage.googleapis.com/tagjs-prod.appspot.com/HWlyNDeUdw/sdion640.png"
                                    alt="Чат 2"
                                />
                            </div>
                            <input
                                placeholder="1. Загрузите аватар чата;"
                                value={inputs.input19}
                                onChange={handleChange("input19")}
                                className="input"
                                disabled
                            />
                            <input
                                placeholder="2. Введите название;"
                                value={inputs.input20}
                                onChange={handleChange("input20")}
                                className="input"
                                disabled
                            />
                            <button className="chat-button" onClick={() => alert("Pressed!")}>
                                3. Выберите вид: беседа (для всех) или канал (только редакторы);
                            </button>
                            <input
                                placeholder="5. Можно сделать чат публичным, после чего изменить его будет нельзя;"
                                value={inputs.input21}
                                onChange={handleChange("input21")}
                                className="input"
                                disabled
                            />
                            <input
                                placeholder="6. Создайте канал."
                                value={inputs.input22}
                                onChange={handleChange("input22")}
                                className="input"
                                disabled
                            />
                            <p>
                                В открывшемся окне отметьте участников галочкой и нажмите кнопку «Добавить».
                            </p>
                            <Image
                                className="full-width-image"
                                src="https://storage.googleapis.com/tagjs-prod.appspot.com/HWlyNDeUdw/l4f61861.png"
                                alt="Участники чата"
                            />
                            <p>
                                В зависимости от типа, чат будет отображаться во вкладке «Публичный» или «Проекты». Для личного чата нажмите «Создать чат» во вкладке «Личный чат».
                            </p>
                            <Image
                                className="full-width-image"
                                src="https://storage.googleapis.com/tagjs-prod.appspot.com/HWlyNDeUdw/ymv47uem.png"
                                alt="Личный чат"
                            />
                            <p>
                                Выберите чат, введите сообщение (с возможностью прикрепления файла, картинки, отметки участника) и отправьте его, нажав синюю стрелку.
                            </p>
                            <Image
                                className="full-width-image"
                                src="https://storage.googleapis.com/tagjs-prod.appspot.com/HWlyNDeUdw/z8ccgg9o.png"
                                alt="Сообщения в чате"
                            />
                            <p>Щелкнув правой кнопкой мыши можно:</p>
                            <input
                                placeholder="1. оставить реакцию на сообщение;"
                                value={inputs.input23}
                                onChange={handleChange("input23")}
                                className="input"
                                disabled
                            />
                            <input
                                placeholder="2. добавить комментарий;"
                                value={inputs.input24}
                                onChange={handleChange("input24")}
                                className="input"
                                disabled
                            />
                            <input
                                placeholder="3. ответить, скопировать ссылку, отметить сообщение как «важное», отредактировать, перейти к задаче или удалить."
                                value={inputs.input25}
                                onChange={handleChange("input25")}
                                className="input"
                                disabled
                            />
                            <div className="chat-images">
                                <Image
                                    className="half-image"
                                    src="https://storage.googleapis.com/tagjs-prod.appspot.com/HWlyNDeUdw/8w8jsj50.png"
                                    alt="Опции чата 1"
                                />
                                <Image
                                    className="half-image"
                                    src="https://storage.googleapis.com/tagjs-prod.appspot.com/HWlyNDeUdw/ydbvc82s.png"
                                    alt="Опции чата 2"
                                />
                            </div>
                            <p>
                                Поиск по чату доступен в верхней панели.
                            </p>
                            <Image
                                className="full-width-image"
                                src="https://storage.googleapis.com/tagjs-prod.appspot.com/HWlyNDeUdw/q3gjlth7.png"
                                alt="Поиск по чату"
                            />
                            <p>
                                Изменить аватар, название, закрепить чат, отключить уведомления, скопировать ссылку, добавить пользователя или удалить можно, нажав на кнопку со значком «i».
                            </p>
                            <Image
                                className="full-width-image"
                                src="https://storage.googleapis.com/tagjs-prod.appspot.com/HWlyNDeUdw/o2b3t9kx.png"
                                alt="Настройки чата"
                            />
                        </section>

                        {/* Секция: Дэшборд (Wildberries) */}
                        <section className="section" id="WB">
                            <h2>Дэшборд (Wildberries)</h2>
                            <p>
                                Перед Вами сводная таблица для анализа ключевых показателей эффективности в режиме реального времени. С её помощью можно отследить динамику заказов и спрогнозировать допоставку товара.
                            </p>
                            <Image
                                className="full-width-image"
                                src="https://storage.googleapis.com/tagjs-prod.appspot.com/HWlyNDeUdw/585c1clc.png"
                                alt="Дэшборд Wildberries 1"
                            />
                            <Image
                                className="full-width-image"
                                src="https://storage.googleapis.com/tagjs-prod.appspot.com/HWlyNDeUdw/mdxaj2fr.png"
                                alt="Дэшборд Wildberries 2"
                            />
                            <p>Таблица включает в себя:</p>
                            <input
                                placeholder="1. Название товара, артикулы WB и продавца;"
                                value={inputs.input26}
                                onChange={handleChange("input26")}
                                className="input"
                                disabled
                            />
                            <input
                                placeholder="2. Цена, установленная продавцом в личном кабинете;"
                                value={inputs.input27}
                                onChange={handleChange("input27")}
                                className="input"
                                disabled
                            />
                            <input
                                placeholder="3. Остаток товара на FBO;"
                                value={inputs.input28}
                                onChange={handleChange("input28")}
                                className="input"
                                disabled
                            />
                            <input
                                placeholder="4. Суммарные заказы за одну неделю (8 недель);"
                                value={inputs.input29}
                                onChange={handleChange("input29")}
                                className="input"
                                disabled
                            />
                            <input
                                placeholder="5. Ежедневные заказы за последние 7 дней;"
                                value={inputs.input30}
                                onChange={handleChange("input30")}
                                className="input"
                                disabled
                            />
                            <input
                                placeholder="6. Среднее количество заказов в день (2 недели);"
                                value={inputs.input31}
                                onChange={handleChange("input31")}
                                className="input"
                                disabled
                            />
                            <input
                                placeholder="7. На сколько дней хватит остатка на FBO;"
                                value={inputs.input32}
                                onChange={handleChange("input32")}
                                className="input"
                                disabled
                            />
                            <input
                                placeholder="8. Свободное поле для комментариев."
                                value={inputs.input33}
                                onChange={handleChange("input33")}
                                className="input"
                                disabled
                            />
                            <p>
                                Для удобства восприятия ячейки залиты градиентом, отражающим динамику заказов, а стрелками – их изменение.
                            </p>
                            <Image
                                className="full-width-image"
                                src="https://storage.googleapis.com/tagjs-prod.appspot.com/HWlyNDeUdw/10f3q2rs.png"
                                alt="Градиент"
                            />
                            <p>Стрелками можно сортировать значения в столбце.</p>
                            <Image
                                className="full-width-image"
                                src="https://storage.googleapis.com/tagjs-prod.appspot.com/HWlyNDeUdw/94h7lgrs.png"
                                alt="Стрелки сортировки"
                            />
                        </section>

                        {/* Секция: Дэшборд (Ozon) */}
                        <section className="section" id="Ozon">
                            <h2>Дэшборд (Ozon)</h2>
                            <p>
                                Перед Вами сводная таблица для анализа ключевых показателей эффективности в режиме реального времени для Ozon. Она помогает отследить динамику заказов и определить товар с хорошими/плохими показателями.
                            </p>
                            <Image
                                className="full-width-image"
                                src="https://storage.googleapis.com/tagjs-prod.appspot.com/HWlyNDeUdw/he2pyjs6.png"
                                alt="Дэшборд Ozon 1"
                            />
                            <Image
                                className="full-width-image"
                                src="https://storage.googleapis.com/tagjs-prod.appspot.com/HWlyNDeUdw/juka1dvz.png"
                                alt="Дэшборд Ozon 2"
                            />
                            <p>Таблица включает в себя:</p>
                            <input
                                placeholder="1. Название товара, артикулы Ozon и продавца;"
                                value={inputs.input34}
                                onChange={handleChange("input34")}
                                className="input"
                                disabled
                            />
                            <input
                                placeholder="2. Цена, установленная продавцом в личном кабинете;"
                                value={inputs.input35}
                                onChange={handleChange("input35")}
                                className="input"
                                disabled
                            />
                            <input
                                placeholder="3. Остаток на FBO;"
                                value={inputs.input36}
                                onChange={handleChange("input36")}
                                className="input"
                                disabled
                            />
                            <input
                                placeholder="4. Суммарные заказы за одну неделю;"
                                value={inputs.input37}
                                onChange={handleChange("input37")}
                                className="input"
                                disabled
                            />
                            <input
                                placeholder="5. Ежедневные заказы за последние 7 дней;"
                                value={inputs.input38}
                                onChange={handleChange("input38")}
                                className="input"
                                disabled
                            />
                            <input
                                placeholder="6. Среднее количество заказов в день (2 недели);"
                                value={inputs.input39}
                                onChange={handleChange("input39")}
                                className="input"
                                disabled
                            />
                            <input
                                placeholder="7. На сколько дней хватит остатка на FBO;"
                                value={inputs.input40}
                                onChange={handleChange("input40")}
                                className="input"
                                disabled
                            />
                            <input
                                placeholder="8. Свободное поле для комментариев."
                                value={inputs.input41}
                                onChange={handleChange("input41")}
                                className="input"
                                disabled
                            />
                        </section>

                        {/* Секция: Сноски для сводной таблицы (Ozon) */}
                        <section className="section" id="Ozon pivot">
                            <h2>Сноски для сводной таблицы (Ozon)</h2>
                            <Image
                                className="full-width-image"
                                src="https://storage.googleapis.com/tagjs-prod.appspot.com/HWlyNDeUdw/g5q7awcn.png"
                                alt="Сноски Ozon 1"
                            />
                            <Image
                                className="full-width-image"
                                src="https://storage.googleapis.com/tagjs-prod.appspot.com/HWlyNDeUdw/jaez9kw7.png"
                                alt="Сноски Ozon 2"
                            />
                            <input
                                placeholder="1. Название товара, артикулы Ozon и продавца;"
                                value={inputs.input42}
                                onChange={handleChange("input42")}
                                className="input"
                                disabled
                            />
                            <input
                                placeholder="2. Цена товара, установленная продавцом в личном кабинете;"
                                value={inputs.input43}
                                onChange={handleChange("input43")}
                                className="input"
                                disabled
                            />
                            <input
                                placeholder="3. Остаток товара на складах маркетплейса;"
                                value={inputs.input44}
                                onChange={handleChange("input44")}
                                className="input"
                                disabled
                            />
                            <input
                                placeholder="4. Суммарные заказы товара за указанный период;"
                                value={inputs.input45}
                                onChange={handleChange("input45")}
                                className="input"
                                disabled
                            />
                            <input
                                placeholder="5. Количество заказов товара за указанный день;"
                                value={inputs.input46}
                                onChange={handleChange("input46")}
                                className="input"
                                disabled
                            />
                            <input
                                placeholder="6. Среднее количество заказов в день (2 недели);"
                                value={inputs.input47}
                                onChange={handleChange("input47")}
                                className="input"
                                disabled
                            />
                            <input
                                placeholder="7. На сколько дней хватит остатка на FBO;"
                                value={inputs.input48}
                                onChange={handleChange("input48")}
                                className="input"
                                disabled
                            />
                            <input
                                placeholder="8. Свободное поле для комментариев."
                                value={inputs.input49}
                                onChange={handleChange("input49")}
                                className="input"
                                disabled
                            />
                        </section>

                        {/* Секция: Сноски для сводной таблицы (Wildberries) */}
                        <section className="section" id="WB pivot">
                            <h2>Сноски для сводной таблицы (Wildberries)</h2>
                            <Image
                                className="full-width-image"
                                src="https://storage.googleapis.com/tagjs-prod.appspot.com/HWlyNDeUdw/i1nn2v9r.png"
                                alt="Сноски WB 1"
                            />
                            <Image
                                className="full-width-image"
                                src="https://storage.googleapis.com/tagjs-prod.appspot.com/HWlyNDeUdw/gun07vtj.png"
                                alt="Сноски WB 2"
                            />
                            <input
                                placeholder="1. Название товара, артикулы WB и продавца;"
                                value={inputs.input50}
                                onChange={handleChange("input50")}
                                className="input"
                                disabled
                            />
                            <input
                                placeholder="2. Цена товара, установленная продавцом в личном кабинете;"
                                value={inputs.input51}
                                onChange={handleChange("input51")}
                                className="input"
                                disabled
                            />
                            <input
                                placeholder="3. Остаток товара на складах маркетплейса;"
                                value={inputs.input52}
                                onChange={handleChange("input52")}
                                className="input"
                                disabled
                            />
                            <input
                                placeholder="4. Суммарные заказы товара за указанный период;"
                                value={inputs.input53}
                                onChange={handleChange("input53")}
                                className="input"
                                disabled
                            />
                            <input
                                placeholder="5. Количество заказов товара за указанный день;"
                                value={inputs.input54}
                                onChange={handleChange("input54")}
                                className="input"
                                disabled
                            />
                            <input
                                placeholder="6. Среднее количество заказов в день (2 недели);"
                                value={inputs.input55}
                                onChange={handleChange("input55")}
                                className="input"
                                disabled
                            />
                            <input
                                placeholder="7. На сколько дней хватит остатка на FBO;"
                                value={inputs.input56}
                                onChange={handleChange("input56")}
                                className="input"
                                disabled
                            />
                            <input
                                placeholder="8. Свободное поле для комментариев."
                                value={inputs.input57}
                                onChange={handleChange("input57")}
                                className="input"
                                disabled
                            />
                        </section>
                    </main>
                </ConfigProvider>

            </div>
        </div>
    );
}
