import { useNavigate } from "react-router-dom";
import {
  PinInput,
  PinInputField,
  HStack
} from "@chakra-ui/react";
import "./aouth.css";
import "./aouthDark.css";
import { useEffect, useRef, useState } from "react";
import axios from "axios"
import { useToast } from '@chakra-ui/react'
import logosvg from "../../../img/LogoUSvg.svg"

import { LoginButton } from '@telegram-auth/react';


import { useSelector } from "react-redux";
import 'react-phone-number-input/style.css'
import PhoneInput, { formatPhoneNumber, formatPhoneNumberIntl, isValidPhoneNumber, isPossiblePhoneNumber } from 'react-phone-number-input'


import ru from 'react-phone-number-input/locale/ru'
import sockets from "../../../socket/socket";
import SliderLending from "./slider";
import LendingQrScannModalBTN from "../lendingQrScannModal/lendingQrScannModal";
import { Helmet } from "react-helmet";
import OfferModal from "../offerModal/offerModal";
import TelegramLogin from "../telegramLogin/telegramLogin";


let textModal = `Where does it come from?Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of "de Finibus Bonorum et Malorum" (The Extremes of Good and Evil) by Cicero, written in 45 BC. This book is a treatise on the theory of ethics, very popular during the Renaissance. The first line of Lorem Ipsum, "Lorem ipsum dolor sit amet..", comes from a line in section 1.10.32.The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested. Sections 1.10.32 and 1.10.33 from "de Finibus Bonorum et Malorum" by Cicero are also reproduced in their exact original form, accompanied by English versions from the 1914 translation by H. Rackham.`

let thisDark = localStorage.getItem("isDark");
const socket = sockets
export default function Outh() {
  const [isSending, setIsSending] = useState(false);
  const [inptValue, setinptValue] = useState("");
  const [loading, setloading] = useState(false);
  const [loadQR, setLoadQR] = useState(false);
  const [inpDisapled, setInpDisapled] = useState(false);
  const [pohoneNumber, setPohoneNumber] = useState(null);
  const navigate = useNavigate()
  const toast = useToast({ position: 'top' })
  const [isDark, setIsDark] = useState(false)
  const [country, setcountry] = useState("RU")
  const inputRef = useRef(null)
  const inputRef2 = useRef(null)
  const [location, setLocation] = useState(null);
  const [isFocus, setIsFocus] = useState(false);
  const [isOpenMenu, setisOpenMenu] = useState(false);
  const focusRef = useRef(null)
  const focusRef2 = useRef(null)
  const containerRef = useRef(null)
  const mobileMenuRef = useRef(null);
  const [menuIndex, setmenuIndex] = useState(0);

  useEffect(() => {
    if (isSending) {
      setIsSending(true);
      setInpDisapled(true);
      setloading(true);
      setinptValue("");
  
      const params = new URLSearchParams();
      const token = new URLSearchParams(window.location.search).get("token");
      if (token) params.append("token", token); // Добавляем только если он есть
  
      axios.post(`/pincode/v3/api?${params.toString()}`, { pinCode: inptValue })
        .then((response) => {
          socket.emit("pushmyid", response.data.datas._id);
          setIsSending(false);
          setInpDisapled(false);
          setloading(false);
          if (response.data.authorization) {
            window.location.replace("/");
          }
        })
        .catch((error) => {
          console.log(error);
          setIsSending(false);
          setInpDisapled(false);
          setloading(false);
          toast({
            title: error.response?.data?.message || "Ошибка",
            description: error.response?.data?.message || "Ошибка",
            status: "error",
            duration: 2000,
            isClosable: true,
          });
        });
    }
  }, [isSending]);
  

  useEffect(() => {

    setIsDark()

    setIsDark(JSON.parse(thisDark))
  }, [])
  useEffect(() => {

    axios.get('/registration/v2/api')
      .then(function (response) {
        // handle success
        console.log(response);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .finally(function () {
        // always executed
      });

  }, [])


  const handelPincode = (e) => {

    setinptValue(e)
    //без проверяется если пин-код длина равна 4 тогда отправляется сервер
    if (e.length === 4) {
      console.log("send server");
      setIsSending(true)
      setInpDisapled(true)

    }

  }
  useEffect(() => {

    if (isFocus === true) {
      const timer = setTimeout(() => {
        focusRef.current.focus();
        focusRef2.current.focus();

      }, 500); // Задержка в 500 миллисекунд

      // Очистка таймера при размонтировании или изменении зависимости
      return () => clearTimeout(timer);
    }
  }, [isFocus]);


  //Вот как мы отправляем номер телефона на сервер, чтобы позвонить нам
  const handleCall = () => {

    let replcaePhone = pohoneNumber.replace(/\+/g, '');
    if (pohoneNumber.length > 0) {
      setloading(true)
      setInpDisapled(true)
      axios.post('/registration/v3/api', {
        tel: replcaePhone
      })
        .then(function (response) {
          console.log(response);
          setloading(false)
          setInpDisapled(false)
          setIsFocus(true)
          toast({
            title: response.data.info,
            description: "пожалуйста подождите.",
            duration: 2000,
            status: "info",
            isClosable: true,

          })

        })
        .catch(function (error) {
          setIsFocus(false)
          console.log(error);
          setloading(false)
          setInpDisapled(false)
          toast({
            title: error.response.data.message,
            description: "Попробуйте чуть позже.",
            duration: 2000,
            isClosable: true,
            status: "error"
          })

        });
      console.log("called");
    } else {
      toast({
        title: "ошибка",
        description: "вы не набрали свой номер телефона.",
        status: "error",
        duration: 2000,
        isClosable: true,
        colorScheme: "#5E80E4"
      })
    }

  }

  useEffect(() => {
    inputRef.current.style.border = "1px solid red"
    inputRef2.current.style.border = "1px solid red"

    if (pohoneNumber && isPossiblePhoneNumber(pohoneNumber)) {


      inputRef.current.style.border = "1px solid #3B79F6"
      inputRef.current.style.outline = "1px solid #3B79F6"
      inputRef.current.style.fontWeight = "bold"


      inputRef2.current.style.border = "1px solid #3B79F6"
      inputRef2.current.style.outline = "1px solid #3B79F6"
      inputRef2.current.style.fontWeight = "bold"
    } else if (pohoneNumber == undefined) {
      inputRef.current.style.border = "1px solid red"
      inputRef.current.style.outline = "1px solid red"

      inputRef2.current.style.border = "1px solid red"
      inputRef2.current.style.outline = "1px solid red"
    }
    else {
      inputRef.current.style.border = "1px solid #a3bff7"
      inputRef.current.style.outline = "1px solid #a3bff7"
      inputRef.current.style.fontWeight = "normal"


      inputRef2.current.style.border = "1px solid #a3bff7"
      inputRef2.current.style.outline = "1px solid #a3bff7"
      inputRef2.current.style.fontWeight = "normal"
    }

  }, [pohoneNumber])
  // автоопределение страны
  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch the user's IP address from ipify.org
        const ipResponse = await axios.get('https://api.ipify.org/?format=json');
        const userIp = ipResponse.data.ip;

        // Fetch location information based on the user's IP
        const locationResponse = await axios.get(`https://ipinfo.io/${userIp}?token=298a6835c8ca73`);
        const { city, region, country, loc } = locationResponse.data;

        // Set the location and country state
        setLocation({ city, region, country, loc });
        setcountry(country);


      } catch (error) {
        // Handle errors
        console.error('Error fetching location:', error);
      }
    };

    fetchData();
  }, []); // Run the effect only once during component mount
  useEffect(() => {

    document.querySelectorAll(".PhoneInputInput")[0].focus();
    document.querySelectorAll(".PhoneInputInput")[1].focus();

  }, [])

  useEffect(() => {

    if (isOpenMenu == false) {
      mobileMenuRef.current.style.padding = "0"
      mobileMenuRef.current.style.height = "0"

    } else {
      mobileMenuRef.current.style.padding = "16px"
      mobileMenuRef.current.style.height = "300px"


    }

  }, [isOpenMenu])

  function funcSetIndex(params) {
    setmenuIndex(params)

    return menuIndex
  }
  const handleClick = () => {
    if (containerRef.current) {
      containerRef.current.transition = "1s"
      containerRef.current.scrollTop = containerRef.current.scrollHeight;
    }
  };
  const sendQrCodeToServer = async (qrCode) => {
    setLoadQR(true);
    try {
      const response = await axios.post('/qrCodeAutorizationBySocket/v3/api', {
        qrCode, // отправляем QR-код
      });
      socket.emit('pushmyid', response.data.datas._id);

      if (response.data.authorization) {
        window.location.replace("/"); // Переходим на главную страницу, если авторизация прошла успешно
      }
    } catch (error) {
      console.error('Ошибка при отправке запроса:', error);
      toast({
        title: error.response.data.message,
        description: error.response.data.message,
        status: "error",
        duration: 2000,
        isClosable: true,
      });

    } finally {

      setLoadQR(false);
    }
  };

  useEffect(() => {

    function sendForServer(msg, myinfo) {

      sendQrCodeToServer(myinfo._id)
    }
    socket.on('sendMessageSocket', sendForServer);

    return () => {

      socket.off('sendMessageSocket', sendForServer);
    };
  }, []);
  
  return (

    <>
        
        
          <Helmet>
        <title>Авторизация — Войдите в Упрости.рф</title>
        <meta name="description" content="Войдите в Упрости.рф, чтобы управлять задачами и командой на маркетплейсах. Простой доступ к вашему таск-трекеру для эффективной работы." />
      </Helmet>
    
      <div className={"aouthCont"}>

        <div>
          <div className="outNav">

            <div>
              <img src={logosvg} alt="" className="iconGlavv" loading="lazy"/>
              <p>Упрости</p>
            </div>
            <ul>
              <li onClick={() => funcSetIndex(0)}><p>Что мы предлагаем?</p></li>
              <li onClick={() => funcSetIndex(1)}><p>Таск-трекер</p></li>
              <li onClick={() => funcSetIndex(2)}><p>Внутренний чат</p></li>
              <li onClick={() => funcSetIndex(3)}><p>Мобильная версия</p></li>
              <li><p>Контакты</p></li>
            </ul>
          </div>
          <SliderLending funcSetIndex={funcSetIndex} />
        </div>
        <div>

          <div>

            <div>
              {/* <QRScanner /> */}
              <img src={logosvg} alt="" loading="lazy"/>
            </div>
            <div>
              <p>Войдите в аккаунт</p>
              <p>С возвращением, введите ваши данные</p>
            </div>
          </div>
          <div>
            <div>
              <div>
                <div>
                  <div>
                    <p>Введите ваш номер телефона</p>
                    <PhoneInput
                      ref={inputRef}
                      initialValueFormat="national"
                      labels={ru}
                      className="verifyInput"
                      international
                      defaultCountry={country}
                      limitMaxLength
                      placeholder="Введите номер"
                      value={pohoneNumber}
                      onChange={setPohoneNumber}
                      error={pohoneNumber ? (isPossiblePhoneNumber(pohoneNumber) ? undefined : 'Invalid phone number') : 'Phone number required'}

                    />
                  </div>
                </div>
                <p>После нажатия на кнопку вам поступит звонок. Отвечать на звонок не нужно</p>
              </div>

              <div className="ScannerAndCallBTN">
                {
                  loading ?
                    <button onClick={handleCall} style={{ opacity: "0.5" }} background={"#5E7DE8"} width={"270px"} disabled={inpDisapled || pohoneNumber && isValidPhoneNumber(pohoneNumber) ? false : true} className={"pinBTN"} >
                      <p>Получить звонок</p>
                      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                        <path fillRule="evenodd" clipRule="evenodd" d="M6.11183 1.04854C5.05974 0.0818031 3.44259 0.0818031 2.3905 1.04854C2.34741 1.08813 2.30142 1.13413 2.24169 1.19388L1.34419 2.09137C0.441081 2.99448 0.0619618 4.2964 0.339024 5.54318C1.91099 12.617 7.43553 18.1416 14.5094 19.7136C15.7562 19.9906 17.0581 19.6115 17.9612 18.7084L18.8586 17.811C18.9184 17.7512 18.9644 17.7052 19.004 17.6621C19.9708 16.61 19.9708 14.9928 19.004 13.9407C18.9644 13.8976 18.9184 13.8516 18.8586 13.7918L17.3894 12.3226C16.3693 11.3025 14.8287 11.0107 13.5063 11.5872C12.7492 11.9172 11.8672 11.7502 11.2832 11.1662L8.88639 8.76937C8.30239 8.18537 8.13536 7.30342 8.46538 6.54632C9.04184 5.22385 8.75009 3.68333 7.72999 2.66322L6.26066 1.19389C6.20093 1.13414 6.15492 1.08813 6.11183 1.04854ZM3.40541 2.15305C3.88363 1.71363 4.6187 1.71363 5.09692 2.15305C5.11343 2.16822 5.13507 2.18962 5.21114 2.26569L6.66933 3.72388C7.25333 4.30789 7.42036 5.18983 7.09034 5.94694C6.51388 7.2694 6.80563 8.80993 7.82573 9.83003L10.2225 12.2268C11.2426 13.2469 12.7832 13.5387 14.1056 12.9622C14.8627 12.6322 15.7447 12.7992 16.3287 13.3832L17.7869 14.8414C17.8629 14.9175 17.8843 14.9391 17.8995 14.9557C18.3389 15.4339 18.3389 16.1689 17.8995 16.6472C17.8843 16.6637 17.8629 16.6853 17.7869 16.7614L16.9005 17.6477C16.3601 18.1882 15.5809 18.4151 14.8348 18.2493C8.32946 16.8036 3.24893 11.7231 1.8033 5.21779C1.6375 4.47164 1.86438 3.6925 2.40485 3.15203L3.2912 2.26569C3.36726 2.18962 3.3889 2.16822 3.40541 2.15305Z" fill="white" />
                      </svg>
                    </button>
                    :
                    <button onClick={handleCall} background={"#5E7DE8"} width={"270px"} disabled={inpDisapled || pohoneNumber && isValidPhoneNumber(pohoneNumber) ? false : true} className={"pinBTN"} >
                      <p>Получить звонок</p>
                      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                        <path fillRule="evenodd" clipRule="evenodd" d="M6.11183 1.04854C5.05974 0.0818031 3.44259 0.0818031 2.3905 1.04854C2.34741 1.08813 2.30142 1.13413 2.24169 1.19388L1.34419 2.09137C0.441081 2.99448 0.0619618 4.2964 0.339024 5.54318C1.91099 12.617 7.43553 18.1416 14.5094 19.7136C15.7562 19.9906 17.0581 19.6115 17.9612 18.7084L18.8586 17.811C18.9184 17.7512 18.9644 17.7052 19.004 17.6621C19.9708 16.61 19.9708 14.9928 19.004 13.9407C18.9644 13.8976 18.9184 13.8516 18.8586 13.7918L17.3894 12.3226C16.3693 11.3025 14.8287 11.0107 13.5063 11.5872C12.7492 11.9172 11.8672 11.7502 11.2832 11.1662L8.88639 8.76937C8.30239 8.18537 8.13536 7.30342 8.46538 6.54632C9.04184 5.22385 8.75009 3.68333 7.72999 2.66322L6.26066 1.19389C6.20093 1.13414 6.15492 1.08813 6.11183 1.04854ZM3.40541 2.15305C3.88363 1.71363 4.6187 1.71363 5.09692 2.15305C5.11343 2.16822 5.13507 2.18962 5.21114 2.26569L6.66933 3.72388C7.25333 4.30789 7.42036 5.18983 7.09034 5.94694C6.51388 7.2694 6.80563 8.80993 7.82573 9.83003L10.2225 12.2268C11.2426 13.2469 12.7832 13.5387 14.1056 12.9622C14.8627 12.6322 15.7447 12.7992 16.3287 13.3832L17.7869 14.8414C17.8629 14.9175 17.8843 14.9391 17.8995 14.9557C18.3389 15.4339 18.3389 16.1689 17.8995 16.6472C17.8843 16.6637 17.8629 16.6853 17.7869 16.7614L16.9005 17.6477C16.3601 18.1882 15.5809 18.4151 14.8348 18.2493C8.32946 16.8036 3.24893 11.7231 1.8033 5.21779C1.6375 4.47164 1.86438 3.6925 2.40485 3.15203L3.2912 2.26569C3.36726 2.18962 3.3889 2.16822 3.40541 2.15305Z" fill="white" />
                      </svg>
                    </button>
                }
                <LendingQrScannModalBTN />
              </div>
            </div>
            <p>Нажимая кнопку "Получить звонок", вы принимаете  <OfferModal nameText={"Оферту"} textInModal={textModal}/> и  <OfferModal nameText={"Соглашение на обработку персональных данных."} textInModal={textModal}/> </p>
              
          </div>
          <div>
            <div>

              <HStack >
                <PinInput type="number" value={inptValue} onChange={(e) => [handelPincode(e)]} disabled={inpDisapled} variant="flushed" isRequired={true}>
                  <PinInputField ref={focusRef2} />
                  <PinInputField />
                  <PinInputField />
                  <PinInputField />
                </PinInput>
              </HStack>
              <p>Введите последние 4 цифры номера</p>
            </div>
          </div>
        </div>

      </div>
      <div className={"aouthContMobile"}>
        <nav>
          <div>
            <div>
              <img src={logosvg} alt="" loading="lazy"/>

            </div>
            <p>Упрости</p>
          </div>
          <div>
            <div onClick={() => setisOpenMenu(!isOpenMenu)}>
              {
                isOpenMenu ?
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                    <path d="M15.5304 1.53033C15.8232 1.23744 15.8232 0.762563 15.5304 0.46967C15.2375 0.176777 14.7626 0.176777 14.4697 0.46967L8.00001 6.93935L1.53033 0.469673C1.23744 0.17678 0.762563 0.17678 0.46967 0.469673C0.176777 0.762566 0.176777 1.23744 0.46967 1.53033L6.93935 8.00001L0.469697 14.4697C0.176804 14.7626 0.176804 15.2374 0.469697 15.5303C0.76259 15.8232 1.23746 15.8232 1.53036 15.5303L8.00001 9.06067L14.4697 15.5303C14.7626 15.8232 15.2374 15.8232 15.5303 15.5303C15.8232 15.2374 15.8232 14.7626 15.5303 14.4697L9.06067 8.00002L15.5304 1.53033Z" fill="#2D264B" />
                  </svg>
                  :
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="14" viewBox="0 0 16 14" fill="none">
                    <path d="M1 0.25C0.585786 0.25 0.25 0.585786 0.25 1C0.25 1.41421 0.585786 1.75 1 1.75H15C15.4142 1.75 15.75 1.41421 15.75 1C15.75 0.585786 15.4142 0.25 15 0.25H1Z" fill="#2D264B" />
                    <path d="M1 4.25C0.585786 4.25 0.25 4.58579 0.25 5C0.25 5.41421 0.585786 5.75 1 5.75H15C15.4142 5.75 15.75 5.41421 15.75 5C15.75 4.58579 15.4142 4.25 15 4.25H1Z" fill="#2D264B" />
                    <path d="M1 8.25C0.585786 8.25 0.25 8.58579 0.25 9C0.25 9.41421 0.585786 9.75 1 9.75H15C15.4142 9.75 15.75 9.41421 15.75 9C15.75 8.58579 15.4142 8.25 15 8.25H1Z" fill="#2D264B" />
                    <path d="M1 12.25C0.585786 12.25 0.25 12.5858 0.25 13C0.25 13.4142 0.585786 13.75 1 13.75H15C15.4142 13.75 15.75 13.4142 15.75 13C15.75 12.5858 15.4142 12.25 15 12.25H1Z" fill="#2D264B" />
                  </svg>
              }

            </div>
          </div>
        </nav>
        <div className="mobileOutListMenu" ref={mobileMenuRef}>
          <ul>
            <li onClick={() => [funcSetIndex(0), handleClick(), setisOpenMenu(!isOpenMenu)]}><p>Что мы предлагаем?</p></li>
            <li onClick={() => [funcSetIndex(1), handleClick(), setisOpenMenu(!isOpenMenu)]}><p>Таск-трекер</p></li>
            <li onClick={() => [funcSetIndex(2), handleClick(), setisOpenMenu(!isOpenMenu)]}><p>Внутренний чат</p></li>
            <li onClick={() => [funcSetIndex(3), handleClick(), setisOpenMenu(!isOpenMenu)]}><p>Мобильная версия</p></li>
            <li onClick={() => setisOpenMenu(!isOpenMenu)}><p>Контакты</p></li>
          </ul>
        </div>
        <div className="scrolForMobileOuth" ref={containerRef}

          style={{
            scrollBehavior: 'smooth', // Добавляем плавный скролл
          }}
        >
          <div className="outhForMobile">
            <div>
              <div>
                <img src={logosvg} alt="" loading="lazy" />
              </div>
              <div>
                <p>Войдите в аккаунт</p>
                <p>С возвращением, введите ваши данные</p>
              </div>
            </div>
            <div>
              <div>
                <div>
                  <div>
                    <div>
                      <p>Введите ваш номер телефона</p>
                      <PhoneInput
                        ref={inputRef2}
                        initialValueFormat="national"
                        labels={ru}
                        className="verifyInput"
                        international
                        defaultCountry={country}
                        limitMaxLength
                        placeholder="Введите номер"
                        value={pohoneNumber}
                        onChange={setPohoneNumber}
                        error={pohoneNumber ? (isPossiblePhoneNumber(pohoneNumber) ? undefined : 'Invalid phone number') : 'Phone number required'}
                      />
                    </div>
                  </div>
                  <p>После нажатия на кнопку вам поступит звонок. Отвечать на звонок не нужно</p>
                </div>

                <div className="ScannerAndCallBTN">
                  {
                    loading ?
                      <button onClick={handleCall} style={{ opacity: "0.5" }} disabled={inpDisapled || pohoneNumber && isValidPhoneNumber(pohoneNumber) ? false : true} className={"pinBTN"} >
                        <p>Получить звонок</p>
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                          <path fillRule="evenodd" clipRule="evenodd" d="M6.11183 1.04854C5.05974 0.0818031 3.44259 0.0818031 2.3905 1.04854C2.34741 1.08813 2.30142 1.13413 2.24169 1.19388L1.34419 2.09137C0.441081 2.99448 0.0619618 4.2964 0.339024 5.54318C1.91099 12.617 7.43553 18.1416 14.5094 19.7136C15.7562 19.9906 17.0581 19.6115 17.9612 18.7084L18.8586 17.811C18.9184 17.7512 18.9644 17.7052 19.004 17.6621C19.9708 16.61 19.9708 14.9928 19.004 13.9407C18.9644 13.8976 18.9184 13.8516 18.8586 13.7918L17.3894 12.3226C16.3693 11.3025 14.8287 11.0107 13.5063 11.5872C12.7492 11.9172 11.8672 11.7502 11.2832 11.1662L8.88639 8.76937C8.30239 8.18537 8.13536 7.30342 8.46538 6.54632C9.04184 5.22385 8.75009 3.68333 7.72999 2.66322L6.26066 1.19389C6.20093 1.13414 6.15492 1.08813 6.11183 1.04854ZM3.40541 2.15305C3.88363 1.71363 4.6187 1.71363 5.09692 2.15305C5.11343 2.16822 5.13507 2.18962 5.21114 2.26569L6.66933 3.72388C7.25333 4.30789 7.42036 5.18983 7.09034 5.94694C6.51388 7.2694 6.80563 8.80993 7.82573 9.83003L10.2225 12.2268C11.2426 13.2469 12.7832 13.5387 14.1056 12.9622C14.8627 12.6322 15.7447 12.7992 16.3287 13.3832L17.7869 14.8414C17.8629 14.9175 17.8843 14.9391 17.8995 14.9557C18.3389 15.4339 18.3389 16.1689 17.8995 16.6472C17.8843 16.6637 17.8629 16.6853 17.7869 16.7614L16.9005 17.6477C16.3601 18.1882 15.5809 18.4151 14.8348 18.2493C8.32946 16.8036 3.24893 11.7231 1.8033 5.21779C1.6375 4.47164 1.86438 3.6925 2.40485 3.15203L3.2912 2.26569C3.36726 2.18962 3.3889 2.16822 3.40541 2.15305Z" fill="white" />
                        </svg>
                      </button>
                      :
                      <button onClick={handleCall} disabled={inpDisapled || pohoneNumber && isValidPhoneNumber(pohoneNumber) ? false : true} className={"pinBTN"} >
                        <p>Получить звонок</p>
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                          <path fillRule="evenodd" clipRule="evenodd" d="M6.11183 1.04854C5.05974 0.0818031 3.44259 0.0818031 2.3905 1.04854C2.34741 1.08813 2.30142 1.13413 2.24169 1.19388L1.34419 2.09137C0.441081 2.99448 0.0619618 4.2964 0.339024 5.54318C1.91099 12.617 7.43553 18.1416 14.5094 19.7136C15.7562 19.9906 17.0581 19.6115 17.9612 18.7084L18.8586 17.811C18.9184 17.7512 18.9644 17.7052 19.004 17.6621C19.9708 16.61 19.9708 14.9928 19.004 13.9407C18.9644 13.8976 18.9184 13.8516 18.8586 13.7918L17.3894 12.3226C16.3693 11.3025 14.8287 11.0107 13.5063 11.5872C12.7492 11.9172 11.8672 11.7502 11.2832 11.1662L8.88639 8.76937C8.30239 8.18537 8.13536 7.30342 8.46538 6.54632C9.04184 5.22385 8.75009 3.68333 7.72999 2.66322L6.26066 1.19389C6.20093 1.13414 6.15492 1.08813 6.11183 1.04854ZM3.40541 2.15305C3.88363 1.71363 4.6187 1.71363 5.09692 2.15305C5.11343 2.16822 5.13507 2.18962 5.21114 2.26569L6.66933 3.72388C7.25333 4.30789 7.42036 5.18983 7.09034 5.94694C6.51388 7.2694 6.80563 8.80993 7.82573 9.83003L10.2225 12.2268C11.2426 13.2469 12.7832 13.5387 14.1056 12.9622C14.8627 12.6322 15.7447 12.7992 16.3287 13.3832L17.7869 14.8414C17.8629 14.9175 17.8843 14.9391 17.8995 14.9557C18.3389 15.4339 18.3389 16.1689 17.8995 16.6472C17.8843 16.6637 17.8629 16.6853 17.7869 16.7614L16.9005 17.6477C16.3601 18.1882 15.5809 18.4151 14.8348 18.2493C8.32946 16.8036 3.24893 11.7231 1.8033 5.21779C1.6375 4.47164 1.86438 3.6925 2.40485 3.15203L3.2912 2.26569C3.36726 2.18962 3.3889 2.16822 3.40541 2.15305Z" fill="white" />
                        </svg>
                      </button>
                  }
                  <LendingQrScannModalBTN />
                </div>
              </div>
              <p>Нажимая кнопку "Получить звонок", вы принимаете  <OfferModal nameText={"Оферту"}  textInModal={textModal}/> и  <OfferModal nameText={"Соглашение на обработку персональных данных."} textInModal={textModal}/> </p>
            </div>
            <div>
              <div>
                <HStack>
                  <PinInput type="number" color="#5E7DE8" colorScheme='#5E7DE8' focusBorderColor={"#5E7DE8"} value={inptValue} onChange={(e) => [handelPincode(e)]} disabled={inpDisapled} variant="flushed" isRequired={true}>
                    <PinInputField ref={focusRef} />
                    <PinInputField />
                    <PinInputField />
                    <PinInputField />
                  </PinInput>
                </HStack>
                <p>Введите последние 4 цифры номера</p>
              </div>
            </div>
          </div>

          <SliderLending funcSetIndex={funcSetIndex} />

        </div>

      </div>
      {/* <TelegramLogin /> */}
    </>
  );
}
