import {
  Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton,
  ModalBody, ModalFooter, Button, FormControl, FormLabel, Input,
  NumberInput, NumberInputField, Switch, Select, Textarea, useToast, Text, Box
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import axios from "axios";
import { Spinner, Flex } from "@chakra-ui/react";

const EditPromoModal = ({ isOpen, onClose, promo, onUpdated }) => {
  const { register, handleSubmit, reset, setValue, watch } = useForm();
  const [loading, setLoading] = useState(false);
  const [companySearch, setCompanySearch] = useState("");
  const [companyList, setCompanyList] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [loadingCompanies, setLoadingCompanies] = useState(false);

  const toast = useToast();

  const type = watch("type");

  useEffect(() => {
    if (promo) {
      setValue("type", promo.type);
      setValue("discountType", promo.discountType);
      setValue("discountValue", promo.discountValue);
      setValue("usageLimit", promo.usageLimit ?? "");
      setValue("oneTimePerUser", promo.oneTimePerUser);
      setValue("expirationDate", promo.expirationDate?.slice(0, 10));
      setValue("isActive", promo.isActive);
      setValue("comment", promo.comment);

      // ✅ Устанавливаем companyID из promo, даже если юзер не выбирал вручную
      if (promo.type === "company" && promo.companyID) {
        setValue("companyID", promo.companyID._id || promo.companyID);
        fetchCompanyName(promo.companyID._id || promo.companyID); // отображение имени
      } else {
        setValue("companyID", null);
      }
    }
  }, [promo, setValue]);


  const fetchCompanyName = async (companyID) => {
    try {
      const res = await axios.get(`/promo/searchCompanyForPromocode?q=${companyID}`);
      const found = res.data.find((c) => c._id === companyID);
      if (found) {
        setSelectedCompany(found);
        setCompanySearch(found.sellersname);
      }
    } catch (err) {
      console.error("Ошибка при поиске компании по ID", err);
    }
  };

  const searchCompanies = async (query) => {
    try {
      setLoadingCompanies(true); // показываем спиннер
      const res = await axios.get(`/promo/searchCompanyForPromocode?q=${query}`);
      setCompanyList(res.data);
    } catch (err) {
      console.error("Ошибка поиска компаний", err);
    } finally {
      setLoadingCompanies(false); // скрываем спиннер
    }
  };


  useEffect(() => {
    if (companySearch.length > 1) {
      const delayDebounce = setTimeout(() => {
        searchCompanies(companySearch);
      }, 300);
      return () => clearTimeout(delayDebounce);
    }
  }, [companySearch]);

  const onSubmit = async (data) => {
    try {
      setLoading(true);
      data.usageLimit = data.usageLimit ? Number(data.usageLimit) : null;
      data.discountValue = Number(data.discountValue);
      data.oneTimePerUser = Boolean(data.oneTimePerUser);
      data.isActive = Boolean(data.isActive);
      data.expirationDate = new Date(data.expirationDate);

      if (data.type === "global") data.companyID = null;

      await axios.patch(`/promo/${promo._id}/toggle`, data);
      toast({ title: "Промокод обновлён", status: "success" });
      reset();
      onClose();
      onUpdated();
    } catch (err) {
      console.log(err.response.data.message);
      toast({ title: err.response.data.message, status: "error" });
    } finally {
      setLoading(false);
    }
  };



  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg">
      <ModalOverlay />
      <ModalContent as="form" onSubmit={handleSubmit(onSubmit)}>
        <ModalHeader>Редактировать промокод</ModalHeader>
        <ModalCloseButton />
        <ModalBody display="grid" gap={4}>

          <FormControl>
            <FormLabel>Тип промокода</FormLabel>
            <Select {...register("type")}>
              <option value="global">Глобальный</option>
              <option value="company">Для компании</option>
            </Select>
          </FormControl>

          {type === "company" && (
            <FormControl>
              <FormLabel>Компания</FormLabel>
              <Input
                placeholder="Начните вводить название..."
                value={companySearch}
                onChange={(e) => {
                  setCompanySearch(e.target.value);
                  setValue("companyID", "");
                  setSelectedCompany(null);
                }}
              />
              {companySearch && (
                <Box border="1px solid #ccc" borderRadius="md" mt={1} maxH="200px" overflowY="auto" p={2}>
                  {loadingCompanies ? (
                    <Flex justify="center"><Spinner size="sm" /></Flex>
                  ) : companyList.length > 0 ? (
                    <>
                      <Text fontSize="sm" mb={2} color="gray.600">Результаты:</Text>
                      {companyList.map((c) => (
                        <Box
                          key={c._id}
                          px={3}
                          py={2}
                          borderBottom="1px solid #eee"
                          _hover={{ bg: "gray.100", cursor: "pointer" }}
                          onClick={() => {
                            setSelectedCompany(c);
                            setValue("companyID", c._id);
                            setCompanySearch(c.sellersname);
                            setCompanyList([]);
                          }}
                        >
                          {c.sellersname}
                        </Box>
                      ))}
                    </>
                  ) : (
                    <Text fontSize="sm" color="gray.500">Ничего не найдено</Text>
                  )}
                </Box>
              )}


              {selectedCompany && (
                <Text fontSize="sm" mt={2} color="gray.600">
                  Выбранная компания: <b>{selectedCompany.sellersname}</b>
                </Text>
              )}

              {!selectedCompany && promo?.companyID?.sellersname && (
                <Text fontSize="sm" mt={2} color="gray.600">
                  Выбранная компания: <b>{promo.companyID.sellersname}</b>
                </Text>
              )}
            </FormControl>
          )}


          <FormControl>
            <FormLabel>Тип скидки</FormLabel>
            <Select {...register("discountType")}>
              <option value="percent">Процент (%)</option>
              <option value="fixed">Фиксированная сумма (₽)</option>
            </Select>
          </FormControl>

          <FormControl>
            <FormLabel>Значение скидки</FormLabel>
            <NumberInput min={1}>
              <NumberInputField {...register("discountValue")} />
            </NumberInput>
          </FormControl>

          <FormControl>
            <FormLabel>Лимит использований</FormLabel>
            <NumberInput min={1}>
              <NumberInputField {...register("usageLimit")} />
            </NumberInput>
          </FormControl>

          <FormControl display="flex" alignItems="center">
            <FormLabel mb="0">Одноразовый</FormLabel>
            <Switch {...register("oneTimePerUser")} />
          </FormControl>

          <FormControl>
            <FormLabel>Срок действия</FormLabel>
            <Input type="date" {...register("expirationDate")} />
          </FormControl>

          <FormControl display="flex" alignItems="center">
            <FormLabel mb="0">Активен</FormLabel>
            <Switch {...register("isActive")} />
          </FormControl>

          <FormControl>
            <FormLabel>Комментарий</FormLabel>
            <Textarea {...register("comment")} />
          </FormControl>

        </ModalBody>
        <ModalFooter>
          <Button onClick={onClose} mr={3}>Отмена</Button>
          <Button colorScheme="blue" type="submit" isLoading={loading}>Сохранить</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default EditPromoModal;
