import React, { useEffect, useState, useRef, useCallback } from "react";
import {
    Box, Button, Table, Thead, Tbody, Tr, Th, Td, Tag,
    useToast, Spinner, IconButton, Flex, Heading, Tooltip,
    Input, InputGroup, InputLeftElement, Select, Text,
    useDisclosure, AlertDialog, AlertDialogOverlay, AlertDialogContent,
    AlertDialogHeader, AlertDialogBody, AlertDialogFooter
} from "@chakra-ui/react";
import {
    EditIcon, DeleteIcon, AddIcon, RepeatIcon, SearchIcon, CopyIcon, ChevronDownIcon, CloseIcon
} from "@chakra-ui/icons";
import axios from "axios";
import CreatePromoModal from "./CreatePromoModal";
import EditPromoModal from "./EditPromoModal";
import sockets from "../../../../socket/socket";
import UsedCompaniesModal from "./UsedCompaniesModal";


const socket = sockets;
const LIMIT = 20;

const PromoAdminPanel = () => {
    const toast = useToast();
    const [promos, setPromos] = useState([]);
    const [loading, setLoading] = useState(true);
    const [loadingMore, setLoadingMore] = useState(false);
    const [hasMore, setHasMore] = useState(true);
    const [offset, setOffset] = useState(0);

    const [search, setSearch] = useState("");
    const [statusFilter, setStatusFilter] = useState("all");

    const [createOpen, setCreateOpen] = useState(false);
    const [editOpen, setEditOpen] = useState(false);
    const [selectedPromo, setSelectedPromo] = useState(null);


    const [promoWithCompanies, setPromoWithCompanies] = useState(null);

    const {
        isOpen: isUsedModalOpen,
        onOpen: onUsedOpen,
        onClose: onUsedClose
    } = useDisclosure();

    // ⛔️ AlertDialog state
    const { isOpen: isDeleteOpen, onOpen: onDeleteOpen, onClose: onDeleteClose } = useDisclosure();
    const cancelRef = useRef();
    const [promoToDelete, setPromoToDelete] = useState(null);

    const observer = useRef();

    const lastPromoRef = useCallback((node) => {
        if (loadingMore) return;
        if (observer.current) observer.current.disconnect();
        observer.current = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting && hasMore) {
                loadMore();
            }
        });
        if (node) observer.current.observe(node);
    }, [loadingMore, hasMore]);

    const loadMore = async () => {
        try {
            setLoadingMore(true);
            const { data } = await axios.get(`/promo/all?limit=${LIMIT}&offset=${offset}`);
            setPromos(prev => [...prev, ...data.promos]);
            setOffset(prev => prev + LIMIT);
            if (data.promos.length < LIMIT) setHasMore(false);
        } catch {
            toast({ title: "Ошибка загрузки", status: "error" });
        } finally {
            setLoadingMore(false);
        }
    };

    const fetchInitial = async () => {
        try {
            setLoading(true);
            const { data } = await axios.get(`/promo/all?limit=${LIMIT}&offset=0`);
            setPromos(data.promos);
            setOffset(LIMIT);
            setHasMore(data.promos.length === LIMIT);
        } catch {
            toast({ title: "Ошибка", description: "Не удалось загрузить промокоды", status: "error" });
        } finally {
            setLoading(false);
        }
    };

    const handleDeleteClick = (promo) => {
        setPromoToDelete(promo);
        onDeleteOpen();
    };

    const handleDeleteConfirm = async () => {
        try {
            await axios.delete(`/promo/${promoToDelete._id}`);
            toast({ title: "Промокод удалён", status: "info" });
            fetchInitial();
        } catch {
            toast({ title: "Ошибка удаления", status: "error" });
        } finally {
            onDeleteClose();
        }
    };

    const toggleStatus = async (id) => {
        try {
            await axios.patch(`/promo/${id}/toggle`);
            toast({ title: "Статус обновлён", status: "success" });
            fetchInitial();
        } catch {
            toast({ title: "Ошибка", status: "error" });
        }
    };

    useEffect(() => {
        fetchInitial();
    }, []);

    const filteredPromos = promos.filter(p => {
        const s = search.trim().toLowerCase();
        return (
            (p.code.toLowerCase().includes(s) || p.companyID?.sellersname?.toLowerCase().includes(s)) &&
            (statusFilter === "all" ||
                (statusFilter === "active" && p.isActive) ||
                (statusFilter === "inactive" && !p.isActive))
        );
    });

    const getExpirationStatus = (dateStr) => {
        const now = new Date();
        const date = new Date(dateStr);
        const diff = Math.ceil((date - now) / (1000 * 60 * 60 * 24));
        if (diff < 0) return { color: "red", label: "Истёк" };
        if (diff <= 3) return { color: "orange", label: `Скоро (${diff} дн.)` };
        return { color: "green", label: `ОК (${diff} дн.)` };
    };

    useEffect(() => {
        socket.on('updatePromoCode', toggleStatus);
        return () => socket.off('updatePromoCode', toggleStatus);
    }, []);

    useEffect(() => {
        console.log(filteredPromos, "filteredPromosfilteredPromos");

    }, [filteredPromos])


    const handleRemoveCompany = async (promoId, companyId) => {
        try {
            await axios.patch(`/promo/${promoId}/remove-company`, { companyId });
            toast({ title: "Компания удалена из списка использовавших", status: "info" });
            fetchInitial();
        } catch {
            toast({ title: "Ошибка удаления компании", status: "error" });
        }
    };

    return (
        <Box p={6}>
            <Flex justify="space-between" align="center" mb={4}>
                <Heading size="md">Промокоды</Heading>
                <Button colorScheme="blue" leftIcon={<AddIcon />} onClick={() => setCreateOpen(true)}>
                    Создать промокод
                </Button>
            </Flex>

            <Flex gap={4} mb={4}>
                <InputGroup maxW="300px">
                    <InputLeftElement><SearchIcon /></InputLeftElement>
                    <Input
                        placeholder="Поиск по коду или компании"
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                    />
                </InputGroup>
                <Select maxW="200px" value={statusFilter} onChange={e => setStatusFilter(e.target.value)}>
                    <option value="all">Все</option>
                    <option value="active">Активные</option>
                    <option value="inactive">Отключённые</option>
                </Select>
            </Flex>

            {loading ? (
                <Flex justify="center"><Spinner /></Flex>
            ) : (
                <>
                    <Box overflowX="auto">
                        <Table variant="striped" size="sm">
                            <Thead>
                                <Tr>
                                    <Th>Код</Th>
                                    <Th>Тип</Th>
                                    <Th>Компания</Th>
                                    <Th>Скидка</Th>
                                    <Th>Ограничения</Th>
                                    <Th>Использовано</Th>
                                    <Th>Срок</Th>
                                    <Th>Комментарий</Th>
                                    <Th>Статус</Th>
                                    <Th>Действия</Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {filteredPromos.map((promo, i) => (
                                    <Tr key={promo._id} ref={i === filteredPromos.length - 1 ? lastPromoRef : null}>
                                        <Td>
                                            <Flex align="center" gap={2}>
                                                <Text fontWeight="bold">{promo.code}</Text>
                                                <Tooltip label="Скопировать" placement="top">
                                                    <IconButton
                                                        icon={<CopyIcon />}
                                                        size="xs"
                                                        variant="ghost"
                                                        onClick={() => {
                                                            navigator.clipboard.writeText(promo.code);
                                                            toast({ title: "Скопировано", status: "success", duration: 2000 });
                                                        }}
                                                        aria-label="Copy"
                                                    />
                                                </Tooltip>
                                            </Flex>
                                        </Td>
                                        <Td><Tag colorScheme={promo.type === "global" ? "green" : "purple"}>{promo.type === "global" ? "Глобальный" : "Компания"}</Tag></Td>
                                        <Td>{promo.type === "company" ? promo.companyID?.sellersname || "—" : "—"}</Td>
                                        <Td><Tag colorScheme="yellow">{promo.discountType === "percent" ? `${promo.discountValue}%` : `${promo.discountValue}₽`}</Tag></Td>
                                        <Td>
                                            {promo.oneTimePerUser && <Tag mr={1}>1 раз</Tag>}
                                            {promo.usageLimit && <Tag>Лимит: {promo.usageLimit}</Tag>}
                                        </Td>
                                        <Td><Tag colorScheme="blue">{promo.usageCount || 0} / {promo.usageLimit ?? "∞"}</Tag></Td>
                                        <Td>
                                            {(() => {
                                                const { color, label } = getExpirationStatus(promo.expirationDate);
                                                return <Tag colorScheme={color}>{new Date(promo.expirationDate).toLocaleDateString()} — {label}</Tag>;
                                            })()}
                                        </Td>
                                        <Td>{promo.comment || "-"}</Td>
                                        <Td><Tag colorScheme={promo.isActive ? "green" : "red"}>{promo.isActive ? "Активен" : "Отключён"}</Tag></Td>
                                        <Td>
                                            <Flex gap={2}>
                                                <Tooltip label="Редактировать">
                                                    <IconButton icon={<EditIcon />} size="sm" onClick={() => {
                                                        setSelectedPromo(promo);
                                                        setEditOpen(true);
                                                    }} />
                                                </Tooltip>
                                                <Tooltip label="Удалить">
                                                    <IconButton icon={<DeleteIcon />} size="sm" colorScheme="red" onClick={() => handleDeleteClick(promo)} />
                                                </Tooltip>
                                                <Tooltip label="Переключить статус">
                                                    <IconButton icon={<RepeatIcon />} size="sm" onClick={() => toggleStatus(promo._id)} />
                                                </Tooltip>
                                                <Tooltip label="Использованные компании">
                                                    <IconButton
                                                        icon={<ChevronDownIcon />}
                                                        size="sm"
                                                        variant="outline"
                                                        onClick={() => {
                                                            setPromoWithCompanies(promo);
                                                            onUsedOpen();
                                                        }}
                                                    />

                                                </Tooltip>

                                            </Flex>
                                        </Td>
                                    </Tr>
                                ))}
                            </Tbody>
                        </Table>
                    </Box>

                    {loadingMore && <Flex justify="center" mt={4}><Spinner size="sm" /></Flex>}
                    {!hasMore && <Text mt={4} align="center" fontSize="sm" color="gray.500">Все промокоды загружены</Text>}
                </>
            )}

            {/* Модалка подтверждения удаления */}
            <AlertDialog isOpen={isDeleteOpen} leastDestructiveRef={cancelRef} onClose={onDeleteClose}>
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize="lg" fontWeight="bold">Удаление промокода</AlertDialogHeader>
                        <AlertDialogBody>
                            Вы уверены, что хотите удалить промокод <b>{promoToDelete?.code}</b>?
                        </AlertDialogBody>
                        <AlertDialogFooter>
                            <Button ref={cancelRef} onClick={onDeleteClose}>Отмена</Button>
                            <Button colorScheme="red" onClick={handleDeleteConfirm} ml={3}>Удалить</Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
            <UsedCompaniesModal
                isOpen={isUsedModalOpen}
                onClose={() => {
                    setPromoWithCompanies(null);
                    onUsedClose();
                }}
                promo={promoWithCompanies}
                onCompanyRemoved={fetchInitial}
            />

            <CreatePromoModal isOpen={createOpen} onClose={() => setCreateOpen(false)} onCreated={fetchInitial} />
            <EditPromoModal isOpen={editOpen} onClose={() => {
                setSelectedPromo(null);
                setEditOpen(false);
            }} promo={selectedPromo} onUpdated={fetchInitial} />
        </Box>
    );
};

export default PromoAdminPanel;
